import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import user from './user';
import profile from './profile';
import notifications from './notifications';
import toast from './toast';
import conversations from './conversations';
import messages from './messages';
import offers from './offers';
import projects from './projects';
import paymentMethods from './paymentMethods';
import transactions from './transactions';
import stats from './stats';
import invitations from './invitations';
import payouts from './payouts';
import typingIndicator from './typingIndicator';
import files from './files';
import fullScreen from './fullScreen';
import admin from './admin';

export default configureStore({
    reducer: {
        user,
        profile,
        notifications,
        toast,
        conversations,
        messages,
        offers,
        projects,
        paymentMethods,
        transactions,
        stats,
        invitations,
        payouts,
        typingIndicator,
        files,
        fullScreen,
        admin,
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
});
