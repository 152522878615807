import {CheckCircleIcon, ScaleIcon,} from '@heroicons/react/outline'

import {Dialog, Menu, RadioGroup, Transition} from '@headlessui/react'
import {
    CashIcon,
    ChevronRightIcon,
    DotsVerticalIcon,
    InformationCircleIcon,
    RefreshIcon,
    XIcon,
} from '@heroicons/react/solid'
import React, {Fragment, useEffect, useRef, useState} from "react";
import currency from "currency.js";
import {classNames} from "../../utils";
import {Link} from "react-router-dom";

const mailingLists = [
    {
        id: 1,
        title: 'Buyer',
        description: 'The buyer pays the full fee amount',
        users: <span>100% <i className="text-gray-300">/</i> 0%</span>
    },
    {
        id: 2, title: 'Half and half', description: 'Fee is equally divided between both parties', users: <span>50% <i
            className="text-gray-300">/</i> 50%</span>
    },
    {
        id: 3, title: 'Seller', description: 'The seller pays the full fee amount', users: <span>0% <i
            className="text-gray-300">/</i> 100%</span>
    },
]

const cards = [
    {name: 'Account balance', href: '#', icon: ScaleIcon, amount: '$32.000,00', css: 'text-green-400'},
    {name: 'Pending fees', href: '#', icon: RefreshIcon, amount: '$8.620,00'},
    {name: 'Processed (last 30 days)', href: '#', icon: CheckCircleIcon, amount: '$22.450,00', css: 'text-green-400'},
]

const projects = [
    {
        id: 1,
        title: 'Platform Fees',
        initials: 'PF',
        totalMembers: "2.00 %",
        pinned: true,
        bgColorClass: 'bg-pink-600',
    },
    /*{
        id: 2,
        title: 'Platform Fees',
        initials: 'PF',
        totalMembers: "2.00 %",
        pinned: true,
        bgColorClass: 'bg-yellow-500',
        active: false,
    },
    {
        id: 3,
        title: 'Platform Fees',
        initials: 'PF',
        totalMembers: "2.00 %",
        pinned: true,
        bgColorClass: 'bg-green-500',
        active: false,
    },*/
    // More projects...
]
const pinnedProjects = projects.filter((project) => project.pinned)
const transactions = [
    {
        id: 1,
        name: 'Payment to Molly Sanders',
        href: '#',
        amount: '$20,000',
        currency: 'USD',
        status: 'success',
        date: 'July 11, 2020',
        datetime: '2020-07-11',
    },
    {
        id: 1,
        name: 'Payment to Ervin Hop',
        href: '#',
        amount: '$800',
        currency: 'USD',
        status: 'success',
        date: 'July 15, 2020',
        datetime: '2020-07-11',
    },
    // More transactions...
]
const statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
}

export default function ManageDashboard() {
    const cancelButtonRef = useRef(null)
    const [showFeesDialog, setShowFeesDialog] = useState(false)

    const [platformFeeSellerPercentage, setPlatformFeeSellerPercentage] = useState("1.5")
    const [platformFeeBuyerPercentage, setPlatformFeeBuyerPercentage] = useState("1")

    const [projectTotal, setProjectTotal] = useState("4500")
    const [subtotal, setSubtotal] = useState("")
    const [total, setTotal] = useState("")
    const [platformFee, setPlatformFee] = useState("")
    const [processingFee, setProcessingFee] = useState("")
    const [sellerReceives, setSellerReceives] = useState("")
    const [buyerPays, setBuyerPays] = useState("")
    const [platformEarns, setPlatformEarns] = useState("")

    function toggleFees() {
        setShowFeesDialog(true)
    }

    function onPercentageChange(e) {
        let _platformFeeSellerPercentage = parseFloat(platformFeeSellerPercentage) || 0,
            _platformFeeBuyerPercentage = parseFloat(platformFeeBuyerPercentage) || 0,
            _projectTotal = parseFloat(projectTotal) || 0
        if (_platformFeeSellerPercentage > 100) _platformFeeSellerPercentage = 100
        if (_platformFeeBuyerPercentage > 100) _platformFeeBuyerPercentage = 100
        if (_platformFeeSellerPercentage < 0) _platformFeeSellerPercentage = 0
        if (_platformFeeBuyerPercentage < 0) _platformFeeBuyerPercentage = 0
        if (_projectTotal < 0) _projectTotal = 0


        let subtotalAmount = currency(_projectTotal, {symbol: "$"}),
            platformFeeSellerFactor = currency(_platformFeeSellerPercentage, {precision: 3}).divide(100).add(1),
            platformFeeBuyerFactor = currency(_platformFeeBuyerPercentage, {precision: 3}).divide(100).add(1),
            processingFeeFactor = currency(2.9, {precision: 3}).divide(100).add(1),
            processingFeeAmount = subtotalAmount.multiply(processingFeeFactor).add(0.30).subtract(subtotalAmount),
            platformFeeSellerAmount = subtotalAmount.multiply(platformFeeSellerFactor).subtract(subtotalAmount),
            platformFeeBuyerAmount = subtotalAmount.multiply(platformFeeBuyerFactor).subtract(subtotalAmount),
            totalAmount = subtotalAmount.add(processingFeeAmount).add(platformFeeSellerAmount).add(platformFeeBuyerAmount)


        setSubtotal(subtotalAmount.format())
        setProcessingFee(processingFeeAmount.format())
        setPlatformFee(platformFeeSellerAmount.add(platformFeeBuyerAmount).format())
        setTotal(totalAmount.format())
        setBuyerPays(subtotalAmount.add(processingFeeAmount).add(platformFeeBuyerAmount).format())
        setSellerReceives(subtotalAmount.subtract(platformFeeSellerAmount).format())
        setPlatformEarns(platformFeeSellerAmount.add(platformFeeBuyerAmount).format())

    }

    useEffect(() => {
        onPercentageChange()
    }, [platformFeeSellerPercentage, platformFeeBuyerPercentage, projectTotal])

    //const [selectedMailingLists, setSelectedMailingLists] = useState(mailingLists[0])

    const [isShowingAlert, setIsShowingAlert] = useState(true)

    return (
        <>
            <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                {isShowingAlert && (
                    <div className="absolute inset-x-0 bottom-0 pb-2 sm:pb-5">
                        <div className="mx-auto max-w-4xl px-2 sm:px-6 lg:px-8">
                            <div className="rounded-lg bg-indigo-600 p-2 shadow-lg sm:p-3">
                                <div className="flex flex-wrap items-center justify-between">
                                    <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-indigo-800 p-2">
                <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true"/>
              </span>
                                        <p className="ml-3 truncate font-medium text-white">
                                            <span className="md:hidden">This is a demo page</span>
                                            <span className="hidden md:inline">This is a demo page, contact us to get access to the full version.</span>
                                        </p>
                                    </div>
                                    <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                                        <Link
                                            to="/manage/request-access"
                                            className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
                                        >
                                            Contact us
                                        </Link>
                                    </div>
                                    <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                        <button
                                            onClick={() => setIsShowingAlert(false)}
                                            type="button"
                                            className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="mt-8">
                    <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                        Active settings
                    </h2>

                    <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                        <ul role="list" className="mt-3 grid grid-cols-1 gap-4 sm:grid-cols-3 sm:gap-6 xl:grid-cols-3">
                            {pinnedProjects.map((project) => (
                                <li key={project.id} className="relative col-span-1 flex rounded-md shadow-sm">
                                    <div
                                        className={classNames(
                                            project.bgColorClass,
                                            'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                                        )}
                                    >
                                        {project.initials}
                                    </div>
                                    <div
                                        className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                                        <div className="flex-1 truncate px-4 py-2 text-sm">
                                            <span className="font-medium text-gray-900">
                                                {project.title}
                                            </span>
                                            <p className="text-gray-500">{project.totalMembers}</p>
                                        </div>
                                        <Menu as="div" className="flex-shrink-0 pr-2">
                                            <Menu.Button
                                                className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                <span className="sr-only">Open options</span>
                                                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    className="absolute right-10 top-3 z-10 mx-3 mt-1 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({active}) => (
                                                                <span
                                                                    onClick={toggleFees}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm cursor-pointer'
                                                                    )}
                                                                >
                                                                Manage
                                                            </span>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                    <div className="py-1">
                                                        {/*<Menu.Item>
                                                        {({active}) => (
                                                            <a
                                                                href="#"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                Unpin
                                                            </a>
                                                        )}
                                                    </Menu.Item>*/}
                                                        <Menu.Item>
                                                            {({active}) => (
                                                                <Link
                                                                    to="/manage/request-access"
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    Help
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mx-auto mt-8 max-w-6xl px-4 sm:px-6 lg:px-8">
                        <h2 className="text-lg font-medium leading-6 text-gray-900">Overview</h2>
                        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                            {/* Card */}
                            {cards.map((card) => (
                                <div key={card.name} className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">{card.name}</dt>
                                                    <dd>
                                                        <div
                                                            className="text-lg font-medium text-gray-900">{card.amount}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-5 py-3">
                                        <div className="text-sm">
                                            <Link to="/manage/request-access" href={card.href}
                                                  className="font-medium text-indigo-700 hover:text-indigo-900">
                                                View all
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                        Recent activity
                    </h2>

                    {/* Activity list (smallest breakpoint only) */}
                    <div className="shadow sm:hidden">
                        <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                            {transactions.map((transaction) => (
                                <li key={transaction.id}>
                                    <a href={transaction.href} className="block bg-white px-4 py-4 hover:bg-gray-50">
                        <span className="flex items-center space-x-4">
                          <span className="flex flex-1 space-x-2 truncate">
                            <CashIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                            <span className="flex flex-col truncate text-sm text-gray-500">
                              <span className="truncate">{transaction.name}</span>
                              <span>
                                <span className="font-medium text-gray-900">{transaction.amount}</span>{' '}
                                  {transaction.currency}
                              </span>
                              <time dateTime={transaction.datetime}>{transaction.date}</time>
                            </span>
                          </span>
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                        </span>
                                    </a>
                                </li>
                            ))}
                        </ul>

                        <nav
                            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
                            aria-label="Pagination"
                        >
                            <div className="flex flex-1 justify-between">
                                {/*<a
                                    href="#"
                                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                                >
                                    Previous
                                </a>
                                <a
                                    href="#"
                                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                                >
                                    Next
                                </a>*/}
                            </div>
                        </nav>
                    </div>

                    {/* Activity table (small breakpoint and up) */}
                    <div className="hidden sm:block">
                        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                            <div className="mt-2 flex flex-col">
                                <div
                                    className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead>
                                        <tr>
                                            <th
                                                className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                scope="col"
                                            >
                                                Transaction
                                            </th>
                                            <th
                                                className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                scope="col"
                                            >
                                                Amount
                                            </th>
                                            <th
                                                className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                                                scope="col"
                                            >
                                                Status
                                            </th>
                                            <th
                                                className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                scope="col"
                                            >
                                                Date
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {transactions.map((transaction) => (
                                            <tr key={transaction.id} className="bg-white">
                                                <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                    <div className="flex">
                                                        <a href={transaction.href}
                                                           className="group inline-flex space-x-2 truncate text-sm">
                                                            <CashIcon
                                                                className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="truncate text-gray-500 group-hover:text-gray-900">
                                                                {transaction.name}
                                                            </p>
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                    <span
                                                        className="font-medium text-gray-900">{transaction.amount}</span>
                                                    {transaction.currency}
                                                </td>
                                                <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                <span
                                    className={classNames(
                                        statusStyles[transaction.status],
                                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                    )}
                                >
                                  {transaction.status}
                                </span>
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                    <time dateTime={transaction.datetime}>{transaction.date}</time>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {/* Pagination */}
                                    <nav
                                        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Showing <span className="font-medium">1</span> to <span
                                                className="font-medium">2</span> of{' '}
                                                <span className="font-medium">2</span> results
                                            </p>
                                        </div>
                                        {/*<div className="flex flex-1 justify-between sm:justify-end">
                                            <a
                                                href="#"
                                                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                            >
                                                Previous
                                            </a>
                                            <a
                                                href="#"
                                                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                            >
                                                Next
                                            </a>
                                        </div>*/}
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Transition.Root show={showFeesDialog} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShowFeesDialog}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="mt-6 text-center sm:mt-3 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                Manage your fees
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Here you can view and manage platform fees
                                                </p>
                                            </div>
                                        </div>
                                        <div className="sm:mx-4 mt-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <label htmlFor="projectTotal"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Project total
                                                </label>
                                                <div className="relative mt-1 rounded-md shadow-sm">
                                                    <input
                                                        type="number"
                                                        value={projectTotal}
                                                        onChange={(e) => setProjectTotal(e.target.value)}
                                                        step="0.1"
                                                        max="100"
                                                        min="0"
                                                        id="projectTotal"
                                                        className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        placeholder="0.0"
                                                        aria-describedby="price-currency0"
                                                    />
                                                    <div
                                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <span className="text-gray-500 sm:text-sm"
                                                              id="price-currency0">$</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <label htmlFor="feeSeller"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Platform fee (seller pays)
                                                </label>
                                                <div className="relative mt-1 rounded-md shadow-sm">
                                                    <input
                                                        type="number"
                                                        value={platformFeeSellerPercentage}
                                                        onChange={(e) => setPlatformFeeSellerPercentage(e.target.value)}
                                                        step="0.1"
                                                        max="100"
                                                        min="0"
                                                        id="feeSeller"
                                                        className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        placeholder="0.0"
                                                        aria-describedby="price-currency1"
                                                    />
                                                    <div
                                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <span className="text-gray-500 sm:text-sm"
                                                              id="price-currency1">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <label htmlFor="feeBuyer"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Platform fee (buyer pays)
                                                </label>
                                                <div className="relative mt-1 rounded-md shadow-sm">
                                                    <input
                                                        type="number"
                                                        value={platformFeeBuyerPercentage}
                                                        onChange={(e) => setPlatformFeeBuyerPercentage(e.target.value)}
                                                        step="0.1"
                                                        max="100"
                                                        min="0"
                                                        id="feeBuyer"
                                                        className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                        placeholder="0.0"
                                                        aria-describedby="price-currency2"
                                                    />
                                                    <div
                                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                        <span className="text-gray-500 sm:text-sm"
                                                              id="price-currency2">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                                        <div className="sm:mx-4">

                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-2">
                                                    <h3 className="font-medium text-gray-900">Fee structure</h3>
                                                    <dl className="mt-2 border-t divide-y divide-gray-200">
                                                        <div className="py-3 flex justify-between text-sm font-medium">
                                                            <dt className="text-gray-500">Project total</dt>
                                                            <dd className="text-gray-700">{subtotal}</dd>
                                                        </div>
                                                        <div className="py-3 flex justify-between text-sm font-medium">
                                                            <dt className="text-gray-500 inline-flex items-center">
                                                                Credit card fee (2,9% + 30c)
                                                                {/*<QuestionMarkCircleIcon
                                                                    className="w-4 h-4 inline ml-1"/>*/}</dt>
                                                            <dd className="text-gray-700">{processingFee}</dd>
                                                        </div>
                                                        <div className="py-3 flex justify-between text-sm font-medium">
                                                            <dt className="text-gray-500 inline-flex items-center">
                                                                Platform fee ({platformFeeSellerPercentage}% + {platformFeeBuyerPercentage}%)
                                                                {/*<QuestionMarkCircleIcon
                                                                    className="w-4 h-4 inline ml-1"/>*/}</dt>
                                                            <dd className="text-gray-700">{platformFee}</dd>
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="py-3 pb-1.5 flex justify-between font-medium">
                                                                <dt className="text-gray-900 font-bold">Total</dt>
                                                                <dd className="text-gray-900 font-bold">{total}</dd>
                                                            </div>
                                                            <dl className="font-medium">
                                                                <div className="py-1.5 flex justify-between text-sm">
                                                                    <dt className="text-gray-900">Buyer pays <span className="text-gray-500">(credit card fee of 2,9% + 30c added)</span></dt>
                                                                    <dd className="text-gray-900">{buyerPays}</dd>
                                                                </div>
                                                                <div className="py-1.5 flex justify-between text-sm">
                                                                    <dt className="text-gray-900">Seller receives</dt>
                                                                    <dd className="text-gray-900">{sellerReceives}</dd>
                                                                </div>
                                                                <div className="py-1.5 flex justify-between text-sm">
                                                                    <dt className="text-green-600">Your earnings</dt>
                                                                    <dd className="text-green-600">{platformEarns}</dd>
                                                                </div>
                                                            </dl>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setShowFeesDialog(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Dismiss
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}
