import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {onErrorProfileFallback, profilePhoto} from "../components/utils";
import {setInvitation} from "../store/invitations";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getProfile, profileSelector} from "../store/profile";
import Spinner from "../components/spinner";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import {showToast, TOAST_ALERT} from "../store/toast";
import {getConversationId} from "../utils";
import {sendMessage} from "../store/messages";
import {getFunctions, httpsCallable} from "firebase/functions";
import {region} from "../config";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const firestore = getFirestore(),
    functions = getFunctions()

functions.region = region

const InvitationDialog = props => {
    const {uid, invitationUid, invitation, open} = props

    const [invitationId, setInvitationId] = useState(null)

    const _invActive = invitation?.active
    const isUsername = invitation?.isUsername

    const cancelButtonRef = useRef(null)
    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()
    const history = useHistory()

    async function loadConversationAndRedirectIfExists(conversationId) {
        setIsLoading(true)

        getDoc(doc(firestore, "conversations", conversationId)).then(d => {
            if (d.exists) {
                // redirect
                setOpen()
                history.push("/chat/" + conversationId)
            }
        }).catch(e => {
            setIsLoading(false)
            if (e && e.code === "permission-denied") {
                // ok
            } else {
                console.error(e.code)
                setOpen()
                dispatch(showToast({
                    text: "There was a problem retrieving data. Please try again later.",
                    type: TOAST_ALERT
                }))
            }

        })
    }

    const profile = useSelector(profileSelector(invitationUid ? invitationUid : _invActive, isUsername))

    function setOpen() {
        dispatch(setInvitation(null))
        props.setOpen(false)
    }

    const [message, setMessage] = useState('')
    const [isSubmitting, setItSubmitting] = useState(false)

    useEffect(() => {
        if (_invActive || invitationUid) {
            setMessage("")
            setItSubmitting(false)
            if (isUsername) {
                setInvitationId(null)
                dispatch(getProfile(_invActive, isUsername))
            } else if (invitationUid) {
                dispatch(getProfile(invitationUid))
                setInvitationId(invitationUid)
            } else {
                setInvitationId(invitation?.active)
            }
        }
    }, [uid, _invActive, invitationUid])

    useEffect(() => {
        if (profile?.id) {
            setMessage("")
            setItSubmitting(false)
            setInvitationId(profile.id)
            return loadConversationAndRedirectIfExists(getConversationId(profile.id, uid))
        }
    }, [profile])

    function onSubmit(e) {
        e.preventDefault()

        setItSubmitting(true)

        const conversationId = getConversationId(invitationId, uid)
        dispatch(sendMessage(conversationId, message, [], null, (ok, err) => {
            if (ok) {
                setOpen()
                history.push("/chat/" + conversationId)
            } else {
                if (err.code === "permission-denied") {
                    const spl = conversationId.split("_")
                    if (spl.indexOf(uid) !== -1) {
                        const createConversation = httpsCallable(functions, "createConversation")
                        createConversation({conversationId}).then(() => {

                            // let's try again
                            dispatch(sendMessage(conversationId, message, [], null, (ok, err) => {
                                setOpen(false)
                                if (ok) {
                                    history.push("/chat/" + conversationId)
                                } else {
                                    dispatch(showToast({
                                        text: "There was a problem sending message. Please try again later.",
                                        type: TOAST_ALERT
                                    }))
                                }
                            }))

                        }).catch(err => {
                            console.error("createConversation function error", err)
                            setOpen(false)
                            dispatch(showToast({
                                text: "There was a problem starting a conversation. Please try again later.",
                                type: TOAST_ALERT
                            }))
                        })
                        return
                    }
                }

                console.error("fatal error sendMessage", err)

                setOpen(false)
                dispatch(showToast({
                    text: "There was a problem sending message. Please try again later.",
                    type: TOAST_ALERT
                }))
            }
        }))
        setMessage("")

        //setOpen()
        //history.push("/chat/" + getConversationId(invitationId, uid))
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >

                        <form onSubmit={onSubmit}
                              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            {isLoading && <div className="flex justify-center items-center my-4"><Spinner big/></div>}
                            <div
                                hidden={isLoading}>
                                <div
                                    className="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-indigo-100">
                                    <img className="h-12 w-12 rounded-full" src={profilePhoto(invitationId)}
                                         onError={onErrorProfileFallback} alt=""/>
                                </div>
                                <div className="mt-3 text-center sm:mt-4">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Message {profile?.name}
                                    </Dialog.Title>
                                    <div className="my-2">
                                        <p className="text-sm text-gray-500 py-4">
                                            You can start a new conversation by sending a message <br/>in the form
                                            below
                                        </p>
                                    </div>

                                    <div className="block mt-2 sm:mt-0">
                                        <textarea
                                            disabled={isSubmitting}
                                            id="about"
                                            name="about"
                                            required
                                            minLength="1"
                                            rows={3}
                                            className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                            onChange={e => setMessage(e.target.value)}
                                            value={message}
                                            placeholder={"Start a conversation with " + profile?.name}
                                        />
                                    </div>


                                </div>
                            </div>
                            <div
                                hidden={isLoading}
                                className={classNames(
                                    "mt-5 sm:mt-6",
                                    isLoading ? "hidden" : "sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
                                )}>
                                <button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className={classNames(
                                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm",
                                        isSubmitting ? "bg-gray-300 text-gray-500" : "bg-indigo-600 hover:bg-indigo-700 text-white"
                                    )}
                                >
                                    {isSubmitting && <Spinner/>}
                                    {isSubmitting ? "Sending message..." : "Send message"}
                                </button>

                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Dismiss
                                </button>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default InvitationDialog
