import {createSlice} from "@reduxjs/toolkit";

import {getAuth} from 'firebase/auth';
import {doc, getDoc, getFirestore} from 'firebase/firestore';
import {getFunctions, httpsCallable} from "firebase/functions";
import {region} from "../config";

const auth = getAuth(),
    firestore = getFirestore(),
    functions = getFunctions()

functions.region = region

const initialState = {
    details: false,
    connectedAccount: null
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.details = action.payload
        },
        setConnectedAccount: (state, action) => {
            state.connectedAccount = action.payload
        },
    }
})

export default slice.reducer

// ACTIONS

const {setUser, setConnectedAccount} = slice.actions

export const getCurrentUser = (currentUser = auth.currentUser) => async (dispatch) => {
    if (currentUser) await getDoc(doc(firestore, "users", currentUser.uid)).then(doc => dispatch(setUser({...doc.data(), ...currentUser})))
    else dispatch(setUser(null))
}

export const updateCurrentUserData = (updates, cb) => async (dispatch) => {
    const updateUserBillingDetails = httpsCallable(functions, "updateUserBillingDetails")
    await updateUserBillingDetails(updates)
        .then(() => dispatch(getCurrentUser()))
        .catch(err => console.error(err))
        .finally(() => cb && cb())
}

/**
 * Get connected account to check if everything is alright on Stripe
 * @returns {(function(*): Promise<void>)|*}
 */
export const getConnectedAccount = () => async (dispatch) => {
    const updateUserBillingDetails = httpsCallable(functions, "getConnectedAccount")
    await updateUserBillingDetails()
        .then(({data}) => dispatch(setConnectedAccount(data.stripeResult)))
        .catch(err => console.error(err))
}

/*export const addCard = source => async (dispatch) => {
    const addCustomerCard = functions.httpsCallable("addCustomerCard")

    await addCustomerCard({})
        .then(() => {
            console.log("complete")
            dispatch(getCurrentUser())
        }).catch((err) => {
            console.error("err", err)
        }).finally(() => {
            console.log("finally")
        })
    console.log("awaited long enough")
}*/

// SELECTORS

export const userDetailsSelector = state => state.user.details
export const connectedAccountSelector = state => state.user.connectedAccount
