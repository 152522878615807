import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getProfile, profileSelector} from "../store/profile";


const AuthorText = props => {
    const {id, fallback = ""} = props
    const profile = useSelector(profileSelector(id)) || {}
    const dispatch = useDispatch()

    useEffect(() => {
        if (id) dispatch(getProfile(id))
    }, [id])

    return profile.name || fallback
}

export default AuthorText
