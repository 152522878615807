import React, {useEffect, useState} from 'react'
import {PlusIcon,} from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import {orderedConversationsSelector, seenConversationsSelector} from "../../store/conversations";
import {NavLink, useHistory, useParams} from "react-router-dom";
import Conversation from "./conversation";
import AuthorSnippet from "../../components/author-snippet";
import {userDetailsSelector} from "../../store/user";
import AuthorText from "../../components/author-text";
import {beautifulDateTime, removeHTML} from "../../utils";
import ConversationInvite from "../../dialogs/conversation-invite";
import {removeMessagesListeners} from "../../store/messages";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Chat = () => {
    const {conversationId = ''} = useParams()
    const history = useHistory()

    const user = useSelector(userDetailsSelector)

    const orderedConversations = useSelector(orderedConversationsSelector)
    const seen = useSelector(seenConversationsSelector)

    const [open, setOpen] = useState(false)
    const [redirectIfEmpty, setRedirectIfEmpty] = useState(true)

    useEffect(() => {
        if (redirectIfEmpty && !conversationId && orderedConversations && orderedConversations.length > 0) {
            history.push("/chat/" + orderedConversations[0].id)
        }
        if (conversationId) setRedirectIfEmpty(false)
    }, [conversationId, orderedConversations])

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(removeMessagesListeners())
        }
    }, [])

    return (
        <div style={{height: 'calc(100vh - 4rem)', maxHeight: 'calc(100vh - 4rem)', overflow: "hidden"}}>
            <main className="min-w-0 h-full flex flex-1 border-t border-gray-200 w-full">
                <div className={classNames(
                    conversationId ? "flex flex-grow" : "hidden sm:hidden md:hidden lg:hidden xl:flex 2xl:flex flex-grow"
                )}>
                    <Conversation id={conversationId}/>
                </div>

                {/* Message list*/}
                <aside className={classNames(
                    conversationId ? "hidden xl:block xl:flex-shrink-0 xl:order-first" : "block flex-grow md:flex-grow lg:flex-grow xl:flex-grow-0 2xl:flex-grow-0 xl:flex-shrink-0 xl:order-first"
                )}>
                    <div className="h-full relative flex flex-col xl:w-96 border-r border-gray-200 bg-gray-100">
                        <div className="flex-shrink-0">
                            <div className="h-16 bg-white px-6 flex justify-between">
                                <div className="flex items-center space-x-3">
                                    <h2 className="text-lg font-medium text-gray-900">Inbox</h2>
                                    <p className="text-sm font-medium text-gray-500">{orderedConversations.length} {orderedConversations.length === 1 ? "conversation" : "conversations"} </p>
                                </div>
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        onClick={() => setOpen(true)}
                                        className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:border-indigo-600"
                                    >
                                        <PlusIcon className="mr-2.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        <span>New</span>
                                    </button>
                                </div>
                            </div>
                            <div
                                className="border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
                                Sorted by date
                            </div>
                        </div>
                        <nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto">
                            <ul className="border-b border-gray-200 divide-y divide-gray-200">
                                {orderedConversations.map(conversation => {
                                    let s = seen[conversation.id],
                                        notSeen = s ? s.seen === false : false
                                    return (
                                        <li
                                            key={conversation.id}
                                            className={classNames(
                                                "relative bg-white py-5 px-6 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600",
                                                conversation.id === conversationId ? "bg-gray-50" : ""
                                            )}
                                        >
                                            <div className="flex justify-between space-x-3">
                                                <div className="min-w-0 flex-1">
                                                    <NavLink to={"/chat/" + conversation.id}
                                                             className="block focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true"/>
                                                        <AuthorSnippet
                                                            id={conversation.members.filter(m => m !== user.uid)[0]}/>
                                                    </NavLink>
                                                </div>
                                                <time
                                                    dateTime={conversation.message.createdAt.toDate().toISOString()}
                                                    className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                                                >
                                                    {beautifulDateTime(conversation.message.createdAt)}
                                                </time>
                                            </div>
                                            <div className="mt-4">
                                                <span className={classNames(
                                                    "line-clamp-1 text-sm text-gray-600",
                                                    notSeen ? "font-medium" : ""
                                                )}><AuthorText
                                                    id={conversation.message.uid}/>: {removeHTML(conversation.message.text || "...")}</span>
                                            </div>
                                            {notSeen && (
                                                <span
                                                    className="block absolute right-0 top-0 bottom-0 bg-indigo-200 w-1"/>
                                            )}
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </div>
                </aside>
            </main>

            <ConversationInvite
                user={user}
                open={open}
                onClose={setOpen}
            />

        </div>
    )

}

export default Chat
