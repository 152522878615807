import {Redirect, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setInvitation} from "../store/invitations";
import {userDetailsSelector} from "../store/user";
import {currentProfileSelector} from "../store/profile";

const Invitation = () => {
    const {invitation = null, username = null} = useParams()
    const user = useSelector(userDetailsSelector)
    const currentProfile = useSelector(currentProfileSelector)
    const dispatch = useDispatch()

    useEffect(() => {
        if (invitation) {
            if (user && user.uid === invitation) {
                dispatch(setInvitation(null))
            } else {
                dispatch(setInvitation(invitation))
            }
        }
        try {
            if (window.LeadDyno) {
                window.LeadDyno.recordVisit();
                window.LeadDyno.autoWatch();
            }
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        if(username && username.length > 1) {
            if(currentProfile && currentProfile.lowerUsername === username.toLowerCase()) {
                dispatch(setInvitation(null))
            } else {
                dispatch(setInvitation(username.toLowerCase(), true))
            }
        }
    }, [username, currentProfile])

    return <Redirect to="/dashboard"/>
}

export default Invitation
