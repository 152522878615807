import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import {getProject, projectSelector} from "../store/projects";
import {PaperClipIcon} from "@heroicons/react/solid";
import Spinner from "../components/spinner";
import {downloadFiles} from "../store/files";
import MediaItem from "../components/files/media-item";
import {classNames} from "../utils";


const ReviewDeliverables = props => {
    const {projectRef, user, open, setOpen, onCancel, onAccept, onReject, onStart} = props;
    const cancelButtonRef = useRef()

    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(() => {
        setIsSubmitting(false)
    }, [open])

    const dispatch = useDispatch()
    const project = useSelector(projectSelector(projectRef ? projectRef.id : ''))

    useEffect(() => {
        if (projectRef) dispatch(getProject(projectRef))
    }, [projectRef])

    function pay() {
        setIsSubmitting(true)
        onAccept(() => {
            setIsSubmitting(false)
            setOpen(false)
        })
    }

    const [isDownloading, setIsDownloading] = useState(false)

    function downloadDeliveredFiles() {
        setIsDownloading(true)
        dispatch(downloadFiles(project.deliverables.attachments, () => {
            setIsDownloading(false)
        }))

    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div
                    className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                            <div className="bg-white pt-5 pb-4 sm:p-6 sm:pb-4">
                                {project && (
                                    <div className="my-4 max-w-5xl mx-auto px-4 sm:px-6 lg:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Project</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{project.title}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Text</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{project.deliverables ? project.deliverables.text : ""}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                        {(project.deliverables && project.deliverables.attachments && project.deliverables.attachments.length > 0) ? (
                                                            <li className="pl-3 pr-4 py-3 flex flex-col">
                                                                <div
                                                                    className="flex items-center justify-between text-sm">
                                                                    <div className="w-0 flex-1 flex items-center">
                                                                        <PaperClipIcon
                                                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                                            aria-hidden="true"/>
                                                                        <span
                                                                            className="ml-2 flex-1 w-0 truncate">{project.deliverables.attachments.length} {project.deliverables.attachments.length === 1 ? "attachment" : "attachments"}</span>
                                                                    </div>
                                                                    <div className="ml-4 flex-shrink-0">
                                                                        {isDownloading ? (
                                                                            <Spinner/>
                                                                        ) : (
                                                                            <span
                                                                                onClick={downloadDeliveredFiles}
                                                                                className="cursor-pointer font-medium text-gray-600 hover:text-blue-500">Download all</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="mt-4 grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-4">
                                                                    {project.deliverables.attachments.map((file, i) => (
                                                                        <div key={i}>
                                                                            <MediaItem defaultFileId={file}/>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </li>
                                                        ) : (
                                                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">


                                                                <div
                                                                    className="w-0 flex-1 flex items-center justify-center">
                                                                    <span
                                                                        className="text-gray-500">No attachments</span>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                )}
                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={isSubmitting}
                                    type="button"
                                    className={classNames(
                                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                        isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                    onClick={pay}
                                >
                                    {isSubmitting && <Spinner/>}
                                    {isSubmitting ? "Accepting..." : "Accept and Pay"}
                                </button>
                                <button
                                    disabled={isSubmitting}
                                    type="button"
                                    className={classNames(
                                        "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                                        isSubmitting ? "bg-gray-300 cursor-default" : "bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500"
                                    )}
                                    onClick={() => {
                                        setOpen(false);
                                        onReject()
                                    }}
                                >
                                    Ask for Revision
                                </button>
                                <button
                                    disabled={isSubmitting}
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ReviewDeliverables
