import {bucket} from "../config";

const profileFallbackUrl = "https://storage.googleapis.com/" + bucket + "/profile-fallback.jpg"
const imageFallbackUrl = "https://storage.googleapis.com/" + bucket + "/noimg.png"
export const onErrorProfileFallback = e => {
    if (e.target && e.target.src !== profileFallbackUrl) e.target.src = profileFallbackUrl
}
export const onErrorImageFallback = e => {
    if (e.target && e.target.src !== imageFallbackUrl) e.target.src = imageFallbackUrl
}

export const profilePhoto = (uid, cache = true) => "https://storage.googleapis.com/" + bucket + "/u/" + uid + "/photo.jpg" + (cache ? "" : "?t=" + (new Date()).getTime())

export const fileThumb = (fileId, cache = true) => "https://storage.googleapis.com/" + bucket + "/files/" + fileId + "/thumb_600x600.jpg" + (cache ? "" : "?t=" + (new Date()).getTime())
