import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {onErrorProfileFallback, profilePhoto} from "../utils";
import {activeInvitationSelector} from "../../store/invitations";
import {getProfile, profileSelector} from "../../store/profile";

const AuthPageHeader = () => {
    const dispatch = useDispatch()

    const invitation = useSelector(activeInvitationSelector)
    const profile = useSelector(profileSelector(invitation && invitation.active ? invitation.active : null, true))

    useEffect(() => {
        if (invitation.active) dispatch(getProfile(invitation.active, true))
    }, [invitation.active])

    return (
        <>
            {invitation && !!invitation.active ? (
                <div>
                    <div
                        className="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-indigo-100">
                        <img className="h-12 w-12 rounded-full" src={profilePhoto(profile?.id)}
                             onError={onErrorProfileFallback} alt=""/>

                    </div>
                    <h1 className="mt-6 mb-4 text-center text-xl font-bold text-gray-600">To
                        message {profile?.name}</h1>
                </div>
            ) : (
                <img
                    className="mx-auto h-12 w-auto mb-6"
                    srcSet="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logoc.png,
                    https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logoc@2x.png 2x"
                    src="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logoc.png"
                    alt="Paynco"
                />
            )}
        </>
    )

}

export default AuthPageHeader
