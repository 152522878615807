export const bankAccountCountries = [
    ["AU", "Australia", "aud", true],
    ["AT", "Austria", "eur", true],
    ["BE", "Belgium", "eur", true],
    ["BR", "Brazil", "brl"],
    ["BG", "Bulgaria", "bgn"],
    ["CA", "Canada", "cad", true],
    ["CY", "Cyprus", "eur"],
    ["CZ", "Czech Republic", "czk"],
    ["DK", "Denmark", "dkk", true],
    ["EE", "Estonia", "eur", true],
    ["FI", "Finland", "eur", true],
    ["FR", "France", "eur", true],
    ["DE", "Germany", "eur", true],
    ["GR", "Greece", "eur", true],
    ["HK", "Hong Kong", "hkd", true],
    ["HU", "Hungary", "huf"],
    ["IN", "India", "inr"],
    ["IE", "Ireland", "eur", true],
    ["IT", "Italy", "eur", true],
    ["JP", "Japan", "jpy", true],
    ["LV", "Latvia", "eur", true],
    ["LT", "Lithuania", "eur", true],
    ["LU", "Luxembourg", "eur", true],
    ["MY", "Malaysia", "myr", true],
    ["MT", "Malta", "eur"],
    ["MX", "Mexico", "mxn"],
    ["NL", "Netherlands", "eur", true],
    ["NZ", "New Zealand", "nzd", true],
    ["NO", "Norway", "nok", true],
    ["PL", "Poland", "pln", true],
    ["PT", "Portugal", "eur", true],
    ["RO", "Romania", "ron"],
    ["SG", "Singapore", "sgd", true],
    ["SK", "Slovakia", "eur", true],
    ["SI", "Slovenia", "eur", true],
    ["ES", "Spain", "eur", true],
    ["SE", "Sweden", "sek", true],
    ["CH", "Switzerland", "chf", true],
    ["AE", "United Arab Emirates", "aed"],
    ["GB", "United Kingdom", "gbp", true],
    ["US", "United States", "usd", true],
]

export const getBankAccountCountry = code => bankAccountCountries.find(([c]) => c === code)
