import React, {useState} from 'react'
import AuthorText from "../../components/author-text";
import {useIntervalWhen} from "rooks";
import {ChatAltIcon} from "@heroicons/react/solid";

const TypingIndicator = props => {
    const {data} = props
    const [values, setValues] = useState([])
    useIntervalWhen(() => {
        if (data) {
            const secs = Math.floor((new Date()).getTime() / 1000) - 2
            setValues(Object.keys(data)
                .sort()
                .map(key => ({uid: key, timestamp: data[key]}))
                .filter(({
                             uid,
                             timestamp
                         }) => timestamp && timestamp.seconds >= secs))
        }
    }, 1000, true)
    return (
        <div className="h-8">
            {values && values.length > 0 && (
                <div className="mx-10 font-medium text-xs text-gray-500 flex items-center">
                    <ChatAltIcon className="w-4 h-4 mr-2"/>
                    <span>{values.map(({uid}) => <AuthorText id={uid}/>)} {values.length === 1 ? "is" : "are"} typing ...</span>
                </div>
            )}
        </div>
    )
}

export default TypingIndicator
