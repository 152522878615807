import General from "./general";
import {NavLink, Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import PaymentMethods from "./payment-methods";
import PaymentDetails from "./payment-details";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import {useSelector} from "react-redux";
import {connectedAccountSelector, userDetailsSelector} from "../../store/user";
import {useEffect, useState} from "react";
import Payouts from "./payouts";
import {hasAllRequiredUserPaymentDetails} from "../../utils";

const tabs = [
    {name: 'General', href: 'general'},
    //{name: 'Notifications', href: '#', current: false},
    //{name: 'Plan', href: '#', current: false},
    {name: 'Payment Details', href: 'payment-details', warn: true},
    {name: 'Payment Methods', href: 'payment-methods'},
    {name: 'Payouts', href: 'payouts', requiredConnectedAccount: false},
    /* {name: 'Payment Methods', href: 'payment-methods'},*/
    //{name: 'Team Members', href: '#', current: false},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Settings = () => {
    let {path, url} = useRouteMatch();
    let history = useHistory();

    const user = useSelector(userDetailsSelector)
    const [showingAccountAlert, setShowingAccountAlert] = useState(false)
    useEffect(() => {
        if (user) {
            setShowingAccountAlert(!hasAllRequiredUserPaymentDetails(user))
        }
    }, [user])

    const connectedAccount = useSelector(connectedAccountSelector)

    return (
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <div className="mt-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/*<h2 className="text-lg leading-6 font-medium text-gray-900">Settings</h2>*/}
                    <div className="mt-2 pt-6 bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-5 pb-6">
                            <h2 id="billing-history-heading" className="text-lg leading-6 font-medium text-gray-900">
                                Settings
                            </h2>
                        </div>

                        {/* Tabs */}
                        <div className="lg:hidden">
                            <label htmlFor="selected-tab" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="selected-tab"
                                name="selected-tab"
                                onChange={e => history.push(url + "/" + e.target.value)}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue={tabs.find((tab) => tab.href === "general").name}
                            >
                                {tabs.map((tab) => ((tab.requiredConnectedAccount && connectedAccount) || !tab.requiredConnectedAccount) && (
                                    <option key={tab.name} value={tab.href}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden lg:block">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8 px-5">
                                    {tabs.map((tab) => ((tab.requiredConnectedAccount && connectedAccount) || !tab.requiredConnectedAccount) && (
                                        <NavLink
                                            to={`${url}/${tab.href}`}
                                            key={tab.name}
                                            className={classNames(
                                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                            )}
                                            activeClassName={"border-indigo-500 text-indigo-600"}
                                        >
                                            {tab.name}
                                            {tab.warn && showingAccountAlert ? (
                                                <ExclamationCircleIcon className="w-5 h-5 ml-3 inline"/>
                                            ) : null}
                                        </NavLink>
                                    ))}
                                </nav>
                            </div>
                        </div>

                        {/* Description list with inline editing */}
                        <Switch>
                            <Route exact path={path}>
                                <Redirect to={path + "/general"}/>
                            </Route>
                            <Route path={`${path}/general`}>
                                <General/>
                            </Route>
                            <Route path={`${path}/payment-details`}>
                                <PaymentDetails/>
                            </Route>
                            <Route path={`${path}/payment-methods`}>
                                <PaymentMethods/>
                            </Route>
                            <Route path={`${path}/payouts`}>
                                <Payouts/>
                            </Route>
                        </Switch>

                    </div>
                </div>

            </div>
        </main>
    )

}

export default Settings
