import React, {useRef, useState} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import FormSuccess from "../../dialogs/form-success";
import {classNames} from "../../utils";
import Spinner from "../../components/spinner";

const functions = getFunctions()

export default function RequestAccess(props) {

    const form = useRef()

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    function onSubmit(e) {
        e.preventDefault()

        setIsSubmitting(true)

        const getInTouch = httpsCallable(functions, "getInTouch")
        getInTouch({
            name,
            email,
            website,
            message
        }).then(() => {
            console.log("complete")
            //cb && cb()
            form.current.reset()
            setOpen(true)
            setIsSubmitting(false)
        }).catch(err => {
            console.error("err", err)
            setIsSubmitting(false)
            alert("There was an error sending your message. Please try again later.")
            form.current.reset()
            //cb && cb(err)
        })
    }

    return (
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <div className="mt-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/*<h2 className="text-lg leading-6 font-medium text-gray-900">Transactions History</h2>*/}
                    <div className="mt-2 bg-white overflow-hidden shadow border bg-gray-50 rounded-lg">
                        <form ref={form} onSubmit={onSubmit} className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 pt-6">
                                <div className="px-4 sm:px-6">
                                    <div>
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                                            Get in touch
                                        </h3>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Please fill out the form below and we will contact you shortly.
                                        </p>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        <div className="sm:col-span-6">
                                            <label htmlFor="name"
                                                   className="block text-sm font-medium text-gray-700">
                                                Name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    required
                                                    autoComplete="name"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>


                                        <div className="sm:col-span-3">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    required
                                                    autoComplete="email"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="website"
                                                   className="block text-sm font-medium text-gray-700">
                                                Website URL
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="url"
                                                    name="website"
                                                    id="website"
                                                    value={website}
                                                    onChange={e => setWebsite(e.target.value)}
                                                    required
                                                    autoComplete="url"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                        {/*<div className="sm:col-span-6">
                                            <label htmlFor="phone"
                                                   className="block text-sm font-medium text-gray-700">
                                                Phone number*
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="phone"
                                                    name="phone"
                                                    type="tel"
                                                    required
                                                    autoComplete="phone"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>*/}

                                        <div className="sm:col-span-6">
                                            <label htmlFor="message"
                                                   className="block text-sm font-medium text-gray-700">
                                                Message
                                            </label>
                                            <div className="mt-1">
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    rows={4}
                                                    value={message}
                                                    onChange={e => setMessage(e.target.value)}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {/*<div className="pt-8 px-4 sm:px-6">
                                    <div>
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
                                        <p className="mt-1 text-sm text-gray-500">
                                            We'll always let you know about important changes, but you pick what
                                            else you want to hear
                                            about.
                                        </p>
                                    </div>
                                    <div className="mt-6">
                                        <fieldset>
                                            <legend className="sr-only">By Email</legend>
                                            <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                                By Email
                                            </div>
                                            <div className="mt-4 space-y-4">
                                                <div className="relative flex items-start">
                                                    <div className="flex h-5 items-center">
                                                        <input
                                                            id="comments"
                                                            name="comments"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="comments"
                                                               className="font-medium text-gray-700">
                                                            News
                                                        </label>
                                                        <p className="text-gray-500">Get notified of any news related to
                                                            Paynco.</p>
                                                    </div>
                                                </div>
                                                <div className="relative flex items-start">
                                                    <div className="flex h-5 items-center">
                                                        <input
                                                            id="candidates"
                                                            name="candidates"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="candidates"
                                                               className="font-medium text-gray-700">
                                                            Promotions
                                                        </label>
                                                        <p className="text-gray-500">Receive promotional messaging.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>*/}
                            </div>

                            <div className="py-5 px-4 sm:px-6">
                                <div className="flex justify-end">
                                    <button
                                        disabled={isSubmitting}
                                        type="reset"
                                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Clear form
                                    </button>
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={classNames(
                                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                            isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        )}
                                    >
                                        {isSubmitting && <Spinner/>}
                                        {isSubmitting ? "Sending..." : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <FormSuccess open={open} setOpen={setOpen}/>
        </main>

    )

}
