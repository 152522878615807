import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Switch} from '@headlessui/react'
import {showToast, TOAST_ALERT, TOAST_INFO} from "../../store/toast";
import {onErrorProfileFallback, profilePhoto} from "../../components/utils";
import {currentProfileSelector, updateProfile} from "../../store/profile";
import {userDetailsSelector} from "../../store/user";
import {getAuth} from 'firebase/auth';
import {getStorage, ref, uploadBytes} from 'firebase/storage';

const auth = getAuth()
const storage = getStorage()


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const General = () => {
    const dispatch = useDispatch()
    const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
    //const [autoUpdateApplicantDataEnabled, setAutoUpdateApplicantDataEnabled] = useState(false)
    const [profilePhotoTimestamp, setProfilePhotoTimestamp] = useState('')

    const fileInput = useRef()

    const user = useSelector(userDetailsSelector)
    const profile = useSelector(currentProfileSelector)
    useEffect(() => {
        if (profile) {
            setName(profile.name)
        }
    }, [profile])

    const [name, setName] = useState('')
    const [isEditingName, setIsEditingName] = useState()
    useEffect(() => {
        if (isEditingName === false && user.displayName !== name && (user.displayName !== null || name)) {
            dispatch(updateProfile({name}))
        }
    }, [isEditingName, dispatch])


    const [password, setPassword] = useState('')
    const [isEditingPassword, setIsEditingPassword] = useState()
    useEffect(() => {
        if (isEditingPassword === false && password) {
            console.log("changing password")
            user.updatePassword(password).then(() => dispatch(showToast({
                text: "Your password was successfully updated!",
                type: TOAST_INFO
            }))).catch(err => {
                if (err.code === "auth/requires-recent-login") {
                    dispatch(showToast({
                        text: "You need to re-authenticate to be allowed to change your password!",
                        persist: true,
                        actionText: "Login again",
                        actionCallback: () => {
                            auth.signOut()
                        },
                        type: TOAST_ALERT
                    }))
                } else {
                    dispatch(showToast({
                        text: "There was an error updating your password!",
                        type: TOAST_ALERT
                    }))
                }
            })
            /*user.updateProfile({
                displayName: name
            }).then(() => dispatch(showToast({
                text: "Your name was successfully updated!",
                type: TOAST_INFO
            }))).catch(err => dispatch(showToast({
                text: "There was an error updating your name!",
                type: TOAST_ALERT
            })))*/
        }
        setPassword('')
    }, [isEditingPassword, dispatch])

    function uploadProfilePhoto(e) {
        if (e.target.files.length > 0) {
            const fileRef = ref(storage, 'u/' + user.uid + '/photo.jpg'),
                uploadTask = uploadBytes(fileRef, e.target.files[0]).then(snapshot => {
                    setProfilePhotoTimestamp("" + (new Date()).getTime())
                }).catch(err => {
                    console.error(err)
                    dispatch(showToast({
                        text: "There was a problem uploading your profile photo",
                        type: TOAST_ALERT
                    }))
                })
        }
    }

    function submitFieldForm(isEditing, setIsEditing) {
        return e => {
            e.preventDefault()
            setIsEditing(!isEditing)
        }
    }

    return (
        <div className="p-5">
            <div className="mt-10 divide-y divide-gray-200">
                <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                        This information will be displayed publicly so be careful what you
                        share.
                    </p>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <form onSubmit={submitFieldForm(isEditingName, setIsEditingName)}
                              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {isEditingName ? <input type="text" autoFocus={isEditingName}
                                                        className="flex-grow -my-3 -ml-3 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        value={name || ""} onChange={e => setName(e.target.value)}/> :
                                    <span className="flex-grow border-l border-white">{name}</span>}
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="submit"
                                        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    {isEditingName ? 'Save' : 'Update'}
                                    </button>
                                </span>
                            </dd>
                        </form>
                        <div
                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">Photo</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <span className="flex-grow">
                                    <img
                                        className="h-8 w-8 rounded-full"
                                        src={profilePhoto(user.uid, false) + "&t=" + profilePhotoTimestamp}
                                        onError={onErrorProfileFallback}
                                        alt=""
                                    />
                                    </span>
                                <span
                                    className="ml-4 flex-shrink-0 flex items-start space-x-4">
                                    <input onChange={uploadProfilePhoto} ref={fileInput} type="file" className="hidden"
                                           accept="image/jpeg"/>
                                    <button
                                        type="button"
                                        onClick={() => fileInput.current.click()}
                                        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                    Update
                                    </button>
                                    </span>
                            </dd>
                        </div>

                        {/*<div
                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                            <dt className="text-sm font-medium text-gray-500">Job title</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">Human Resources Manager</span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        disabled
                                        className="bg-white rounded-md font-medium text-indigo-200 cursor-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                    Update
                                    </button>
                                    </span>
                            </dd>
                        </div>*/}
                    </dl>
                </div>
            </div>

            <div className="mt-10 divide-y divide-gray-200">
                <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Account</h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                        Manage how information is displayed on your account.
                    </p>
                </div>
                <div className="mt-6">
                    <dl className="divide-y divide-gray-200">
                        <div
                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">{user.email}</span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        disabled
                                        className="bg-white rounded-md font-medium text-indigo-200 cursor-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                    Update
                                    </button>
                                    </span>
                            </dd>
                        </div>
                        <form onSubmit={submitFieldForm(isEditingPassword, setIsEditingPassword)}
                              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Password</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {isEditingPassword ? <input type="password" autoFocus={isEditingPassword}
                                                            className="flex-grow -my-3 -ml-3 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            value={password || ""}
                                                            onChange={e => setPassword(e.target.value)}/> :
                                    <span className="flex-grow border-l border-white">******</span>}
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="submit"
                                        className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    {isEditingPassword ? 'Save' : 'Update'}
                                    </button>
                                </span>
                            </dd>
                        </form>
                        <Switch.Group as="div"
                                      className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <Switch.Label as="dt"
                                          className="text-sm font-medium text-gray-500"
                                          passive>
                                Receive Newsletter
                            </Switch.Label>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Switch
                                    checked={automaticTimezoneEnabled}
                                    onChange={setAutomaticTimezoneEnabled}
                                    className={classNames(
                                        automaticTimezoneEnabled ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            automaticTimezoneEnabled ? 'translate-x-5' : 'translate-x-0',
                                            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                        )}
                                    />
                                </Switch>
                            </dd>
                        </Switch.Group>
                        {/*<Switch.Group
                            as="div"
                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200"
                        >
                            <Switch.Label as="dt"
                                          className="text-sm font-medium text-gray-500"
                                          passive>
                                Auto-update applicant data
                            </Switch.Label>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Switch
                                    disabled
                                    checked={autoUpdateApplicantDataEnabled}
                                    onChange={setAutoUpdateApplicantDataEnabled}
                                    className={classNames(
                                        autoUpdateApplicantDataEnabled ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-auto'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            autoUpdateApplicantDataEnabled ? 'translate-x-5' : 'translate-x-0',
                                            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                        )}
                                    />
                                </Switch>
                            </dd>
                        </Switch.Group>*/}
                    </dl>
                </div>
            </div>
        </div>
    )

}

export default General
