import {createSlice} from "@reduxjs/toolkit";

import {getAuth} from 'firebase/auth';
import {collection, getDocs, getFirestore, limit, query, where} from 'firebase/firestore';
import currency from "currency.js";

const auth = getAuth()
const firestore = getFirestore()

const initialState = {
    totalReceived: null,
    totalSent: null,
    totalPending: null,
};

const slice = createSlice({
    name: 'stats',
    initialState,
    reducers: {
        setDashboardStats: (state, action) => {
            state.totalReceived = action.payload.totalReceived
            state.totalSent = action.payload.totalSent
            state.totalPending = action.payload.totalPending
        },
        setTotalSent: (state, action) => {
            state.totalSent = action.payload
        },
        setTotalReceived: (state, action) => {
            state.totalReceived = action.payload
        },
        setTotalPending: (state, action) => {
            state.totalPending = action.payload
        },
    }
})

export default slice.reducer

// ACTIONS

const {setDashboardStats, setTotalSent, setTotalReceived, setTotalPending} = slice.actions

export const fetchDashboardStats = () => async (dispatch) => {
    const uid = auth.currentUser.uid,
        _query = query(collection(firestore, "projects"), where("members", "array-contains-any", [uid]))

    getDocs(_query).then(snap => {
        let totalReceived = currency(0, {symbol: "$"}),
            totalSent = currency(0, {symbol: "$"}),
            totalPending = currency(0, {symbol: "$"})

        snap.forEach(doc => {
            let paymentStatus = doc.get("paymentStatus") || doc.get("status");
            if (paymentStatus === "completed") {
                if (uid === doc.get("buyer")) {
                    // sent
                    totalSent = totalSent.subtract(doc.get("price"))
                } else {
                    // received
                    totalReceived = totalReceived.add(doc.get("price"))
                }
            } else if (paymentStatus !== "canceled") {
                // pending
                totalPending = totalPending.add(doc.get("price"))
                console.log(doc.data())
            }
        })

        dispatch(setDashboardStats({
            totalReceived: totalReceived.format(),
            totalSent: totalSent.format(),
            totalPending: totalPending.format(),
        }))
    })
}

export const getTotalSent = () => async (dispatch) => {
    const uid = auth.currentUser.uid,
        _query = query(collection(firestore, "transactions"), where("uid", "==", uid), where("type", "==", "customerToTransactionGroup"), limit(1000))

    getDocs(_query).then(snap => {
        let amount = currency(0, {symbol: "$"})
        snap.forEach(doc => amount = amount.add(doc.get("amount.value")))
        dispatch(setTotalSent(amount.format()))
    })
}

export const getTotalReceived = () => async (dispatch) => {
    const uid = auth.currentUser.uid,
        _query = query(collection(firestore, "transactions"), where("uid", "==", uid), where("type", "==", "transactionGroupToConnectedAccount"), limit(1000))

    getDocs(_query).then(snap => {
        let amount = currency(0, {symbol: "$"})
        snap.forEach(doc => amount = amount.add(doc.get("amount.value")))
        dispatch(setTotalReceived(amount.format()))
    })
}

export const getTotalPending = () => async (dispatch) => {
    const uid = auth.currentUser.uid,
        _query = query(collection(firestore, "projects"), where("members", "array-contains-any", [uid]), where("paymentStatus", "==", "pending"), limit(1000))
    getDocs(_query).then(snap => {
        let amount = currency(0, {symbol: "$"})
        snap.forEach(doc => {
            if (doc.get("buyer") === uid) {
                amount = amount.subtract(doc.get("pricingStrategy.total.value"))
            } else {
                amount = amount.add(doc.get("pricingStrategy.subtotal.value"))
            }
        })
        dispatch(setTotalPending(amount.format()))
    })
}

// SELECTORS

export const dashboardStatsSelector = state => [state.stats.totalReceived, state.stats.totalSent, state.stats.totalPending]
