import {Link, NavLink, Redirect, Route, Switch, useLocation} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import {useDispatch, useSelector} from "react-redux";
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import {
    BellIcon,
    CashIcon,
    ChatIcon,
    CloudIcon,
    CogIcon,
    HomeIcon,
    InformationCircleIcon,
    MenuAlt1Icon,
    ViewGridIcon,
    XIcon,
} from "@heroicons/react/outline";
import {Fragment, useEffect, useState} from "react";
import {Dialog, Menu, Transition} from "@headlessui/react";
import {ChartBarIcon, ChevronDownIcon, ClipboardListIcon, PencilAltIcon, SupportIcon} from "@heroicons/react/solid";
import Settings from "./pages/settings/index";
import Toast from "./components/toast";
import {showToast, TOAST_ALERT, TOAST_INFO} from "./store/toast";
import Chat from "./pages/chat";
import {onErrorProfileFallback, profilePhoto} from "./components/utils";
import {getProfile, updateOnlineStatus} from "./store/profile";
import {getConnectedAccount, getCurrentUser, userDetailsSelector} from "./store/user";
import {getAuth} from 'firebase/auth';
import {notificationsSelector, removeNotificationsListener, startNotificationsListener} from "./store/notifications";
import {
    orderedConversationsSelector,
    removeConversationsListener,
    seenConversationsSelector,
    startConversationsListener
} from "./store/conversations";
import Transactions from "./pages/transactions";
import Projects from "./pages/projects";
import Files from "./pages/files";
import PasswordRecovery from "./pages/password-recovery";
import SearchPopover from "./components/search-popover";
import Invitation from "./pages/invitation";
import {activeInvitationSelector} from "./store/invitations";
import InvitationDialog from "./dialogs/invitation";
import {APP_ENV} from "./config";
import AdminDashboard from "./pages/admin/dashboard";
import NotFound from "./pages/404";
import {useIntervalWhen} from "rooks";
import FullScreen from "./dialogs/full-screen";
import Demo from "./pages/demo";
import RequestAccess from "./pages/api/request-access";
import ManageDashboard from "./pages/manage/dashboard";

const auth = getAuth()

const defaultNavigation = [
    {name: 'Dashboard', href: '/dashboard', icon: HomeIcon},
    {name: 'Messages', href: '/chat', icon: ChatIcon, badge: true},
    /* {name: 'Storage', href: '/projects', icon: PhotographIcon},*/
    {name: 'Projects', href: '/projects', icon: ViewGridIcon},
    {name: 'Files', href: '/files', icon: CloudIcon},
    {name: 'Transactions', href: '/transactions', icon: CashIcon},
    {name: 'Settings', href: '/settings', icon: CogIcon}
]
const manageNavigation = [
    {name: 'Dashboard', href: '/manage/dashboard', icon: HomeIcon},
    {name: 'API Access', href: '/manage/request-access', icon: ClipboardListIcon},
    {name: 'Transactions', href: '/manage/transactions', icon: ClipboardListIcon, disabled: true},
    {name: 'Customizations', href: '/manage/customizations', icon: PencilAltIcon, disabled: true},
    {name: 'Analytics', href: '/manage/analytics', icon: ChartBarIcon, disabled: true},
    {name: 'Settings', href: '/manage/settings', icon: CogIcon, disabled: true}
]
const secondaryNavigation = [
    {name: 'Help', href: '/@PayncoSupport', icon: SupportIcon}
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function isAdmin(userId) {
    return userId === 'DeOCWFXHaUdWKtTfgl4q0pqKBMr2' || userId === 'ICaP1wwN39eNnctKlCOioTUuefx2' ||
        userId === 'H3TxdseEWugJkZwmlqg3ZxGs9AE3' || userId === '8Xvurymj07Zhs0oQmBA7uhbLPGu2'
}

const App = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const user = useSelector(userDetailsSelector)
    const notifications = useSelector(notificationsSelector)
    const profile = {}
    const dispatch = useDispatch()

    useEffect(() => {
        console.log("App env:", APP_ENV)
        auth.onAuthStateChanged(u => {
            dispatch(getCurrentUser(u))
            if (u) {
                dispatch(getProfile(u.uid))
                dispatch(startNotificationsListener())
                dispatch(startConversationsListener())
                dispatch(getConnectedAccount())
            } else {
                dispatch(removeNotificationsListener())
                dispatch(removeConversationsListener())
            }
        })
        return () => {
            dispatch(removeNotificationsListener())
            dispatch(removeConversationsListener())
        }
    }, [])

    const location = useLocation()
    const [headerShadow, setHeaderShadow] = useState(false)
    const [mainUIHidden, setMainUIHidden] = useState(false)
    const [isAdminThemed, setIsAdminThemed] = useState(false)
    const [isManager, setIsManager] = useState(false)
    const [navigation, setNavigation] = useState(defaultNavigation)
    useEffect(() => {
        let shadow = false, mainUI = false, adminThemed = false, manage = false
        switch (location.pathname.split("/")[1]) {
            case "files":
            case "projects":
            case "transactions":
            case "settings":
                shadow = true
                break;
            case "demo":
            case "sign-up":
                mainUI = true
                adminThemed = true
                break
            case "manage":
                manage = true
            case "admin":
                adminThemed = true
        }
        setMainUIHidden(mainUI)
        setHeaderShadow(shadow)
        setIsAdminThemed(adminThemed)
        setIsManager(manage)
        setSidebarOpen(false)

        if (manage) setNavigation(manageNavigation)
        else setNavigation(defaultNavigation)
    }, [location])

    const conversations = useSelector(orderedConversationsSelector)
    const seen = useSelector(seenConversationsSelector)

    const [notSeenConversationsNo, setNotSeenConversationsNo] = useState(0)
    useEffect(() => {
        if (conversations && seen) {
            const len = Object.keys(seen).length
            if (len > 0) {
                setNotSeenConversationsNo(len)
                document.title = `(${len}) New Message`
                return
            }
        }
        setNotSeenConversationsNo(0)
        document.title = "Paynco - Invite your Clients, Set up Terms and Get Paid safely"
    }, [conversations, seen])

    let isLoading = user === false

    function signOut() {
        auth.signOut().then(() => dispatch(showToast({
            text: "Your have been successfully signed out!",
            type: TOAST_INFO
        }))).catch(err => dispatch(showToast({
            text: "There was an error signing you out!",
            type: TOAST_ALERT
        })))
    }

    const [searchQuery, setSearchQuery] = useState('')

    const invitation = useSelector(activeInvitationSelector)
    const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false)
    useEffect(() => {
        if (invitation.active && user) setIsInvitationDialogOpen(true)
    }, [user, invitation.active])

    useIntervalWhen(() => {
        if (user) {
            try {
                dispatch(updateOnlineStatus())
            } catch (e) {
                console.log(e)
            }
        }
    }, 10000, true)

    return isLoading ? (
            <div/>
        ) :
        <>
            {user || isManager ? (
                <div className="h-screen flex overflow-hidden bg-gray-50">
                    <Transition.Root show={sidebarOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            static
                            className="fixed inset-0 flex z-40 lg:hidden"
                            open={sidebarOpen}
                            onClose={setSidebarOpen}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                            </Transition.Child>
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <div
                                    className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex-shrink-0 flex items-center px-4">
                                        <img
                                            className="h-8 w-auto"
                                            srcSet="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logow.png,
                    https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logow@2x.png 2x"
                                            src="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logow.png"
                                            alt="Paynco"
                                        />
                                    </div>
                                    <nav
                                        className="mt-5 flex-shrink-0 h-full divide-y divide-indigo-800 overflow-y-auto"
                                        aria-label="Sidebar">
                                        <div className="px-2 space-y-1">
                                            {navigation.map((item) => (
                                                <NavLink
                                                    key={item.name}
                                                    to={item.disabled ? "/manage/request-access" : item.href}
                                                    aria-disabled={item.disabled}
                                                    activeClassName={classNames(
                                                        item.disabled ? (isManager ? 'bg-manage-900 text-white' : "bg-indigo-600 text-white") : (isManager ? 'bg-manage-800 text-white' : "bg-indigo-800 text-white")
                                                    )}
                                                    className={classNames(
                                                        item.disabled ? "text-indigo-800" : "text-indigo-100 hover:text-white hover:bg-indigo-600",
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                    )}>
                                                    {item.name}
                                                    {item.badge && notSeenConversationsNo > 0 && (
                                                        <span
                                                            className="-mr-2 ml-1 p-1 rounded-full text-xs font-medium bg-indigo-100 text-gray-800">{notSeenConversationsNo}</span>
                                                    )}
                                                </NavLink>
                                            ))}
                                        </div>
                                        {!isAdminThemed && (
                                            <div className="mt-6 pt-6">
                                                <div className="px-2 space-y-1">
                                                    {secondaryNavigation.map((item) => (
                                                        <NavLink
                                                            key={item.name}
                                                            to={item.href}
                                                            activeClassName={isManager ? 'bg-manage-800 text-white' : "bg-indigo-800 text-white"}
                                                            className="text-indigo-100 hover:text-white hover:bg-indigo-600 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                                                            <item.icon className="mr-4 h-6 w-6 text-indigo-200"
                                                                       aria-hidden="true"/>
                                                            {item.name}
                                                        </NavLink>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </nav>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 w-14" aria-hidden="true">
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </Dialog>
                    </Transition.Root>

                    {/* Narrow sidebar */}
                    {!mainUIHidden && (
                        <div className={classNames(
                            "hidden w-28 overflow-y-auto md:flex flex-col justify-between",
                            isAdminThemed ? "bg-manage-900" : "bg-indigo-600"
                        )}>
                            <div className="w-full py-6 flex flex-col items-center">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="h-8 w-auto"
                                        srcSet="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logow.png,
                    https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logow@2x.png 2x"
                                        src="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logow.png"
                                        alt="Paynco"
                                    />
                                </div>
                                <div className="flex-1 mt-6 w-full px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <NavLink
                                            key={item.name}
                                            to={item.disabled ? "/manage/request-access" : item.href}
                                            aria-disabled={item.disabled}
                                            activeClassName={classNames(
                                                item.disabled ? (isManager ? 'bg-manage-900 text-white' : "bg-indigo-600 text-white") : (isManager ? 'bg-manage-800 text-white' : "bg-indigo-800 text-white")
                                            )}
                                            className={classNames(
                                                item.disabled ? "text-indigo-800" : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
                                                'relative group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.disabled ? 'text-indigo-800' : 'text-indigo-300 group-hover:text-white',
                                                    'h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="mt-2">
                                            {item.name}
                                                {item.badge && notSeenConversationsNo > 0 && (
                                                    <span
                                                        className="-mr-2 ml-1 p-1 rounded-full text-xs font-medium bg-indigo-100 text-gray-800">{notSeenConversationsNo}</span>
                                                )}
                                        </span>

                                        </NavLink>
                                    ))}
                                </div>
                            </div>

                            <div className="flex-grow"/>
                            {!isAdminThemed && (
                                <div className="flex mt-6 mb-2 w-full px-2 space-y-1">
                                    {secondaryNavigation.map((item) => (
                                        <NavLink
                                            key={item.name}
                                            to={item.href}
                                            activeClassName={isManager ? 'bg-manage-800 text-white' : "bg-indigo-800 text-white"}
                                            className={classNames(
                                                'text-indigo-100 hover:bg-indigo-800 hover:text-white',
                                                'relative group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-white' : 'text-indigo-300 group-hover:text-white',
                                                    'h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="mt-2">
                                            {item.name}
                                        </span>

                                        </NavLink>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex flex-col flex-1 overflow-auto focus:outline-none">

                        <header
                            className={classNames(
                                "relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none",
                                headerShadow ? "shadow-sm" : ""
                            )}>
                            {!mainUIHidden && (
                                <button
                                    className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                                    onClick={() => setSidebarOpen(true)}
                                >
                                    <span className="sr-only">Open sidebar</span>
                                    <MenuAlt1Icon className="h-6 w-6" aria-hidden="true"/>
                                </button>
                            )}
                            {/* Search bar */}
                            <div
                                className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">

                                <div className="flex-1 flex">
                                    {!mainUIHidden && user && (
                                        <SearchPopover open={!!searchQuery} setSearchQuery={setSearchQuery}
                                                       searchQuery={searchQuery} user={user}/>
                                    )}
                                </div>

                                <div className="ml-4 flex items-center md:ml-6">
                                    {/*<button
                                        className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">View notifications</span>
                                        <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>*/}

                                    {/* Notifications dropdown */}
                                    <Menu as="div" className="ml-3 relative">
                                        {({open}) => (
                                            <>
                                                <div>
                                                    <Menu.Button
                                                        className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 relative">
                                                        <span className="sr-only">View notifications</span>
                                                        <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                                        {notifications && notifications.length > 0 && <span
                                                            className="absolute bottom-0 right-0 block h-3 w-3 rounded-full ring-2 ring-white bg-red-400"/>}
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <div
                                                        className="origin-top-right absolute right-0 mt-2 w-screen max-w-xs rounded-md shadow-lg bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >
                                                        <span
                                                            className="text-xs px-4 text-gray-300 font-medium">Activity</span>
                                                        <Menu.Items
                                                            className="bg-white mt-1"
                                                        >
                                                            {notifications.map(item => (
                                                                <Menu.Item key={item.id}>
                                                                    <div
                                                                        className='flex items-center px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 cursor-default'
                                                                    >
                                                                        <InformationCircleIcon
                                                                            className="h-4 h-4 mr-3 text-gray-400"/>
                                                                        {item.message}
                                                                    </div>
                                                                </Menu.Item>
                                                            ))}
                                                            {notifications.length === 0 && (
                                                                <div
                                                                    className='block px-4 py-2 text-sm text-gray-700'
                                                                >
                                                                    No new notifications
                                                                </div>
                                                            )}
                                                        </Menu.Items>
                                                    </div>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative">
                                        {({open}) => (
                                            <>
                                                <div>
                                                    <Menu.Button
                                                        className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                                                        <img
                                                            className="h-8 w-8 rounded-full"
                                                            src={profilePhoto(user?.uid || "", false)}
                                                            onError={onErrorProfileFallback}
                                                            alt=""
                                                        />
                                                        <span
                                                            className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                              <span className="sr-only">Open user menu for </span>{profile?.name}</span>
                                                        <ChevronDownIcon
                                                            className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                                                            aria-hidden="true"
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >
                                                        {isAdmin(user?.uid || "") && (
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <Link
                                                                        to={"/admin"}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                        )}
                                                                    >
                                                                        Admin Dashboard
                                                                    </Link>
                                                                )}
                                                            </Menu.Item>
                                                        )}
                                                        <Menu.Item>
                                                            {({active}) => (
                                                                <Link
                                                                    to="/settings"
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                >
                                                                    Settings
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        {user && (
                                                            <Menu.Item onClick={signOut}>
                                                                {({active}) => (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100' : '',
                                                                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                                                        )}
                                                                    >
                                                                Logout
                                                            </span>
                                                                )}
                                                            </Menu.Item>
                                                        )}
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>
                            </div>
                        </header>

                        <Switch>
                            <Route path="/dashboard">
                                <Dashboard/>
                            </Route>
                            <Route path="/settings">
                                <Settings/>
                            </Route>
                            {/*<Route path="/profile/:profileId">
                                <Profile/>
                            </Route>*/}
                            <Route path="/chat/:conversationId">
                                <Chat/>
                            </Route>
                            <Route path="/chat">
                                <Chat/>
                            </Route>
                            <Route path="/projects">
                                <Projects/>
                            </Route>
                            <Route path="/files">
                                <Files/>
                            </Route>
                            <Route path="/transactions">
                                <Transactions/>
                            </Route>
                            <Route path="/invitation/:invitation">
                                <Invitation/>
                            </Route>
                            <Route path="/admin">
                                <AdminDashboard/>
                            </Route>
                            {/*<Route path="/sign-up">
                                <Redirect to="/dashboard"/>
                            </Route>*/}
                            <Route path="/sign-in">
                                <Redirect to="/dashboard"/>
                            </Route>
                            <Route path="/demo">
                                <Demo/>
                            </Route>
                            <Route path="/sign-up"> ¸
                                <RequestAccess/>
                            </Route>
                            {isManager && (
                                <>
                                    <Route path="/manage/dashboard">
                                        <ManageDashboard/>
                                    </Route>

                                    <Route path="/manage" exact={true}>
                                        <Redirect to="/manage/dashboard"/>
                                    </Route>

                                    <Route path="/manage/request-access">
                                        <Redirect to="/sign-up"/>
                                    </Route>
                                </>
                            )}
                            <Route path="/(\@):username">
                                <Invitation/>
                            </Route>
                            <Route path="/" exact={true}>
                                <Redirect to="/dashboard"/>
                            </Route>
                            <Route path="*">
                                <NotFound small homepage={"/dashboard"}/>
                            </Route>
                            {/*<Redirect to="/dashboard"/>*/}
                        </Switch>
                    </div>
                </div>
            ) : (
                <Switch>
                    <Route path="/sign-in">
                        <SignIn/>
                    </Route>
                    <Route path="/sign-up">
                        <SignUp/>
                    </Route>
                    <Route path="/password-recovery">
                        <PasswordRecovery/>
                    </Route>
                    <Route path="/invitation/:invitation">
                        <Invitation/>
                    </Route>
                    <Route path="/(\@):username">
                        <Invitation/>
                    </Route>

                    <Route path="/dashboard">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/settings">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/chat/:conversationId">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/chat">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/projects">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/files">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/transactions">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/admin">
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="/demo">
                        <Demo/>
                    </Route>
                    {isManager && (
                        <>
                            <Route path="/manage/dashboard">
                                <ManageDashboard/>
                            </Route>

                            <Route path="/manage" exact={true}>
                                <Redirect to="/manage/dashboard"/>
                            </Route>

                            <Route path="/manage/request-access">
                                <RequestAccess/>
                            </Route>
                        </>
                    )}
                    <Route path="/" exact={true}>
                        <Redirect to="/sign-in"/>
                    </Route>
                    <Route path="*">
                        <NotFound homepage={"/sign-in"}/>
                    </Route>
                    {/*<Redirect to="/sign-in"/>*/}
                </Switch>
            )}
            <Toast/>
            <FullScreen/>
            <InvitationDialog open={isInvitationDialogOpen} setOpen={setIsInvitationDialogOpen}
                              invitation={invitation} uid={user ? user.uid : ""}/>
        </>
}

export default App;
