import React, {useEffect, useState} from "react";
import {getStorage} from "firebase/storage";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../spinner";
import {downloadFile, fileSelector, getFile, removeFileListener} from "../../store/files";
import {beautifulStorageURL} from "../../utils";

const storage = getStorage();

export default function FileDetailItem({fileId}) {
    const dispatch = useDispatch()

    const file = useSelector(fileSelector(fileId))

    useEffect(() => {
        dispatch(getFile(fileId, true))
        return () => {
            dispatch(removeFileListener(fileId))
        }
    }, [])

    /*useEffect(() => {
        if (fileId) {
            dispatch(getFile(fileId, true))
        } else {

        }
    }, [dispatch, fileId])*/

    const [isDownloading, setIsDownloading] = useState(false)

    function downloadDeliveredFiles() {
        setIsDownloading(true)
        dispatch(downloadFile(fileId, () => {
            setIsDownloading(false)
        }))
    }


    return file ? (
        <div className="py-3 flex justify-between text-sm font-medium">
            <dt className="text-gray-500 line-clamp-1">{beautifulStorageURL(file.bucketFilePath)}</dt>
            <dd className="text-gray-900">
                {isDownloading ? (
                    <Spinner/>
                ) : (
                    <span
                        onClick={downloadDeliveredFiles}
                        className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500">Download</span>
                )}
            </dd>
        </div>
    ) : (
        <div className="py-3 flex justify-between text-sm font-medium">
            <dt className="text-gray-200">...</dt>
        </div>
    )
}
