import React, {Fragment} from 'react'
import AuthorSnippet from "../../components/author-snippet";
import {beautifulDateTime} from "../../utils";
import {Menu, Transition} from "@headlessui/react";
import {DotsVerticalIcon} from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const MessageHeader = props => {
    const {item, text, setReplyTo} = props

    return (
        <div className="flex items-center">
            <div className="flex flex-col justify-center">
                <AuthorSnippet id={item.uid} smDense/>
                <div className="mt-1 ml-7 inline-flex md:hidden items-center px-1">
                    <span className="text-xs sm:text-sm italic text-gray-600">{text}</span>
                </div>
            </div>
            <div className="hidden md:inline-flex items-center px-1">
                <span className="text-xs sm:text-sm italic text-gray-600">{text}</span>
            </div>
            <div className="flex-grow"/>
            <span className="text-xs sm:text-sm text-gray-600 whitespace-nowrap sm:ml-3">
                {item.createdAt &&
                    <time
                        dateTime={item.createdAt.toDate().toISOString()}>{beautifulDateTime(item.createdAt)}</time>
                }
            </span>
            {setReplyTo && (
                <div
                    className="mt-0 flex items-center justify-between sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:justify-start">
                    <Menu as="div" className="ml-0 relative inline-block text-left">
                        {({open}) => (
                            <>
                                <div>
                                    <Menu.Button
                                        className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="h-5 w-5"
                                                          aria-hidden="true"/>
                                    </Menu.Button>
                                </div>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        static
                                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({active}) => (
                                                    <button
                                                        onClick={() => setReplyTo(item)}
                                                        type="button"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'w-full flex justify-between px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <span>Reply</span>
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </>
                        )}
                    </Menu>
                </div>
            )}
        </div>
    )
}

export default MessageHeader
