import {ScaleIcon,} from '@heroicons/react/outline'
import {
    ArrowCircleDownIcon,
    ArrowCircleUpIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronRightIcon,
    ExclamationIcon,
    PlusIcon,
} from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import {userDetailsSelector} from "../store/user";
import {onErrorProfileFallback, profilePhoto} from "../components/utils";
import React, {useEffect, useState} from "react";
import {orderedProjectsSelector, removeProjectsListener, startProjectsListener} from "../store/projects";
import {Link} from "react-router-dom";
import {beautifulDateTime, hasAllRequiredUserPaymentDetails} from "../utils";
import ConversationInvite from "../dialogs/conversation-invite";
import {dashboardStatsSelector, fetchDashboardStats} from "../store/stats";
import AuthorText from "../components/author-text";

const cards = [
    {name: 'Total received', href: '#', icon: ArrowCircleDownIcon, amount: '0,00 $', css: 'text-green-400'},
    {name: 'Total sent', href: '#', icon: ArrowCircleUpIcon, amount: '0,00 $', css: 'text-red-400'},
    {name: 'Total pending', href: '#', icon: ScaleIcon, amount: '0,00 $'},
    // More items...
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Dashboard = () => {
    const user = useSelector(userDetailsSelector)
    const stats = useSelector(dashboardStatsSelector)
    const dispatch = useDispatch()

    useEffect(() => {
        /*dispatch(getTotalSent())
        dispatch(getTotalReceived())
        dispatch(getTotalPending())*/
        dispatch(startProjectsListener())
        dispatch(fetchDashboardStats())
        return () => {
            dispatch(removeProjectsListener())
        }
    }, [])


    const orderedProjects = useSelector(orderedProjectsSelector)

    const [active, setActive] = useState([])
    const [other, setOther] = useState([])

    useEffect(() => {
        const a = [], o = []
        for (let i = 0; i < orderedProjects.length; i++) {
            const p = orderedProjects[i]
            //active, delivered, in-revision, completed
            if (p.status === "completed") {
                if (o.length < 5) o.push(p)
            } else if (a.length < 5) a.push(p)
        }
        setActive(a)
        setOther(o)
    }, [orderedProjects])


    const [inviteOpen, setInviteOpen] = useState(false)

    const [showingAccountAlert, setShowingAccountAlert] = useState(false)
    useEffect(() => {
        if (user) {
            setShowingAccountAlert(!user.customerId || !user.connectedAccountId)
        }
    }, [user])

    return (
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            {/* Page header */}
            <div className="bg-white shadow">
                <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                    <div
                        className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                        <div className="flex-1 min-w-0">
                            {/* Profile */}
                            <div className="flex items-center">
                                <img
                                    className="hidden h-16 w-16 rounded-full sm:block"
                                    src={profilePhoto(user.uid, false)} onError={onErrorProfileFallback}
                                    alt=""
                                />
                                <div>
                                    <div className="flex items-center">
                                        <img
                                            className="h-16 w-16 rounded-full sm:hidden"
                                            src={profilePhoto(user.uid)} onError={onErrorProfileFallback}
                                            alt=""
                                        />
                                        <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                            <AuthorText id={user.uid} fallback={"Welcome"}/>
                                        </h1>
                                    </div>
                                    {hasAllRequiredUserPaymentDetails(user) && (
                                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                            {/*<dt className="sr-only">Company</dt>
                                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                                            <OfficeBuildingIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            Duke street studio
                                        </dd>*/}
                                            <dt className="sr-only">Account status</dt>
                                            <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                                <CheckCircleIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                    aria-hidden="true"/>
                                                Verified account
                                            </dd>
                                        </dl>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                            <button
                                type="button"
                                onClick={() => setInviteOpen(true)}
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <PlusIcon className="mr-2.5 h-5 w-5 text-indigo-100" aria-hidden="true"/>
                                <span>Invite</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8" hidden={!showingAccountAlert}>
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 grid grid-cols-1 gap-5">
                        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-yellow-700">
                                        Your account is not yet verified.{' '}
                                        <Link to="/settings"
                                              className="font-medium underline text-yellow-700 hover:text-yellow-600">
                                            Enter your contact information.
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
                    <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        {/* Card */}
                        {cards.map((card, index) => (
                            <div key={card.name} className="bg-white overflow-hidden shadow rounded-lg">
                                <div className="p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <card.icon className={classNames(
                                                "h-6 w-6",
                                                card.css || "text-gray-400"
                                            )} aria-hidden="true"/>
                                        </div>
                                        <div className="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt className="text-sm font-medium text-gray-500 truncate">{card.name}</dt>
                                                <dd>
                                                    <div
                                                        className="text-lg font-medium text-gray-900">{stats[index] === null ? "..." : stats[index]}</div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="max-w-6xl mx-auto flex flex-col lg:flex-row">


                    <div className="flex-grow flex-1 my-6">
                        <h2 className="px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Active projects
                        </h2>

                        {/* Activity list (smallest breakpoint only) */}
                        <div className="shadow sm:hidden">
                            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {active.map(item => (
                                    <li key={item.id}>
                                        <Link to={"/chat/" + item.conversationId}
                                              className="block px-4 py-4 bg-white hover:bg-gray-50">
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{item.title}</span>
                            <span>
                              <span className="text-gray-900 font-medium">{item.price}</span>{' '}
                                $
                            </span>
                            <time
                                dateTime={item.createdAt.toDate().toISOString()}>{beautifulDateTime(item.createdAt, true)}</time>
                          </span>
                        </span>
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Activity table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="flex flex-col mt-2">
                                    <div
                                        className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                            <tr>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Title
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Amount
                                                </th>
                                                <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                                                    Status
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Due
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {active.map(item => (
                                                <tr key={item.id} className="bg-white">
                                                    <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div className="flex">
                                                            <Link to={"/chat/" + item.conversationId}
                                                                  className="group inline-flex space-x-2 truncate text-sm">
                                                                <CashIcon
                                                                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                                <p className="text-gray-500 truncate group-hover:text-gray-900">{item.title}</p>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span
                                                            className="text-gray-900 font-medium">{item.price} </span>
                                                        $
                                                    </td>
                                                    <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                              <span
                                  className={classNames(
                                      'bg-green-100 text-green-800',
                                      'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                  )}
                              >
                                {item.status}
                              </span>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <time
                                                            dateTime={item.dueDate.toDate().toISOString()}>{beautifulDateTime(item.dueDate, true)}</time>
                                                    </td>
                                                </tr>
                                            ))}
                                            {active.length === 0 && (
                                                <tr className="bg-white">
                                                    <td colSpan="4"
                                                        className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                                                        No results
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        <div className="bg-gray-50 px-5 py-3">
                                            <div className="text-sm">
                                                <Link to="/projects"
                                                      className="font-medium text-indigo-700 hover:text-indigo-900">
                                                    View all
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="flex-grow flex-1 my-6">
                        <h2 className="px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Completed projects
                        </h2>

                        {/* Activity list (smallest breakpoint only) */}
                        <div className="shadow sm:hidden">
                            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {other.map(item => (
                                    <li key={item.id}>
                                        <Link to={"/chat/" + item.conversationId}
                                              className="block px-4 py-4 bg-white hover:bg-gray-50">
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{item.title}</span>
                            <span>
                              <span className="text-gray-900 font-medium">{item.price}</span>{' '}
                                $
                            </span>
                            <time
                                dateTime={item.createdAt.toDate().toISOString()}>{beautifulDateTime(item.createdAt, true)}</time>
                          </span>
                        </span>
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Activity table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="flex flex-col mt-2">
                                    <div
                                        className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                            <tr>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Title
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Amount
                                                </th>
                                                <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                                                    Status
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Date
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {other.map(item => (
                                                <tr key={item.id} className="bg-white">
                                                    <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div className="flex">
                                                            <Link to={"/chat/" + item.conversationId}
                                                                  className="group inline-flex space-x-2 truncate text-sm">
                                                                <CashIcon
                                                                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                                <p className="text-gray-500 truncate group-hover:text-gray-900">{item.title}</p>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span
                                                            className="text-gray-900 font-medium">{item.price} </span>
                                                        $
                                                    </td>
                                                    <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                              <span
                                  className={classNames(
                                      'bg-gray-100 text-gray-800',
                                      'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                  )}
                              >
                                {item.status}
                              </span>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <time
                                                            dateTime={item.createdAt.toDate().toISOString()}>{beautifulDateTime(item.createdAt, true)}</time>
                                                    </td>
                                                </tr>
                                            ))}
                                            {other.length === 0 && (
                                                <tr className="bg-white">
                                                    <td colSpan="4"
                                                        className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                                                        No results
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        <div className="bg-gray-50 px-5 py-3">
                                            <div className="text-sm">
                                                <Link to="/projects"
                                                      className="font-medium text-indigo-700 hover:text-indigo-900">
                                                    View all
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                    Recent activity
                </h2>*/}

                {/* Activity list (smallest breakpoint only) */}
                {/*<div className="shadow sm:hidden">
                    <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                        {transactions.map((transaction) => (
                            <li key={transaction.id}>
                                <a href={transaction.href}
                                   className="block px-4 py-4 bg-white hover:bg-gray-50">
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{transaction.name}</span>
                            <span>
                              <span className="text-gray-900 font-medium">{transaction.amount}</span>{' '}
                                {transaction.currency}
                            </span>
                            <time dateTime={transaction.datetime}>{transaction.date}</time>
                          </span>
                        </span>
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </span>
                                </a>
                            </li>
                        ))}
                    </ul>

                    <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                        aria-label="Pagination"
                    >
                        <div className="flex-1 flex justify-between">
                            <a
                                href="#"
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                            >
                                Previous
                            </a>
                            <a
                                href="#"
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                            >
                                Next
                            </a>
                        </div>
                    </nav>
                </div>*/}

                {/* Activity table (small breakpoint and up) */}
                {/*<div className="hidden sm:block">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-col mt-2">
                            <div
                                className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                    <tr>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Transaction
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Amount
                                        </th>
                                        <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                                            Status
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Date
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {transactions.map((transaction) => (
                                        <tr key={transaction.id} className="bg-white">
                                            <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                <div className="flex">
                                                    <a href={transaction.href}
                                                       className="group inline-flex space-x-2 truncate text-sm">
                                                        <CashIcon
                                                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                            aria-hidden="true"
                                                        />
                                                        <p className="text-gray-500 truncate group-hover:text-gray-900">{transaction.name}</p>
                                                    </a>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span
                                                            className="text-gray-900 font-medium">{transaction.amount} </span>
                                                {transaction.currency}
                                            </td>
                                            <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                              <span
                                  className={classNames(
                                      statusStyles[transaction.status],
                                      'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                  )}
                              >
                                {transaction.status}
                              </span>
                                            </td>
                                            <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                <time dateTime={transaction.datetime}>{transaction.date}</time>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                 Pagination
                                <nav
                                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        <p className="text-sm text-gray-700">
                                            Showing <span className="font-medium">1</span> to <span
                                            className="font-medium">10</span> of{' '}
                                            <span className="font-medium">20</span> results
                                        </p>
                                    </div>
                                    <div className="flex-1 flex justify-between sm:justify-end">
                                        <a
                                            href="#"
                                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Previous
                                        </a>
                                        <a
                                            href="#"
                                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                        >
                                            Next
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
            <ConversationInvite
                user={user}
                open={inviteOpen}
                onClose={setInviteOpen}
            />
        </main>
    )

}

export default Dashboard
