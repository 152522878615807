import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {updateCurrentUserData, userDetailsSelector} from "../../store/user";
import {pad} from "../../utils";
import Spinner from "../../components/spinner";
import {bankAccountCountries} from "../../resources/countries";
import {showToast, TOAST_ALERT, TOAST_INFO} from "../../store/toast";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function onChange(fun) {
    return e => fun(e.target.value)
}

const PaymentDetails = () => {
    const dispatch = useDispatch()

    const user = useSelector(userDetailsSelector)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [line1, setLine1] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')


    useEffect(() => {
        if (user) {
            setFirstName(user.firstName || "")
            setLastName(user.lastName || "")
            setEmail(user.email || "")
            setPhone(user.phone || "")
            if (user.address) {
                setLine1(user.address.line1 || "")
                setPostalCode(user.address.postalCode || "")
                setCity(user.address.city || "")
                setCountry(user.address.country || "")
            }
            if (user.dob) {
                setDateOfBirth(`${user.dob.year}-${pad(user.dob.month, 2)}-${pad(user.dob.day, 2)}`)
            }
        }
    }, [user])

    const [isSubmitting, setIsSubmitting] = useState(false)

    function save(e) {
        e.preventDefault();

        if (!dateOfBirth || !country) return dispatch(showToast({
            text: "Please enter all fields!",
            type: TOAST_ALERT
        }))

        if (phone && !(phone.startsWith("+") || phone.startsWith("00"))) return dispatch(showToast({
            text: "Please use required phone number format starting with country code!",
            type: TOAST_ALERT
        }))

        const _dob = new Date(dateOfBirth),
            dob = {
                day: _dob.getDate(),
                month: _dob.getMonth() + 1,
                year: _dob.getFullYear()
            },
            address = {
                line1, postalCode, city, country
            },
            updates = {
                firstName, lastName, email, phone, address, country, dob,
            }

        setIsSubmitting(true)

        dispatch(updateCurrentUserData(updates, () => {
            setIsSubmitting(false)
            dispatch(showToast({
                text: "Your payment details have been successfully updated!",
                type: TOAST_INFO
            }))
            window.location.reload()
        }))
    }

    return (
        <form onSubmit={save} className="pt-5">
            <div className="mt-10 divide-y divide-gray-200 px-5">
                <div className="space-y-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Payment details</h3>
                    <p className="max-w-2xl text-sm text-gray-500">
                        We will always keep your payment information safe.
                    </p>
                </div>
                <div className="mt-6">
                    <div className="mt-6 grid grid-cols-4 gap-6">
                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                First name
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setFirstName)}
                                value={firstName}
                                type="text"
                                name="first_name"
                                id="first_name"
                                autoComplete="given-name"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                                Last name
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setLastName)}
                                value={lastName}
                                type="text"
                                name="last_name"
                                id="last_name"
                                autoComplete="family-name"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                            <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                Date of birth
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setDateOfBirth)}
                                value={dateOfBirth}
                                type="date"
                                name="dob"
                                id="dob"
                                autoComplete="bday"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                            <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setEmail)}
                                value={email}
                                type="text"
                                name="email_address"
                                id="email_address"
                                autoComplete="email"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                            <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">
                                Phone number
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setPhone)}
                                value={phone}
                                type="tel"
                                pattern="^[\+]?[(]?[0-9]{1,5}[)]?[0-9\s]+$"
                                name="phone_number"
                                id="phone_number"
                                placeholder="+1 555 555 1212"
                                autoComplete="tel"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                Address
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setLine1)}
                                value={line1}
                                type="text"
                                name="address"
                                id="address"
                                autoComplete="address-line1"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                            <label htmlFor="city"
                                   className="flex items-center text-sm font-medium text-gray-700">
                                <span>City</span>
                                {/*<QuestionMarkCircleIcon
                                        className="ml-1 flex-shrink-0 h-5 w-5 text-gray-300"
                                        aria-hidden="true"
                                    />*/}
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setCity)}
                                value={city}
                                type="text"
                                name="city"
                                id="city"
                                autoComplete="address-level1"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                            <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700">
                                ZIP / Postal code
                            </label>
                            <input
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setPostalCode)}
                                value={postalCode}
                                type="text"
                                name="postal_code"
                                id="postal_code"
                                autoComplete="postal-code"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                Country / Region
                            </label>
                            <select
                                disabled={isSubmitting}
                                required
                                onChange={onChange(setCountry)}
                                value={country}
                                id="country"
                                name="country"
                                autoComplete="country"
                                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            >
                                <option aria-readonly disabled value="">-- Select --</option>
                                {bankAccountCountries.map(([code, country]) => (
                                    <option key={code} value={code}>{country}</option>
                                ))}
                            </select>
                        </div>

                        {/*<div className="col-span-4 sm:col-span-2">
                                <label htmlFor="postal_code" className="block text-sm font-medium text-gray-700">
                                    ZIP / Postal
                                </label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    autoComplete="postal-code"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                />
                            </div>*/}
                    </div>
                </div>
            </div>

            <div className="mt-10 px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                    disabled={isSubmitting}
                    type="submit"
                    className={classNames(
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                        isSubmitting ? "bg-gray-300" : "bg-indigo-800 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    )}
                >
                    {isSubmitting && <Spinner/>}
                    {isSubmitting ? "Saving..." : "Save"}
                </button>
            </div>
        </form>
    )

}

export default PaymentDetails
