import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, RadioGroup, Transition} from "@headlessui/react";
import Spinner from "../components/spinner";
import {useDispatch, useSelector} from "react-redux";
import {connectedAccountSelector, getConnectedAccount, userDetailsSelector} from "../store/user";
import PayoutWarning from "./payout-warning";
import {classNames} from "../utils";
import {getBankAccountCountry} from "../resources/countries";
import {ExclamationIcon} from "@heroicons/react/solid";

const settings = [
    {me: true, name: 'I am the buyer', description: 'I will be paying for the completion of this project'},
    {me: false, name: 'I am the seller', description: 'I will be delivering the project', countryCheck: true},
]

const CreateOffer = props => {
    const {open, onClose, onAction} = props;
    const cancelButtonRef = useRef()
    const dispatch = useDispatch()
    const user = useSelector(userDetailsSelector)

    const [titleValue, setTitleValue] = useState("")
    const [descriptionValue, setDescriptionValue] = useState("")
    const [dueDateValue, setDueDateValue] = useState("")
    const [priceValue, setPriceValue] = useState("")
    const [buyerValue, setBuyerValue] = useState(settings[0])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const connectedAccount = useSelector(connectedAccountSelector)

    const [doSubmit, setDoSubmit] = useState(false)
    const [showingWarning, setShowingWarning] = useState(false)

    const [countryName, setCountryName] = useState("")
    const [isCountrySupported, setIsCountrySupported] = useState(false)

    useEffect(() => {
        // reset
        setIsSubmitting(false)
        setShowingWarning(false)
        setDoSubmit(false)
        if (open) {
            setTitleValue("")
            setDescriptionValue("")
            setDueDateValue("")
            setPriceValue("")
            setBuyerValue(settings[0])
            dispatch(getConnectedAccount())
        }
    }, [open])

    useEffect(() => {
        const country = getBankAccountCountry(user?.country)
        setCountryName((country ? country[1] : "") || "")
        setIsCountrySupported((country ? country[3] : false) || false)
    }, [user?.country])

    function submitAction() {
        setIsSubmitting(true)
        setShowingWarning(false)
        onAction({
            title: titleValue,
            description: descriptionValue,
            dueDate: dueDateValue,
            price: priceValue,
            creatorRole: buyerValue.me === true ? "buyer" : "seller"
        }, () => {
            setIsSubmitting(false)
            onClose(false)
        })
    }

    const [allowSubmit, setAllowSubmit] = useState(false)
    useEffect(() => {
        if (connectedAccount || buyerValue.me === true) setAllowSubmit(true)
        if (allowSubmit && doSubmit && !isSubmitting) {
            let showWarning = true

            try {
                showWarning = !connectedAccount || (connectedAccount.requirements.currently_due.indexOf("individual.verification.additional_document") !== -1 || connectedAccount.requirements.currently_due.indexOf("individual.verification.document") !== -1)
            } catch (e) {

            }

            if(showWarning && buyerValue.me !== true) {
                setShowingWarning(true)
            } else {
                submitAction()
            }
        }
    }, [allowSubmit, buyerValue, doSubmit])

    function sendOffer(e) {
        e.preventDefault();
        setDoSubmit(true)
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={onClose}
                >
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <form onSubmit={sendOffer}
                                  className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col">

                                        <div className="p-5">
                                            <div className="pb-5">
                                                <label htmlFor="title"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Title
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={e => setTitleValue(e.target.value)}
                                                        value={titleValue}
                                                        disabled={isSubmitting}
                                                        id="title"
                                                        type="text"
                                                        required
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                        placeholder="Project Title"
                                                    />
                                                </div>
                                            </div>
                                            <div className="pb-5">
                                                <label htmlFor="description"
                                                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                    Description
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <textarea
                                                        onChange={e => setDescriptionValue(e.target.value)}
                                                        value={descriptionValue}
                                                        disabled={isSubmitting}
                                                        id="description"
                                                        rows={3}
                                                        className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                                    />
                                                    <p className="mt-2 text-sm text-gray-500">What is this project
                                                        about?</p>
                                                </div>
                                            </div>
                                            <div className="pb-5">
                                                <label htmlFor="dueDate"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Due date
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={e => setDueDateValue(e.target.value)}
                                                        value={dueDateValue}
                                                        disabled={isSubmitting}
                                                        required
                                                        id="dueDate"
                                                        type="date"
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                        placeholder="YYYY-MM-DD"
                                                    />
                                                </div>
                                            </div>
                                            <div className="pb-5">
                                                <label htmlFor="price"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Price
                                                </label>
                                                <div className="mt-1 relative rounded-md shadow-sm">
                                                    <div
                                                        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                        <span className="text-gray-500 sm:text-sm">$</span>
                                                    </div>
                                                    <input
                                                        onChange={e => setPriceValue(e.target.value)}
                                                        value={priceValue}
                                                        disabled={isSubmitting}
                                                        required
                                                        step="0.01"
                                                        type="number"
                                                        id="price"
                                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                                        placeholder="0.00"
                                                        aria-describedby="price-currency"
                                                    />
                                                    <div
                                                        className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pb-5">
                                                <label htmlFor="buyerValue"
                                                       className="block text-sm font-medium text-gray-700">
                                                    What is your role?
                                                </label>
                                                <RadioGroup id="buyerValue" className="mt-1" value={buyerValue}
                                                            onChange={e => !isSubmitting && setBuyerValue(e)}>
                                                    <RadioGroup.Label className="sr-only">What is your
                                                        role?</RadioGroup.Label>
                                                    <div className="bg-white rounded-md -space-y-px">
                                                        {settings.map((setting, settingIdx) => (
                                                            <RadioGroup.Option
                                                                key={setting.name}
                                                                value={setting}
                                                                disabled={setting.countryCheck && isCountrySupported === false}
                                                                className={({checked}) =>
                                                                    classNames(
                                                                        settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                                        settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                                        checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                                                                        setting.countryCheck && isCountrySupported === false ? 'cursor-default' : 'cursor-pointer',
                                                                        'relative border p-4 flex focus:outline-none'
                                                                    )
                                                                }
                                                            >
                                                                {({active, checked}) => (
                                                                    <>
                <span
                    className={classNames(
                        checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                        active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                        'h-4 w-4 mt-0.5 rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5"/>
                </span>
                                                                        <div className="ml-3 flex flex-col">
                                                                            <RadioGroup.Label
                                                                                as="span"
                                                                                className={classNames(checked ? 'text-indigo-900' : (setting.countryCheck && isCountrySupported === false) ? 'text-gray-500' : 'text-gray-900', 'block text-sm font-medium')}
                                                                            >
                                                                                {setting.name}
                                                                            </RadioGroup.Label>
                                                                            <RadioGroup.Description
                                                                                as="span"
                                                                                className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                                                                            >
                                                                                {setting.description}
                                                                            </RadioGroup.Description>
                                                                            {setting.countryCheck && !isCountrySupported && (
                                                                                <p className="mt-2 text-xs text-orange-500 flex items-center">
                                                                                    <ExclamationIcon
                                                                                        className="h-4 w-4 mr-1"/>
                                                                                    This is not yet available
                                                                                    in {countryName}.
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </RadioGroup.Option>
                                                        ))}
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={classNames(
                                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                            isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        )}
                                    >
                                        {isSubmitting && <Spinner/>}
                                        {isSubmitting ? "Sending offer..." : "Send offer"}
                                    </button>
                                    <button
                                        disabled={isSubmitting}
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onClose(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Close
                                    </button>
                                </div>
                            </form>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <PayoutWarning open={showingWarning} setOpen={setShowingWarning} continueAnyway={submitAction}/>
        </>
    )
}

export default CreateOffer
