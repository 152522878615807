import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from 'firebase/auth';

import {
    addDoc,
    collection,
    doc,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp
} from 'firebase/firestore';

import {getFunctions, httpsCallable} from "firebase/functions";
import {region} from "../config";

const auth = getAuth()
const firestore = getFirestore(),
    functions = getFunctions()

functions.region = region

const initialState = {
    ordered: [],
    conversationId: null,
    listener: null,
    conversation: {},
    conversationListener: null,
};

const slice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setConversation: (state, action) => {
            state.conversationId = action.payload.conversationId
            state.conversation = action.payload.conversation
            state.conversationListener = action.payload.conversationListener
        },
        setOrdered: (state, action) => {
            state.ordered = action.payload.ordered
            state.conversationId = action.payload.conversationId
            state.listener = action.payload.listener
        },
        setEmptyOrdered: (state, action) => {
            state.ordered = []
        },
        setListeners: (state, action) => {
            state.listener = action.payload.listener
            state.conversationListener = action.payload.conversationListener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setConversation, setOrdered, setEmptyOrdered, setListeners} = slice.actions

export const startSingleConversationListener = conversationId => async (dispatch, getState) => {
    const state = getState(),
        uid = auth.currentUser.uid,
        conversationRef = doc(firestore, "conversations", conversationId)

    dispatch(setEmptyOrdered())

    if (state.messages.conversationListener) state.messages.conversationListener() // unsubscribe

    const conversationListener = onSnapshot(conversationRef, doc => {
        dispatch(setConversation({
            conversationListener,
            conversationId,
            conversation: {...doc.data(), id: doc.id, membersOtherThanMe: doc.get("members").filter(m => m !== uid)}
        }))
    }, err => {
        console.log(err.code)
        if (err.code === "permission-denied" && typeof conversationId === "string" && auth.currentUser) {
            const spl = conversationId.split("_")
            if (spl.indexOf(auth.currentUser.uid) !== -1) {
                const createConversation = httpsCallable(functions, "createConversation")
                createConversation({conversationId}).then(() => {
                    dispatch(startSingleConversationListener(conversationId))
                }).catch(err => {
                    console.error(err)
                })
            }
        }
    })
}

export const startMessagesListener = conversationId => async (dispatch, getState) => {
    const state = getState(),
        _query = query(collection(firestore, "conversations", conversationId, "messages"), orderBy("createdAt", "desc"), limit(80))

    if (state.messages.listener) state.messages.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        dispatch(setOrdered({
            listener,
            conversationId,
            ordered: snapshot.docs.reverse().map(doc => ({...doc.data(), id: doc.id}))
        }))
    })
}

export const removeMessagesListeners = () => async (dispatch, getState) => {
    const state = getState()

    if (state.messages.conversationListener) state.messages.conversationListener() // unsubscribe
    if (state.messages.listener) state.messages.listener() // unsubscribe

    dispatch(setListeners({
        listener: null,
        conversationListener: null,
    }))
}

export const sendMessage = (conversationId, text, attachments, replyTo, cb) => async () => {
    const uid = auth.currentUser.uid

    if (!conversationId || !uid || !text) return

    const message = {
        uid,
        text,
        type: "MESSAGE",
        createdAt: serverTimestamp()
    }

    if (attachments && attachments.length > 0) message.attachments = attachments
    if (replyTo && replyTo.id) message.replyTo = {...replyTo}

    addDoc(collection(firestore, "conversations", conversationId, "messages"), message).then(() => {
        cb && cb(true)
    }).catch(err => {
        cb && cb(false, err)
    })
}


// SELECTORS

export const orderedMessagesSelector = state => state.messages.ordered
export const singleConversationSelector = state => state.messages.conversation
