import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import algoliasearch from 'algoliasearch/lite';
import {ClipboardCopyIcon, SearchIcon} from "@heroicons/react/solid";
import {showToast, TOAST_INFO} from "../store/toast";
import {useDispatch, useSelector} from "react-redux";
import {onErrorProfileFallback} from "../components/utils";
import InvitationDialog from "./invitation";
import {usersIndex} from "../config";
import {currentProfileSelector} from "../store/profile";

const client = algoliasearch('L1NI0TL9QV', 'aac89fec392109d89b0a15e6ecebdb44');
const index = client.initIndex(usersIndex);

const ConversationInvite = props => {
    const {user, open, onClose} = props;
    const cancelButtonRef = useRef()
    const dispatch = useDispatch()

    const currentProfile = useSelector(currentProfileSelector)

    const [searchQuery, setSearchQuery] = useState('')
    const [searchHits, setSearchHits] = useState([])

    useEffect(() => {
        if (open) {
            index.search(searchQuery, {
                hitsPerPage: 10
            }).then(({hits}) => setSearchHits(hits.filter(p => p.objectID !== user.uid)))
        }
    }, [open, searchQuery])



    function selectAll(e) {
        const range = document.createRange();
        range.selectNode(e.target);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    }

    function copyToClipboard(text) {
        if (!navigator.clipboard) return
        navigator.clipboard.writeText(text).then(() => {
            dispatch(showToast({
                text: "Link copied to clipboard",
                type: TOAST_INFO
            }))
        })
    }

    const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false)
    const [invitationPersonUid, setInvitationPersonUid] = useState(false)

    function message(personUid) {
        setInvitationPersonUid(personUid)
        setIsInvitationDialogOpen(true)
        onClose(false)
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={onClose}
                >
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="sm:gap-3 bg-white shadow pt-2 px-4 sm:p-6">
                                    <div className="text-center">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Invite
                                        </Dialog.Title>
                                    </div>

                                    <div className="px-3 mt-5">
                                        <label htmlFor="search" className="sr-only">
                                            Search
                                        </label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <div
                                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                                                aria-hidden="true"
                                            >
                                                <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true"/>
                                            </div>
                                            <input
                                                onChange={e => setSearchQuery(e.target.value)}
                                                value={searchQuery}
                                                type="text"
                                                name="search"
                                                id="search"
                                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-300 rounded-md"
                                                placeholder="Search people"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:gap-3 bg-gray-50">
                                    <div className="flow-root px-6 overflow-y-auto" style={{height: 145}}>
                                        <ul className="divide-y divide-gray-200">
                                            {searchHits.map(person => person.name ? (
                                                <li key={person.objectID} className="py-4 px-4">
                                                    <div className="flex items-center space-x-4">
                                                        <div className="flex-shrink-0">
                                                            <img className="h-8 w-8 rounded-full" src={person.photo}
                                                                 onError={onErrorProfileFallback} alt=""/>
                                                        </div>
                                                        <div className="flex-1 min-w-0">
                                                            <p className="text-sm font-medium text-gray-900 truncate">{person.name}</p>
                                                            <p className="text-sm text-gray-500 truncate">{'@' + person.username}</p>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                onClick={() => message(person.objectID)}
                                                                className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                                            >
                                                                Message
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            ) : null)}
                                        </ul>
                                        {searchHits.length === 0 && (
                                            <div
                                                className="flex items-center justify-center mt-5 text-gray-500 text-sm">
                                                <span className="text-center">No results</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="sm:gap-3 mt-5 px-6">
                                    <h3 className="font-medium text-center text-gray-900">Or share link</h3>
                                    <div className="flex items-center justify-between bg-gray-50 rounded-full">
                                        <span className="text-sm text-gray-500 italic truncate px-4"
                                          onClick={selectAll}>https://app.paynco.com/@{currentProfile?.username}</span>
                                        <button
                                            onClick={() => copyToClipboard("https://app.paynco.com/@" + currentProfile?.username)}
                                            type="button"
                                            className="bg-white -mr-1 rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        >
                                            <ClipboardCopyIcon className="h-5 w-5" aria-hidden="true"/>
                                            <span className="sr-only">Copy link</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 px-5 flow-row-dense">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => onClose(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <InvitationDialog open={isInvitationDialogOpen} setOpen={setIsInvitationDialogOpen}
                              invitationUid={invitationPersonUid} uid={user ? user.uid : ""}/>
        </>
    )
}

export default ConversationInvite
