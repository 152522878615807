import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    stack: [],
    open: false,
    //index: 0 // todo
};

const slice = createSlice({
    name: 'fullScreen',
    initialState,
    reducers: {
        set: (state, action) => {
            state.stack = [...action.payload.items]
            state.open = action.payload.open === true
        },
        toggleVisibility: (state, action) => {
            state.open = action.payload
        },
    }
})

export default slice.reducer

// ACTIONS

const {set, toggleVisibility} = slice.actions

export const setGallery = ({items = [], open = false, index = 0}) => set({items, open, index})
export const clearGallery = () => set({items: [], open: false})

export const setFullScreenVisible = open => toggleVisibility(open)

// SELECTORS

export const fullScreenStack = state => state.fullScreen.stack
export const fullScreenOpen = state => state.fullScreen.open
