import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import {getProject, projectSelector} from "../store/projects";
import Spinner from "../components/spinner";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProjectRevision = props => {
    const {projectRef, open, setOpen, onAction} = props;
    const cancelButtonRef = useRef()

    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(() => {
        setMessageValue("")
        setIsSubmitting(false)
    }, [open])

    const [messageValue, setMessageValue] = useState("")

    const dispatch = useDispatch()
    const project = useSelector(projectSelector(projectRef ? projectRef.id : '')) || {}

    useEffect(() => {
        if (projectRef) dispatch(getProject(projectRef))
    }, [projectRef])

    function sendOffer(e) {
        e.preventDefault()
        setIsSubmitting(true)
        onAction({
            message: messageValue
        }, () => {
            setIsSubmitting(false)
            setOpen(false)
        })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div
                    className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <form
                            onSubmit={sendOffer}
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex flex-col">

                                    <div className="p-5">
                                        <div className="pb-5">
                                            <label htmlFor="title"
                                                   className="block text-sm font-medium text-gray-700">
                                                Project
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    value={project.title}
                                                    readOnly
                                                    id="title"
                                                    type="text"
                                                    className="shadow-sm focus:ring-white focus:border-gray-300 block w-full sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>
                                        <div className="pb-5">
                                            <label htmlFor="message"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Your message
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <textarea
                                                        disabled={isSubmitting}
                                                        onChange={e => setMessageValue(e.target.value)}
                                                        value={messageValue}
                                                        id="message"
                                                        rows={3}
                                                        required
                                                        className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                                    />
                                                <p className="mt-2 text-sm text-gray-500">Describe what needs to be done
                                                    to resolve any issues</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className={classNames(
                                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                        isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                >
                                    {isSubmitting && <Spinner/>}
                                    {isSubmitting ? "Submitting..." : "Ask for Revision"}
                                </button>
                                <button
                                    disabled={isSubmitting}
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ProjectRevision
