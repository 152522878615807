export const APP_ENV = process.env.REACT_APP_ENV,
    buyerFeePercentage = 4.4, // %
    buyerFeeFactor = 1.044,
    buyerFeeFixedAmount = 0.50 // 50 cents on top of fee percentage
const isProd = APP_ENV === "prod"

export const stripePublishableKey = isProd ? "pk_live_51JAG3MF7Ec2m1vMpNcyEyhc5BMxIHZDrba0IPHd1XWK36deM6wfWIJRIAWbVWeD3XroGfKvOgMpfm3ffQnhV5dRo00HqYT17g5" : "pk_test_51JAG3MF7Ec2m1vMpI8d6wMUo3QTLnX1AXDWfE1AcxqgDPOKFrchvq2ajYNOCsFloRzohwcRxjvSPmtXFMXHOg67x00Q97Oy9r7"
export const firebaseConfig = isProd ? {
    apiKey: "AIzaSyAgwzZHxT3GPJBYBnGLmUwUcCOvJucLnUA",
    authDomain: "paynco-e5a00.firebaseapp.com",
    projectId: "paynco-e5a00",
    storageBucket: "paynco-e5a00.appspot.com",
    messagingSenderId: "69838084188",
    appId: "1:69838084188:web:ad64c1fce62eb401cb6e99"
} : {
    apiKey: "AIzaSyBhS0ylHUTtnxRdenP6sTJcOzcwniZh8JM",
    authDomain: "paynco-test.firebaseapp.com",
    projectId: "paynco-test",
    storageBucket: "paynco-test.appspot.com",
    messagingSenderId: "848120743409",
    appId: "1:848120743409:web:ccfe3569e5df3be462b050",
    measurementId: "G-GMXES4662Y"
}
export const bucket = isProd ? "paynco-e5a00.appspot.com" : "paynco-test.appspot.com"
export const region = isProd ? "europe-west1" : "europe-west3"
export const usersIndex = isProd ? "prod_USERS" : "test_USERS"

export const supportUid = isProd ? "YMpcYibxLSePctvSenzB344PZrJ3" : "WWZcGFdNsiQ4s116SeWLPOx5lZk2"
