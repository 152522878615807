import {useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import {showToast, TOAST_ALERT, TOAST_INFO} from "../store/toast";
import {useDispatch} from "react-redux";
import {ArrowLeftIcon, ExclamationCircleIcon} from "@heroicons/react/solid";

const auth = getAuth()

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PasswordRecovery = () => {
    const dispatch = useDispatch()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [redirect, setRedirect] = useState()
    const [email, setEmail] = useState('')

    const [emailError, setEmailError] = useState('')

    useEffect(() => {
        setEmailError('')
    }, [email])

    function onSubmit(e) {
        e.preventDefault()

        setIsSubmitting(true)

        sendPasswordResetEmail(auth, email).then(() => {
            dispatch(showToast({
                text: "We've sent you password reset email!",
                type: TOAST_INFO
            }))
            setRedirect("/")
        }).catch(err => {
            console.log(err, err.code)
            if (err) {
                switch (err.code) {
                    case "auth/too-many-requests":
                        setEmailError("There were too many requests! Please wait and try again later.")
                        return dispatch(showToast({
                            text: "There were too many requests! Please wait and try again later.",
                            type: TOAST_ALERT
                        }))
                    case "auth/user-not-found":
                        setEmailError("User with this email does not exist!")
                        return dispatch(showToast({
                            text: "User with this email does not exist!",
                            type: TOAST_ALERT
                        }))
                }
            }
            dispatch(showToast({
                text: "There was an error resetting your password!",
                type: TOAST_ALERT
            }))
        }).finally(() => {
            setIsSubmitting(false)
        })
    }

    return redirect ? <Redirect to={redirect}/> : (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-12 w-auto"
                    srcSet="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logoc.png,
                    https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logoc@2x.png 2x"
                    src="https://storage.googleapis.com/paynco-e5a00.appspot.com/assets/logoc.png"
                    alt="Paynco"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Send a password reset email</h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    <Link to="/sign-in" className="font-medium text-indigo-600 hover:text-indigo-500 inline-flex items-center">
                        <ArrowLeftIcon className="h-4 w-4 block -mr-2" aria-hidden="true"/>
                        <span className="block mx-4">Go back to login</span>
                    </Link>
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={onSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    disabled={isSubmitting}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    aria-invalid={!!emailError}
                                    aria-describedby={emailError ? "email-error" : ""}
                                    required
                                    className={classNames(
                                        "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm",
                                        emailError ? "border-red-300 focus:ring-red-500 focus:border-red-500 placeholder-red-300 text-red-900" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-400"
                                    )}
                                />
                                {emailError && (
                                    <div
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                                    </div>
                                )}
                            </div>
                            {emailError && (
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {emailError}
                                </p>
                            )}
                        </div>

                        <div>
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {isSubmitting ? "Sending..." : "Send"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default PasswordRecovery
