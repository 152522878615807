import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from 'firebase/auth';
import {collection, getFirestore, onSnapshot, orderBy, query} from 'firebase/firestore';

import {getFunctions} from "firebase/functions";
import {region} from "../config";

const auth = getAuth(),
    firestore = getFirestore(),
    functions = getFunctions()

functions.region = region

const initialState = {
    ordered: [],
    listener: null,
    '': null
};

const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setAllOrdered: (state, action) => {
            state.ordered = action.payload.ordered
            state.listener = action.payload.listener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setAllOrdered} = slice.actions

export const startAllProjectsListener = () => async (dispatch, getState) => {
    const state = getState(),
        _query = query(collection(firestore, "projects"), orderBy("createdAt", "desc"))

    if (state.admin.listener) state.admin.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        dispatch(setAllOrdered({listener, ordered: snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))}))
    })
}

export const removeAllProjectsListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.admin.listener) state.admin.listener() // unsubscribe
}

// SELECTORS

export const allOrderedProjectsSelector = state => state.admin.ordered
