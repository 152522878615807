import {useDispatch} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {pad} from "../../utils";
import {showToast, TOAST_ALERT} from "../../store/toast";
import {getFunctions, httpsCallable} from "firebase/functions";
import Spinner from "../../components/spinner";
import {region} from "../../config";
import {PlusIcon} from "@heroicons/react/outline";
import {Menu, Transition} from '@headlessui/react'


const functions = getFunctions()

functions.region = region

function listCustomerCards() {
    return httpsCallable(functions, "listCustomerCards")()
}

function detachCustomerCard(pmId) {
    return httpsCallable(functions, "detachCustomerCard")(pmId)
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PaymentMethods = () => {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)
    const [cards, setCards] = useState([])

    function refreshCards() {
        setIsLoading(true)
        listCustomerCards().then(({data}) => {
            if (data && data.stripeResult) {
                console.log(data.stripeResult)
                setCards(data.stripeResult.data || [])
            }
        }).catch(err => {
            console.error(err)
            dispatch(showToast({
                text: "There was an error fetching data. Please try again later.",
                type: TOAST_ALERT
            }))
        }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        refreshCards()
    }, [])

    const [removingCard, setRemovingCard] = useState('')

    function onRemoveCard(pmId) {
        return () => {
            setRemovingCard(pmId)
            detachCustomerCard(pmId).then(() => {
                refreshCards()
            }).catch(err => {
                console.error(err)
                dispatch(showToast({
                    text: "There was an error removing card. Please try again later.",
                    type: TOAST_ALERT
                }))
            }).finally(() => setRemovingCard(''))
        }
    }

    return (
        <div className="p-5">
            <div className="mt-10 divide-y divide-gray-200">
                <div className="flex justify-between items-center">
                    <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Payment methods</h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                            We will always keep your payment information safe.
                        </p>
                    </div>
                    <div>
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <Menu.Button
                                    className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-md border border-gray-100 bg-white text-sm font-medium text-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400">
                                    <PlusIcon className="mr-2.5 h-5 w-5 text-gray-200" aria-hidden="true"/>
                                    <span>Add new</span>
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                    <div className="px-4 py-3">
                                        <p className="text-sm text-gray-700">This feature is <b>not yet</b> available in
                                            this version</p>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
                <div className="mt-6 pb-5">
                    {cards.map(item => item.card && (
                        <div key={item.id} className="mt-5">
                            <div
                                className="py-5 sm:flex sm:items-start sm:justify-between">
                                <h4 className="sr-only">{item.card.brand.toUpperCase()}</h4>
                                <div className="sm:flex sm:items-start">
                                    <svg className="h-8 w-auto sm:flex-shrink-0 sm:h-6" viewBox="0 0 36 24"
                                         aria-hidden="true">
                                        <rect width={36} height={24} fill="#224DBA" rx={4}/>
                                        <path
                                            fill="#fff"
                                            d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                        />
                                    </svg>
                                    <div className="mt-3 sm:mt-0 sm:ml-4">
                                        <div className="text-sm font-medium text-gray-900">Ending
                                            with {item.card.last4}</div>
                                        <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                            <div>Expires {pad(item.card.exp_month, 2)}/{item.card.exp_year.toString().substring(2)}</div>
                                            <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                    &middot;
                  </span>
                                            <div className="mt-1 sm:mt-0">Last updated
                                                on {(new Date(item.created * 1000)).toLocaleDateString()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                                    <button
                                        disabled={!!removingCard}
                                        onClick={onRemoveCard(item.id)}
                                        type="button"
                                        className={classNames(
                                            "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md sm:text-sm",
                                            !!removingCard ? "bg-gray-100 text-gray-700" : "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        )}
                                    >
                                        {removingCard === item.id ? (
                                            <>
                                                <Spinner/>
                                                Removing...
                                            </>
                                        ) : "Remove"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    {isLoading ? (
                        <div className="my-5 flex justify-center">
                            <Spinner big/>
                        </div>
                    ) : (!cards || cards.length === 0) && (
                        <div className="text-center my-5">
                            <p className="mt-1 text-sm text-gray-500">No payment methods yet.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

}

export default PaymentMethods
