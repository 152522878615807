import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    stack: []
};

const slice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        appendToast: (state, action) => {
            state.stack = [...state.stack, action.payload]
        },
        destroyToast: (state, action) => {
            state.stack.shift()
        },
    }
})

export default slice.reducer

// ACTIONS

const {appendToast, destroyToast} = slice.actions

export const TOAST_INFO = "TOAST_INFO"
export const TOAST_WARNING = "TOAST_WARNING"
export const TOAST_ALERT = "TOAST_ALERT"

export const showToast = appendToast

export const dismissToast = () => {
    return destroyToast()
}

// SELECTORS

export const toastStack = state => state.toast.stack
