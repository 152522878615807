import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from '@headlessui/react'
import {connectedAccountSelector, getConnectedAccount, getCurrentUser, userDetailsSelector} from "../../store/user";
import {showToast, TOAST_ALERT} from "../../store/toast";
import {getFunctions, httpsCallable} from "firebase/functions";
import Spinner from "../../components/spinner";
import {CashIcon, InformationCircleIcon, OfficeBuildingIcon, PlusIcon, UserIcon} from "@heroicons/react/solid";
import {bankAccountCountries} from "../../resources/countries";
import {
    pendingTransfersSelector,
    removePendingTransfersListener,
    startPendingTransfersListener
} from "../../store/payouts";
import ProjectText from "../../components/project-text";
import {Link} from "react-router-dom";
import {region} from "../../config";
import {hasAllRequiredUserPaymentDetails} from "../../utils";

function onChange(fun) {
    return e => fun(e.target.value)
}

const functions = getFunctions()

functions.region = region

function listBankAccounts() {
    return httpsCallable(functions, "listBankAccounts")()
}

function addPayoutBankAccount(accountHolderName, accountNumber, country, currency) {
    return httpsCallable(functions, "addPayoutBankAccount")({accountHolderName, accountNumber, country, currency})
}

function getConnectOnBoardingLink() {
    return httpsCallable(functions, "getConnectOnBoardingLink")()
}

function runTransfer(transferId) {
    return httpsCallable(functions, "runTransfer")({transferId})
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Payouts = () => {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)
    const [list, setList] = useState([])

    function refreshList() {
        setIsLoading(true)
        listBankAccounts().then(({data}) => {
            if (data && data.stripeResult) {
                console.log(data.stripeResult)
                setList(data.stripeResult.data || [])
            }
        }).catch(err => {
            console.error(err)
            /*dispatch(showToast({
                text: "There was an error fetching data. Please try again later.",
                type: TOAST_ALERT
            }))*/
        }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        dispatch(getCurrentUser())
        dispatch(getConnectedAccount())
        dispatch(startPendingTransfersListener())
        refreshList()
        return () => {
            removePendingTransfersListener()
        }
    }, [])

    const pendingTransfers = useSelector(pendingTransfersSelector)

    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const cancelCreateButtonRef = useRef()

    const [accountHolder, setAccountHolder] = useState('')
    const [countryIndex, setCountryIndex] = useState('')
    const [accountNumber, setAccountNumber] = useState('')

    const [isSubmitting, setIsSubmitting] = useState(false)

    function onCreateSubmit(e) {
        e.preventDefault();

        const [countryCode, _, currency] = bankAccountCountries[countryIndex]

        setIsSubmitting(true)

        addPayoutBankAccount(accountHolder, accountNumber, countryCode, currency).then(() => {
            setIsSubmitting(false)
            setIsCreateDialogOpen(false)
            refreshList()
        }).catch(e => {
            console.error(e)
            setIsSubmitting(false)
            setIsCreateDialogOpen(false)
            dispatch(showToast({
                text: "There was an error adding your bank account. Please try again later.",
                type: TOAST_ALERT
            }))
        })
    }

    /*const [removingCard, setRemovingCard] = useState('')

    function onRemoveCard(pmId) {
        return () => {
            setRemovingCard(pmId)
            detachCustomerCard(pmId).then(() => {
                refreshCards()
            }).catch(err => {
                console.error(err)
            }).finally(() => setRemovingCard(''))
        }
    }*/

    const userDetails = useSelector(userDetailsSelector)
    const connectedAccount = useSelector(connectedAccountSelector)
    const [showingAccountAlert, setShowingAccountAlert] = useState(true)
    const [showingDetailsAlert, setShowingDetailsAlert] = useState(true)
    useEffect(() => {
        if (userDetails) {
            console.log(connectedAccount)
            try {
                setShowingDetailsAlert(!hasAllRequiredUserPaymentDetails(userDetails))
                setShowingAccountAlert(!hasAllRequiredUserPaymentDetails(userDetails) || (connectedAccount.requirements.currently_due.indexOf("individual.verification.additional_document") !== -1 || connectedAccount.requirements.currently_due.indexOf("individual.verification.document") !== -1))
            } catch (e) {
                setShowingAccountAlert(false)
            }
        }
    }, [connectedAccount, userDetails])

    const [verify, setVerify] = useState(false)
    useEffect(() => {
        if (verify) {
            getConnectOnBoardingLink().then(({data}) => {
                setVerify(false)
                if (data && data.stripeResult && data.stripeResult.url) {
                    window.location = data.stripeResult.url
                }
                console.log(data)
            }).catch(err => {
                setVerify(false)
                console.error(err)
                dispatch(showToast({
                    text: "There was an error fetching data. Please try again later.",
                    type: TOAST_ALERT
                }))
            })
        }
    }, [verify])

    const [payoutId, setPayoutId] = useState('')

    function payout(transferId) {
        return () => {
            setPayoutId(transferId)
            runTransfer(transferId).then(res => {
                setPayoutId('')
                console.log(res)
            }).catch(err => {
                setPayoutId('')
                console.log(err)
                dispatch(showToast({
                    text: "There was an error submitting your request. Please try again later.",
                    type: TOAST_ALERT
                }))
            })
        }
    }

    return (
        <div className="p-5">
            <div className="rounded-md bg-red-50 p-4" hidden={!showingDetailsAlert}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-red-700">1. Enter your payment details.</p>
                        <p className="mt-3 text-sm md:mt-0 md:ml-6">
                            <Link to="/settings/payment-details"
                                  className="whitespace-nowrap font-medium text-red-700 hover:text-red-600">
                                Payment details <span aria-hidden="true">&rarr;</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            <div className={classNames(
                "rounded-md bg-red-50 p-4",
                showingDetailsAlert ? "mt-4" : ""
            )} hidden={!showingAccountAlert}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-red-700">{showingDetailsAlert ? "2. Enable payouts by verifying your identity." : "Verify your identity to enable payouts."}</p>
                        <p className="mt-3 text-sm md:mt-0 md:ml-6">
                            {verify ? (
                                <Spinner/>
                            ) : (
                                <button disabled={showingDetailsAlert} type="button" onClick={() => setVerify(true)}
                                        className={classNames(
                                            "whitespace-nowrap font-medium",
                                            showingDetailsAlert ? "text-red-200 cursor-default" : "text-red-700 hover:text-red-600"
                                        )}>
                                    Verify identity <span aria-hidden="true">&rarr;</span>
                                </button>
                            )}
                        </p>
                    </div>
                </div>
            </div>

            <div className={classNames(
                "rounded-md bg-green-50 p-4",
                showingAccountAlert ? "mt-4" : ""
            )} hidden={!(pendingTransfers.length > 0)}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3 w-full">
                        <h3 className="text-sm font-medium text-green-800">Pending payouts</h3>
                        <div className="mt-2 text-sm text-green-700">
                            {/*<p>You can wait for automatic payout or manually withdraw money to your bank account.</p>*/}
                        </div>
                        <div className="mt-4">
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto -ml-12 mr-4 sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-0 sm:pr-8 lg:px-2 lg:pr-8">
                                        <div className="overflow-hidden border-green-200">
                                            <table className="min-w-full divide-y divide-green-200 table-fixed">
                                                <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-green-700 uppercase tracking-wider"
                                                    >
                                                        Project
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-green-700 uppercase tracking-wider"
                                                    >
                                                        Amount
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-green-700 uppercase tracking-wider"
                                                    >
                                                        Platform fee
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-green-700 uppercase tracking-wider"
                                                    >
                                                        Available for payout
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-green-700 uppercase tracking-wider"
                                                    >
                                                        Status
                                                    </th>
                                                    <th scope="col" className="relative px-6 py-3 w-1/4">
                                                        <span className="sr-only">Action</span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {pendingTransfers.filter(it => it.status === "pending").map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-green-900">
                                                            <ProjectText id={item.projectId}/></td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-green-700">{item.subtotal?.formatted}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-green-700">{item.fee2?.formatted || "$0.00"}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-green-700">{item.payout?.formatted || item.subtotal?.formatted}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-green-700 capitalize">{item.transferCompleted ? "Processed" : item.status}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-right text-green-800 text-sm font-medium flex items-center justify-end">
                                                            {payoutId === item.id ? (
                                                                <Spinner/>
                                                            ) : item.transferCompleted ? (
                                                                <span className="text-green-200">
                                                                    Payout processed
                                                                </span>
                                                            ) : showingAccountAlert ? (
                                                                <span className="text-green-200">
                                                                    Payout now
                                                                </span>
                                                            ) : (
                                                                <button type="button" onClick={payout(item.id)}
                                                                        className="text-green-600 hover:text-green-900">
                                                                    Payout now
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-10 divide-y divide-gray-200">
                <div className="flex justify-between items-center">
                    <div className="space-y-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Payouts</h3>
                        <p className="max-w-2xl text-sm text-gray-500">
                            Enable automatic payouts by adding your bank account.
                        </p>
                    </div>
                    <button
                        disabled={showingDetailsAlert}
                        onClick={() => setIsCreateDialogOpen(true)}
                        type="button"
                        className={classNames(
                            "flex flex-row flex-no-wrap -ml-px relative items-center px-4 py-2 rounded-md border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600",
                            showingDetailsAlert ? "bg-gray-50 text-gray-400 cursor-default" : "bg-white border-gray-300 text-gray-900 hover:bg-gray-50 "
                        )}
                    >
                        <PlusIcon className="mr-2.5 h-5 w-5 opacity-75" aria-hidden="true"/>
                        <span>Add </span><span className="hidden md:inline-block">&nbsp;bank account</span>
                    </button>
                </div>

                <div className="mt-6 pb-5">
                    <ul className="divide-y divide-gray-200">
                        {list.map(item => (
                            <li key={item.id}>
                                <a href="#" className="block hover:bg-gray-50">
                                    <div className="px-4 py-4 sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <p className="text-sm font-medium text-indigo-600 truncate">Bank
                                                account {item.country}****{item.last4}</p>
                                            <div className="ml-2 flex-shrink-0 flex">
                                                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize">
                                                    {item.status}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-2 sm:flex sm:justify-between">
                                            <div className="sm:flex">
                                                <p className="flex items-center text-sm text-gray-500">
                                                    <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                              aria-hidden="true"/>
                                                    {item.account_holder_name}
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                    <OfficeBuildingIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"/>
                                                    {item.bank_name}
                                                </p>
                                            </div>
                                            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                                <CashIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                          aria-hidden="true"/>
                                                <p>
                                                    Payouts in <span className="uppercase">{item.currency}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                    {isLoading ? (
                        <div className="my-5 flex justify-center">
                            <Spinner big/>
                        </div>
                    ) : (!list || list.length === 0) && (
                        <div className="text-center my-5">
                            <p className="mt-1 text-sm text-gray-500">No bank accounts yet.</p>
                        </div>
                    )}
                </div>
            </div>
            <Transition.Root show={isCreateDialogOpen} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelCreateButtonRef}
                    open={isCreateDialogOpen}
                    onClose={setIsCreateDialogOpen}
                >
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <form onSubmit={onCreateSubmit}
                                  className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">


                                        <div className="sm:col-span-6">
                                            <label htmlFor="account_holder"
                                                   className="block text-sm font-medium text-gray-700">
                                                Account holder
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    disabled={isSubmitting}
                                                    onChange={onChange(setAccountHolder)}
                                                    value={accountHolder}
                                                    type="text"
                                                    name="account_holder"
                                                    id="account_holder"
                                                    placeholder="Full name"
                                                    autoComplete="name"
                                                    className="shadow-sm focus:ring-gray-900 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <label htmlFor="country"
                                                   className="block text-sm font-medium text-gray-700">
                                                Country
                                            </label>
                                            <select
                                                disabled={isSubmitting}
                                                onChange={onChange(setCountryIndex)}
                                                value={countryIndex}
                                                required
                                                id="country"
                                                name="country"
                                                autoComplete="country"
                                                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                            >
                                                <option aria-readonly disabled value="">-- Select --</option>
                                                {bankAccountCountries.map(([code, country], index) => (
                                                    <option key={code} value={index}>{country}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="sm:col-span-4">
                                            <label htmlFor="account_number"
                                                   className="block text-sm font-medium text-gray-700">
                                                Account number
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    disabled={isSubmitting}
                                                    onChange={onChange(setAccountNumber)}
                                                    value={accountNumber}
                                                    type="text"
                                                    name="account_number"
                                                    id="account_number"
                                                    className="shadow-sm focus:ring-gray-900 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={classNames(
                                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                            isSubmitting ? "bg-gray-300" : "bg-indigo-800 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        )}
                                    >

                                        {isSubmitting && <Spinner/>}
                                        {isSubmitting ? "Adding..." : "Add"}
                                    </button>
                                    <button
                                        onClick={() => setIsCreateDialogOpen(false)}
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        ref={cancelCreateButtonRef}>
                                        Dismiss
                                    </button>
                                </div>
                            </form>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )

}

export default Payouts
