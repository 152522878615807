import {createSlice} from "@reduxjs/toolkit";

import {getAuth} from 'firebase/auth';
import {collection, getFirestore, onSnapshot, orderBy, query, where} from 'firebase/firestore';

const auth = getAuth()
const firestore = getFirestore()

const initialState = {
    ordered: [],
    listener: null,
};

const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setOrdered: (state, action) => {
            state.ordered = action.payload.ordered
            state.listener = action.payload.listener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setOrdered} = slice.actions

export const startTransactionsListener = () => async (dispatch, getState) => {
    const uid = auth.currentUser.uid,
        state = getState(),
        _query = query(collection(firestore, "transactions"), where("uid", "==", uid), orderBy("createdAt", "desc"))

    if (state.transactions.listener) state.transactions.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        dispatch(setOrdered({listener, ordered: snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))}))
    })
}

export const removeTransactionsListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.transactions.listener) state.transactions.listener() // unsubscribe
}

// SELECTORS

export const transactionsSelector = state => state.transactions.ordered
