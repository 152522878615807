/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {fullScreenOpen, fullScreenStack, setFullScreenVisible} from "../store/fullScreen";
import {onErrorImageFallback} from "../components/utils";
import {DocumentTextIcon, XIcon} from "@heroicons/react/solid";

export default function FullScreen() {
    const dispatch = useDispatch()
    const galleryStack = useSelector(fullScreenStack)
    const open = useSelector(fullScreenOpen)

    function setOpen(open) {
        dispatch(setFullScreenVisible(open))
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-transparent rounded-full text-white hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true"/>
                                </button>
                            </div>
                        </Dialog.Overlay>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden inline-block align-middle h-screen"
                          aria-hidden="true">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="relative inline-block overflow-hidden transform transition-all align-middle sm:max-w-4xl sm:w-full">
                            {galleryStack?.length > 0 && (
                                <div className="relative group">
                                    <div
                                        className="flex justify-center w-full bg-transparent focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                                        {galleryStack[0] ? (
                                            <img src={galleryStack[0]?.downloadUrl || galleryStack[0] || ""} alt=""
                                                 onError={onErrorImageFallback}
                                                 className="object-contain pointer-events-none"/>
                                        ) : (
                                            <div className="flex justify-center items-center">
                                                <DocumentTextIcon className="text-white h-12 w-12 opacity-75"/>
                                            </div>
                                        )}
                                    </div>

                                    <p className="block p-2 text-sm font-medium text-white pointer-events-none">{galleryStack[0]?.fileName || galleryStack[0] || ""}</p>
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
