import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from 'firebase/auth';
import {collection, getDoc, getFirestore, limit, onSnapshot, orderBy, query, where} from 'firebase/firestore';

import {getFunctions, httpsCallable} from "firebase/functions";
import {region} from "../config";

const auth = getAuth(),
    firestore = getFirestore(),
    functions = getFunctions()

functions.region = region

const initialState = {
    listener: null,
    ordered: [],
    map: {},
    '': null
};

const slice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        setOffer: (state, action) => {
            state.map[action.payload.conversationId] = action.payload.map
            state.listener = action.payload.listener
        },
        setUnconnectedOffer: (state, action) => {
            state[action.payload.id] = action.payload
        },
        setOrdered: (state, action) => {
            state.ordered = action.payload.ordered
            state.listener = action.payload.listener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setOffer, setUnconnectedOffer, setOrdered} = slice.actions


export const getOffer = offerRef => async (dispatch) => {
    await getDoc(offerRef).then(doc => {
        if (doc.exists) {
            dispatch(setUnconnectedOffer({...doc.data(), id: offerRef.id}))
        }
    })
}

export const createOffer = (conversationId, {
    title,
    description,
    dueDate,
    price,
    creatorRole
}, cb) => async () => {
    const data = {
        conversationId, title, description, dueDate, price, creatorRole
    }
    const createOffer = httpsCallable(functions, "createOffer")
    await createOffer(data).then(() => {
        console.log("complete")
        cb && cb()
    }).catch(err => {
        console.error("err", err)
        cb && cb(err)
    })
}

export const pendingOfferAction = (offerId, action, cb) => async (dispatch) => {
    const data = {
        offerId, action
    }

    const offerAction = httpsCallable(functions, "offerAction")
    await offerAction(data).then(() => cb && cb()).catch(err => {
        console.error(err)
    })
}


export const startConversationsOffersListener = conversationId => async (dispatch, getState) => {
    const state = getState(),
        _query = query(collection(firestore, "offers"), where("conversationId", "==", conversationId), orderBy("createdAt", "desc"), limit(5))

    if (state.offers.listener) state.offers.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        const map = snapshot.docs.sort((a, b) => a.get("status") > b.get("status")).reduce((obj, cur) => {
            const id = cur.id, data = {...cur.data(), id, ref: cur}
            return {...obj[1], [id]: data}
        }, [[], {}])
        dispatch(setOffer({
            listener,
            conversationId,
            map
        }))
    })
}


export const startOffersListener = () => async (dispatch, getState) => {
    const uid = auth.currentUser.uid,
        state = getState(),
        _query = query(collection(firestore, "offers"), where("members", "array-contains-any", [uid]), orderBy("createdAt", "desc"))

    if (state.offers.listener) state.offers.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        dispatch(setOrdered({listener, ordered: snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))}))
    })
}

export const removeOffersListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.offers.listener) state.offers.listener() // unsubscribe
}

// SELECTORS

export const offerSelector = id => state => state.offers.map[id]
export const unconnectedOfferSelector = id => state => state.offers[id]
export const orderedOffersSelector = state => state.offers.ordered
