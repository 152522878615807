import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DocumentTextIcon, DownloadIcon} from "@heroicons/react/solid";
import {downloadFile, fileSelector, getFile} from "../../store/files";
import {onErrorImageFallback} from "../utils";
import Spinner from "../spinner";
import {setGallery} from "../../store/fullScreen";

export default function MediaItem({defaultFileId, downloadable}) {
    const dispatch = useDispatch()
    const [filePreview, setFilePreview] = useState(null)
    const [isDownloading, setIsDownloading] = useState(false)

    const [fileId, setFileId] = useState(defaultFileId)
    const bucketFile = useSelector(fileSelector(fileId))

    useEffect(() => {
        if (bucketFile?.thumbnailBigUrl) {
            setFilePreview(bucketFile.thumbnailBigUrl)
        }
    }, [bucketFile])

    useEffect(() => {
        if (fileId) {
            if (!fileId.startsWith("https://")) {
                dispatch(getFile(fileId, true))
            } else {
                setFilePreview(fileId)
            }
        }
    }, [fileId])


    function onDownloadClick(e) {
        e.stopPropagation()
        if (isDownloading) return
        setIsDownloading(true)
        dispatch(downloadFile(fileId, () => {
            setIsDownloading(false)
        }))
    }

    function onFullScreenOpenClick(e) {
        console.log("fileId", fileId)
        dispatch(setGallery({items: [bucketFile || fileId], open: true}))
    }

    return (
        <div className="relative group cursor-pointer" onClick={onFullScreenOpenClick}>
            <div
                className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-900 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                {filePreview ? (
                    <img src={filePreview} alt=""
                         onError={onErrorImageFallback}
                         className="object-cover pointer-events-none"/>
                ) : (
                    <div className="flex justify-center items-center">
                        <DocumentTextIcon className="text-white h-12 w-12 opacity-75"/>
                    </div>
                )}
                {downloadable && (
                    <div
                        className="opacity-0 absolute flex justify-end items-start inset-0 focus:outline-none group-hover:opacity-100">
                        <button type="button"
                                onClick={onDownloadClick}
                                className="relative flex justify-center items-center rounded-full overflow-hidden m-1 h-8 w-8">
                            <span className="absolute inset-0 bg-black opacity-50"/>
                            <DownloadIcon className="text-white z-1 h-5 w-5"/>
                            <span className="sr-only">Download</span>
                        </button>
                    </div>
                )}
            </div>

            {bucketFile?.fileName && (
                <div
                    className="mt-2 flex items-center overflow-hidden absolute bottom-0 left-0 right-0 rounded-b-lg p-2 text-gray-200">
                    <div className="absolute inset-0 bg-black opacity-75"/>
                    <div className="z-10 flex items-center overflow-hidden">
                        <div className="inline-block mr-1">
                            {isDownloading && (
                                <Spinner tiny/>
                            )}
                        </div>
                        <p className="block font-medium truncate pointer-events-none text-xs">
                            {bucketFile.fileName}
                        </p>
                    </div>
                </div>
            )}

            {/*<p className="ml-5 block text-sm font-medium text-gray-500 pointer-events-none">{prettyFileSize(inputFile.size)}</p>*/}
        </div>
    )
}
