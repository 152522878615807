import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import {closeDispute, getProject, projectSelector} from "../store/projects";
import {showToast, TOAST_ALERT} from "../store/toast";
import Spinner from "../components/spinner";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const OpenDisputeReview = props => {
    const {projectRef, open, setOpen, user} = props;
    const cancelButtonRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (open) {
            // reset
            setIsSubmitting(false)
        }
    }, [open])

    const dispatch = useDispatch()
    const project = useSelector(projectSelector(projectRef ? projectRef.id : ''))

    useEffect(() => {
        if (projectRef) dispatch(getProject(projectRef))
    }, [projectRef])

    function submit(action) {
        setIsSubmitting(true)

        dispatch(closeDispute(projectRef.id, action, (err) => {
            if (err) {
                dispatch(showToast({
                    text: "There was an error submitting your request!",
                    type: TOAST_ALERT
                }))
            } else {
                dispatch(getProject(projectRef))
            }
            setOpen(false)
        }))
    }

    const [isNegativeAction, setIsNegativeAction] = useState(false)

    function onAccept() {
        setIsNegativeAction(false)
        setIsNegativeAction(false)
        submit("accept")
    }

    function onCancel() {
        setIsNegativeAction(true)
        submit("close")
    }

    function onReject() {
        setIsNegativeAction(true)
        submit("reject")
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div
                    className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white pt-5 pb-4 sm:p-6 sm:pb-4">
                                {project && (
                                    <div className="my-4 max-w-5xl mx-auto px-4 sm:px-6 lg:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Dispute description
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">{project.dispute ? project.dispute.text : ""}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                )}
                            </div>

                            {project ? (
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    {project.dispute && project.dispute.createdBy === user.uid ? (
                                        <button
                                            disabled={isSubmitting}
                                            type="button"
                                            className={classNames(
                                                "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                                                isSubmitting ? "bg-gray-300 cursor-default" : "bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500"
                                            )}
                                            onClick={onCancel}
                                        >
                                            {isSubmitting && <Spinner/>}
                                            {isSubmitting ? "Canceling..." : "Cancel Dispute"}
                                        </button>
                                    ) : (
                                        <>
                                            <button
                                                disabled={isSubmitting}
                                                type="button"
                                                className={classNames(
                                                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                                    isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                )}
                                                onClick={onAccept}
                                            >
                                                {isSubmitting && !isNegativeAction && <Spinner/>}
                                                {isSubmitting && !isNegativeAction ? "Accepting..." : "Accept & Process Refund"}
                                            </button>
                                            <button
                                                disabled={isSubmitting}
                                                type="button"
                                                className={classNames(
                                                    "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                                                    isSubmitting ? "bg-gray-300 cursor-default" : "bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500"
                                                )}
                                                onClick={onReject}
                                            >
                                                {isSubmitting && isNegativeAction && <Spinner/>}
                                                {(isSubmitting && isNegativeAction) ? "Rejecting..." : "Reject"}
                                            </button>
                                        </>
                                    )}
                                    <button
                                        disabled={isSubmitting}
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Close
                                    </button>
                                </div>
                            ) : (
                                <Spinner/>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default OpenDisputeReview
