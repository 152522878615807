import React, {Fragment, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import * as toast from "../store/toast";
import {TOAST_ALERT, TOAST_INFO, TOAST_WARNING} from "../store/toast";

import {Transition} from '@headlessui/react'
import {CheckCircleIcon} from '@heroicons/react/outline'
import {ExclamationCircleIcon, XIcon} from '@heroicons/react/solid'


function useInterval(callback, delay) {
    const intervalId = React.useRef(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    });
    React.useEffect(() => {
        const tick = () => savedCallback.current();
        if (typeof delay === 'number') {
            intervalId.current = window.setInterval(tick, delay);
            return () => window.clearInterval(intervalId.current);
        }
    }, [delay]);
    return intervalId.current;
}

const toastTimeoutSeconds = 5

const Toast = () => {
    const stack = useSelector(toast.toastStack)
    const dispatch = useDispatch()
    const [toastTimeout, setToastTimeout] = useState(-1)
    const [toastClass, setToastClass] = useState("transform ease-out duration-300 transition translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2")

    useEffect(() => {
        if (toastTimeout === -1 && stack.length > 0) setToastTimeout(toastTimeoutSeconds)
    }, [stack])

    useEffect(() => {
        if (toastTimeout === toastTimeoutSeconds - 1) setToastClass("transform ease-out duration-300 transition translate-y-0 opacity-100 sm:translate-x-0")// animate entering toast
        if (toastTimeout === -1) dispatch(toast.dismissToast())
    }, [toastTimeout])

    useInterval(() => {
        if (stack.length > 0 && stack[0].persist) return
        setToastTimeout(toastTimeout => toastTimeout - 1);
    }, toastTimeout > -1 ? 1000 : null);

    function dismissToast() {
        setToastTimeout(-1)
    }

    function actionClick(e) {
        if (stack.length > 0 && stack[0].actionCallback) {
            stack[0].actionCallback(e)
            dismissToast()
        }
    }

    return (
        <div aria-live="assertive"
             className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6">
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                <Transition
                    show={toastTimeout < toastTimeoutSeconds && toastTimeout > 0}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {stack.length > 0 ? (toast => (<div
                            className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {toast.type === TOAST_INFO && (
                                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true"/>
                                        )}
                                        {toast.type === TOAST_ALERT && (
                                            <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true"/>
                                        )}
                                        {toast.type === TOAST_WARNING && (
                                            <ExclamationCircleIcon className="h-6 w-6 text-orange-400" aria-hidden="true"/>
                                        )}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        {toast.title &&
                                        <p className="text-sm font-medium text-gray-900 mb-1">{toast.title}</p>}
                                        <p className="mt-0 text-sm text-gray-500">{toast.text}</p>
                                        {toast.actionText ? (
                                            <div className="mt-3 flex space-x-7">
                                                <button onClick={actionClick}
                                                        className="bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    {toast.actionText}
                                                </button>
                                                <button onClick={dismissToast}
                                                        className="bg-white rounded-md text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                    Dismiss
                                                </button>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button
                                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={dismissToast}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))(stack[0]) : <div/>}
                </Transition>
            </div>
        </div>
    )
}

export default Toast
