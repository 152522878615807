import React, {Fragment, useEffect, useState} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {onErrorProfileFallback} from "./utils";
import algoliasearch from "algoliasearch/lite";
import {SearchIcon} from "@heroicons/react/solid";
import InvitationDialog from "../dialogs/invitation";
import {usersIndex} from "../config";

const client = algoliasearch('L1NI0TL9QV', 'aac89fec392109d89b0a15e6ecebdb44');
const index = client.initIndex(usersIndex);

const SearchPopover = props => {
    const {searchQuery, setSearchQuery, user} = props

    const [open, setOpen] = useState(false)
    const [searchHits, setSearchHits] = useState([])

    function search() {
        index.search(searchQuery, {
            hitsPerPage: 5
        }).then(({hits}) => {
            setSearchHits(hits.filter(p => p.objectID !== user.uid))
        })
    }

    useEffect(() => {
        if (open) search()
    }, [open, searchQuery])

    function onSubmit(e) {
        e.preventDefault()
        search()
    }

    const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false)
    const [invitationPersonUid, setInvitationPersonUid] = useState(false)

    function message(personUid) {
        setInvitationPersonUid(personUid)
        setIsInvitationDialogOpen(true)
        setOpen(false)
    }

    function onMessage(personUid) {
        return () => {
            setSearchQuery("")
            message(personUid)
        }
    }

    return (
        <>
            <form className="w-full flex md:ml-0" onSubmit={onSubmit} action="#" method="GET">
                <label htmlFor="search_field" className="sr-only">
                    Search
                </label>
                <Menu as="div" className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div
                        className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                        aria-hidden="true">
                        <SearchIcon className="h-5 w-5" aria-hidden="true"/>
                    </div>
                    <input
                        onChange={e => {
                            setOpen(true)
                            setSearchQuery(e.target.value)
                        }}
                        value={searchQuery}
                        onFocus={() => setOpen(true)}
                        onBlur={() => setOpen(false)}
                        autoComplete="off"
                        id="search_field"
                        name="search_field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search people"
                        type="search"
                    />

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Menu.Items
                            static
                            className="absolute z-10 left-1/2 transform -translate-x-1/2 px-2 w-full sm:px-0"
                        >
                            <div
                                className="shadow-lg rounded-br-lg border-t border-transparent rounded-bl-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="bg-white">
                                    <div className="sm:gap-3">
                                        <div className="flow-root px-6 overflow-y-auto">
                                            <ul className="divide-y divide-gray-200 py-4">
                                                {searchHits.map(person => (
                                                    <li key={person.objectID} className="py-4 px-4">
                                                        <div className="flex items-center space-x-4">
                                                            <div className="flex-shrink-0">
                                                                <img className="h-8 w-8 rounded-full"
                                                                     src={person.photo}
                                                                     onError={onErrorProfileFallback} alt=""/>
                                                            </div>
                                                            <div className="flex-1 min-w-0">
                                                                <p className="text-sm font-medium text-gray-900 truncate">{person.name}</p>
                                                                <p className="text-sm text-gray-500 truncate">{'@' + person.name}</p>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    onClick={onMessage(person.objectID)}
                                                                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                                                                >
                                                                    Message
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            {searchHits.length === 0 && (
                                                <div
                                                    className="flex items-center justify-center mt-5 text-gray-500 text-sm">
                                                    <span className="text-center">No results</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </form>
            <InvitationDialog open={isInvitationDialogOpen} setOpen={setIsInvitationDialogOpen}
                              invitationUid={invitationPersonUid} uid={user ? user.uid : ""}/>
        </>
    )
}

export default SearchPopover
