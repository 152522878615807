import React, {Fragment, useEffect, useRef, useState} from 'react'

import {Menu, Transition} from '@headlessui/react'
import {ArrowNarrowLeftIcon, ChatIcon, DotsVerticalIcon, PaperClipIcon,} from '@heroicons/react/solid'
import {ReactComponent as SendIcon} from '../../components/icons/send-icon.svg'
import {useDispatch, useSelector} from "react-redux";
import {
    orderedMessagesSelector,
    removeMessagesListeners,
    sendMessage,
    singleConversationSelector,
    startMessagesListener,
    startSingleConversationListener
} from "../../store/messages";
import AuthorSnippet from "../../components/author-snippet";
import {PlusIcon} from "@heroicons/react/outline";
import {
    createOffer,
    getOffer,
    pendingOfferAction,
    startConversationsOffersListener,
    unconnectedOfferSelector
} from "../../store/offers";
import {showToast, TOAST_ALERT, TOAST_WARNING} from "../../store/toast";
import {userDetailsSelector} from "../../store/user";
import MessageOffer from "./message-offer";
import {getStorage} from 'firebase/storage';
import {linkify, projectStatusColorClasses, sanitizeMessage} from "../../utils";
import CreateOffer from "../../dialogs/create-offer";
import PreviewOffer from "../../dialogs/preview-offer";
import StartProject from "../../dialogs/start-project";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {askForProjectRevision, getProject, payoutAndCompleteProject, projectSelector} from "../../store/projects";
import MessageProject from "./message-project";
import ReviewDeliverables from "../../dialogs/review-deliverables";
import ProjectRevision from "../../dialogs/project-revision";
import {setSeen} from "../../store/conversations";
import ConversationInvite from "../../dialogs/conversation-invite";
import {Link} from "react-router-dom";
import AccountWarning from "../../dialogs/account-warning";
import DeliverProject from "../../dialogs/deliver-project";
import PaymentComplete from "../../dialogs/payment-complete";
import {stripePublishableKey, supportUid} from "../../config";
import {
    removeTypingIndicatorListener,
    startTypingIndicatorListener,
    typingIndicatorSelector,
    updateTypingIndicator
} from "../../store/typingIndicator";
import TypingIndicator from "./typing-indicator";
import OpenDispute from "../../dialogs/open-dispute";
import MessageHeader from "./message-header";
import QuotedMessage from "./quoted-message";
import AuthorText from "../../components/author-text";
import OpenDisputeReview from "../../dialogs/open-dispute-review";
import MessageDispute from "./message-dispute";
import MediaUpload, {useMediaUpload} from "../../components/files/media-upload";
import MediaItem from "../../components/files/media-item";

const storage = getStorage()

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const stripePromise = loadStripe(stripePublishableKey);

const Conversation = props => {
    const {id} = props
    const dispatch = useDispatch()
    const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false)
    const [isOfferPreviewDialogOpen, setIsOfferPreviewDialogOpen] = useState(false)
    const [isDisputeDialogOpen, setIsDisputeDialogOpen] = useState(false)
    const [membersOtherThanMe, setMembersOtherThanMe] = useState([])
    const [isSupport, setIsSupport] = useState(false)
    const mediaUpload = useMediaUpload()
    useEffect(() => {
        return () => {
            dispatch(removeMessagesListeners())
            dispatch(removeTypingIndicatorListener())
        }
    }, [])
    useEffect(() => {
        setReplyTo(null)
        mediaUpload.reset();
        if (id) {
            dispatch(startSingleConversationListener(id))
            dispatch(startMessagesListener(id))
            dispatch(startConversationsOffersListener(id))
            dispatch(setSeen(id))
        } else {
            dispatch(removeMessagesListeners())
            dispatch(removeTypingIndicatorListener())
        }
    }, [id])
    const user = useSelector(userDetailsSelector)
    const orderedMessages = useSelector(orderedMessagesSelector)
    const conversation = useSelector(singleConversationSelector)
    const offer = useSelector(unconnectedOfferSelector(conversation && conversation.offerRef ? conversation.offerRef.id : ''))
    const project = useSelector(projectSelector(conversation && conversation.projectRef ? conversation.projectRef.id : ''))
    const [autoScroll, setAutoScroll] = useState(true)
    const scrollMessagesContent = useRef(null)

    function onScroll(e) {
        setAutoScroll(e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight)
    }

    useEffect(() => {
        if (scrollMessagesContent && scrollMessagesContent.current && autoScroll) scrollMessagesContent.current.scrollTop = scrollMessagesContent.current.scrollHeight
    }, [orderedMessages, autoScroll])
    const typingIndicator = useSelector(typingIndicatorSelector(id))
    useEffect(() => {
        if (conversation) {
            setMembersOtherThanMe(conversation.membersOtherThanMe)
            if (conversation.offerRef) dispatch(getOffer(conversation.offerRef))
            if (conversation.projectRef) dispatch(getProject(conversation.projectRef))
            if (conversation.membersOtherThanMe) dispatch(startTypingIndicatorListener(conversation.id, [...conversation.membersOtherThanMe]))
        }
    }, [conversation])
    useEffect(() => {
        setIsSupport(membersOtherThanMe && membersOtherThanMe.indexOf(supportUid) !== -1)
    }, [membersOtherThanMe])
    const [text, setText] = useState('')
    const [uploadingAttachment, setUploadingAttachment] = useState(false)
    const [replyTo, setReplyTo] = useState(null) // save message object (not only ID, but the whole message)
    function handleKeyDown(e) {
        e.target.style.height = 'inherit';
        //e.target.style.height = `${e.target.scrollHeight}px`;
        // In case you have a limitation
        const height = Math.min(e.target.scrollHeight, 400);
        e.target.style.height = `${height}px`;
        if (height === 400) e.target.style.overflow = `auto`;
        else e.target.style.overflow = `hidden`;
    }

    const [indicatorUpdate, setIndicatorUpdate] = useState(Date.now())

    function onMessageChange(e) {
        const now = Date.now(),
            dif = now - indicatorUpdate

        setText(e.target.value)
        handleKeyDown(e)

        if (dif > 1000) {
            console.log("Ping typing indicator")
            setIndicatorUpdate(now)
            id && dispatch(updateTypingIndicator(id))
        }
    }

    function send(e) {
        e.preventDefault()
        if (mediaUpload.isUploading) return
        const fileIds = mediaUpload.getFileIdList()
        if (fileIds.length !== mediaUpload.files.length) {
            return dispatch(showToast({
                text: "Please wait so that all files are finished uploading first!",
                type: TOAST_ALERT
            }))
        }
        dispatch(sendMessage(conversation.id, linkify(text), fileIds, replyTo))
        setText("")
        setReplyTo("")
        mediaUpload.reset();
        e.target.style.height = 'inherit';
    }

    const [isWarnDialogOpen, setIsWarnDialogOpen] = useState(false) // account incomplete warning
    function sendOffer(data, cb) {
        dispatch(createOffer(id, data, err => {
            if (err) dispatch(showToast({
                text: "There was a problem submitting your request!",
                type: TOAST_ALERT
            }))
            cb && cb()
        }))
    }

    const [previewOfferRef, setPreviewOfferRef] = useState('')

    function previewOffer(offerRef) {
        setPreviewOfferRef(offerRef)
        setIsOfferPreviewDialogOpen(true)
    }

    function acceptOffer(cb) {
        dispatch(pendingOfferAction(previewOfferRef.id, "accept", cb))
    }

    function rejectOffer(cb) {
        dispatch(pendingOfferAction(previewOfferRef.id, "reject", cb))
    }

    function cancelOffer(cb) {
        dispatch(pendingOfferAction(previewOfferRef.id, "cancel", cb))
    }

    const [isStartProjectDialogOpen, setIsStartProjectDialogOpen] = useState(false)

    function onStartProject(cb) {
        const offerRef = previewOfferRef || offer

        // todo: check if user has customerId or connectedAccount?
        if (!user.customerId) {
            setIsWarnDialogOpen(true)
            cb && cb()
            return
        }

        if (conversation.offerRef && conversation.offerRef.id === offerRef.id) {
            if (!conversation.projectRef) {

            } else {
                // todo: error
            }

            setIsStartProjectDialogOpen(true)
        } else {
            // todo: error
        }

        cb && cb()
    }

    const [deliverProjectId, setDeliverProjectId] = useState('')
    const [isDeliverDialogOpen, setIsDeliverDialogOpen] = useState(false)

    function deliverProject() {
        setDeliverProjectId(conversation.projectRef.id)
        setIsDeliverDialogOpen(true)
    }

    const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false)

    function reviewTheWork() {
        setIsReviewDialogOpen(true)
    }

    const [isReviewDisputeDialogOpen, setIsReviewDisputeDialogOpen] = useState(false)

    function reviewTheDispute() {
        setIsReviewDisputeDialogOpen(true)
    }

    function payProject(cb) {
        if (!user.customerId) {
            setIsWarnDialogOpen(true)
            return
        }
        dispatch(payoutAndCompleteProject(conversation.projectRef ? conversation.projectRef.id : "", cb))
    }

    const [isRevisionDialogOpen, setIsRevisionDialogOpen] = useState(false)

    function askForRevision() {
        setIsRevisionDialogOpen(true)
    }

    function onCloseRevisionDialog() {
        setIsRevisionDialogOpen(false);
        reviewTheWork()
    }

    function sendForRevision({message}, cb) {
        dispatch(askForProjectRevision(conversation.projectRef ? conversation.projectRef.id : "", message, cb))
    }

    const [inviteOpen, setInviteOpen] = useState(false)
    const [isPaymentCompleteDialogOpen, setIsPaymentCompleteDialogOpen] = useState(false)

    function message(item, isReply) {
        if (!item) return <></>
        switch (item.type) {
            case "MESSAGE":
                return (
                    <div
                        className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
                        <MessageHeader item={item} setReplyTo={isReply ? null : setReplyTo}/>
                        <div
                            className="mt-4 space-y-6 text-sm text-gray-800"
                        >
                            <p className="whitespace-pre-line break-word"
                               dangerouslySetInnerHTML={{__html: sanitizeMessage(item.text)}}/>
                            {item.attachments && (
                                <div
                                    className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                    <ul className="divide-y divide-gray-300">
                                        {item.attachments.length > 0 && (
                                            <li className="pl-3 pr-4 py-3 flex flex-col">

                                                <div className="flex items-center justify-between text-sm">
                                                    <div className="w-0 flex-1 flex items-center">
                                                        <PaperClipIcon
                                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                            aria-hidden="true"/>
                                                        <span
                                                            className="ml-2 flex-1 w-0 truncate">{item.attachments.length} {item.attachments.length === 1 ? "attachment" : "attachments"}</span>
                                                    </div>
                                                    {/*<div className="ml-4 flex-shrink-0">
                                                        {isDownloading ? (
                                                            <Spinner/>
                                                        ) : (
                                                            <span
                                                                onClick={downloadDeliveredFiles}
                                                                className="cursor-pointer font-medium text-gray-600 hover:text-blue-500">Download all</span>
                                                        )}
                                                    </div>*/}
                                                </div>
                                                <div
                                                    className="mt-4 grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-4">
                                                    {item.attachments.map((file, i) => (
                                                        <div key={i}>
                                                            <MediaItem defaultFileId={file} downloadable/>
                                                        </div>
                                                    ))}
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                )
            case "NEW_OFFER":
            case "OFFER_ACCEPTED":
            case "OFFER_REJECTED":
            case "OFFER_CANCELED":
                return <MessageOffer item={item} user={user} setReplyTo={isReply ? null : setReplyTo}
                                     onActionClick={previewOffer}/>
            case "PROJECT_STARTED":
            case "PROJECT_DELIVERED":
            case "PROJECT_IN_REVISION":
            case "PROJECT_COMPLETED":
                return <MessageProject item={item} user={user} setReplyTo={isReply ? null : setReplyTo}
                                       onActionClick={previewOffer}/>
            case "DISPUTE_OPENED":
            case "DISPUTE_ACCEPTED":
            case "DISPUTE_REJECTED":
                return <MessageDispute item={item} setReplyTo={isReply ? null : setReplyTo}
                                       onActionClick={previewOffer}/>
        }
    }

    function featureNotAvailableToast() {
        dispatch(showToast({
            text: "This feature is not yet available in this version",
            type: TOAST_WARNING
        }))
    }

    return (
        <div className="h-full xl:order-last flex-1">
            {id ? (
                <section
                    aria-labelledby="message-heading"
                    className="min-w-0 flex-1 h-full flex flex-col overflow-hidden xl:order-last">
                    {/* Header conversation section */}
                    <div className="flex-shrink-0 bg-white border-b border-gray-200 z-6">
                        {/* Toolbar*/}
                        <div className="h-16 flex flex-col justify-center">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div className="py-3 flex justify-between">
                                    {/* Left buttons */}
                                    <div className="flex">
                                        <Link
                                            to="/chat"
                                            className="xl:hidden mr-3 -ml-3 inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                        >
                                            <ArrowNarrowLeftIcon className="h-5 w-5" aria-hidden="true"/>
                                        </Link>
                                        <AuthorSnippet id={membersOtherThanMe ? membersOtherThanMe[0] : ''} showLastSeen={true}/>
                                    </div>

                                    {/* Right buttons */}
                                    {!isSupport && (
                                        <div
                                            className="relative z-0 inline-flex sm:space-x-3">
                                            <div className="flex space-x-3 items-center">
                                                {project ? (
                                                    <div className="flex items-center">
                                                    <span
                                                        className={classNames(
                                                            "inline-flex items-center rounded-full bg-white shadow-none sm:shadow-sm",
                                                            "sm:" + projectStatusColorClasses(project.status)
                                                        )}>
                                                        <span className={classNames(
                                                            "block sm:hidden w-4 h-4 rounded-full shadow-sm -mr-1 ml-2",
                                                            projectStatusColorClasses(project.status)
                                                        )}/>
                                                        <span
                                                            className={classNames(
                                                                "hidden sm:inline capitalize text-sm font-medium px-3 py-0.5",
                                                                projectStatusColorClasses(project.status)
                                                            )}>Project {(project.status || "").replace("-", " ")}</span>
                                                    </span>

                                                        <div className="hidden md:block ml-4">
                                                            <Menu as="div"
                                                                  className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button
                                                                        className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-md border border-gray-100 bg-white text-sm font-medium text-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400">
                                                                        <PlusIcon
                                                                            className="mr-2.5 h-5 w-5 text-gray-200"
                                                                            aria-hidden="true"/>
                                                                        <span>Invite a Paynco mediator</span>
                                                                    </Menu.Button>
                                                                </div>

                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items
                                                                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                                                        <div className="px-4 py-3">
                                                                            {(project.status === "active" || project.status === "in-revision") ? (
                                                                                <p className="text-sm text-gray-700">This
                                                                                    feature is <b>not yet</b> available
                                                                                    in
                                                                                    this version</p>
                                                                            ) : (
                                                                                <p className="text-sm text-gray-700">Available
                                                                                    on active projects only</p>
                                                                            )}
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                        {project.seller === user.uid && (project.status === "active" || project.status === "in-revision") ? (
                                                            <button
                                                                onClick={deliverProject}
                                                                type="button"
                                                                className="ml-4 sm:inline-flex relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:border-indigo-600"
                                                            >
                                                                <span>Deliver</span>
                                                            </button>
                                                        ) : project.buyer === user.uid && project.status === "delivered" ? (
                                                            <button
                                                                onClick={reviewTheWork}
                                                                type="button"
                                                                className="ml-4 sm:inline-flex relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:border-indigo-600"
                                                            >
                                                                <span>Approve the delivery</span>
                                                            </button>
                                                        ) : project.status === "paused" && project.dispute && project.dispute.status === "pending" ? (
                                                            <button
                                                                onClick={reviewTheDispute}
                                                                type="button"
                                                                className="ml-4 sm:inline-flex relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:border-indigo-600"
                                                            >
                                                                <span>Review the dispute</span>
                                                            </button>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                ) : offer ? (
                                                    <div className="flex items-center">
                                                    <span
                                                        className={classNames(
                                                            "capitalize inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium",
                                                            offer.status === "pending" ? "bg-yellow-100 text-yellow-800" : "",
                                                            offer.status === "rejected" || offer.status === "canceled" ? "bg-red-100 text-red-800" : "",
                                                            offer.status === "accepted" ? "bg-indigo-100 text-indigo-800" : "",
                                                        )}>
                                                        Offer {offer.status}
                                                    </span>
                                                        {offer.buyer === user.uid && offer.status === "accepted" && (
                                                            <button
                                                                onClick={() => onStartProject()}
                                                                type="button"
                                                                className="ml-4 sm:inline-flex relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:border-indigo-600"
                                                            >
                                                                <span>Pay to Start</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                ) : ""}

                                                {((!offer && !project) || (offer && (offer.status === "rejected" || offer.status === "canceled"))) && (
                                                    <button
                                                        onClick={() => setIsOfferDialogOpen(true)}
                                                        type="button"
                                                        className="ml-4 inline-flex relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-600 focus:border-indigo-600 flex-nowrap"
                                                    >
                                                        <PlusIcon className="mr-2.5 h-5 w-5 text-gray-400"
                                                                  aria-hidden="true"/>
                                                        <span>Create offer</span>
                                                    </button>
                                                )}
                                            </div>

                                            {project && (
                                                <div
                                                    className="z-20 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
                                                    <Menu as="div" className="ml-0 relative inline-block text-left">
                                                        {({open}) => (
                                                            <>
                                                                <div>
                                                                    <Menu.Button
                                                                        className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600">
                                                                        <span className="sr-only">Open options</span>
                                                                        <DotsVerticalIcon className="h-5 w-5"
                                                                                          aria-hidden="true"/>
                                                                    </Menu.Button>
                                                                </div>

                                                                <Transition
                                                                    show={open}
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items
                                                                        static
                                                                        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                                    >
                                                                        <div className="py-1">
                                                                            <Menu.Item>
                                                                                {({active}) => (
                                                                                    <button
                                                                                        onClick={() => previewOffer(conversation.projectRef)}
                                                                                        type="button"
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'w-full flex justify-between px-4 py-2 text-sm'
                                                                                        )}
                                                                                    >
                                                                                        <span>View project details</span>
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({active}) => (
                                                                                    <button
                                                                                        onClick={featureNotAvailableToast}
                                                                                        type="button"
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'w-full flex md:hidden justify-between px-4 py-2 text-sm',
                                                                                        )}
                                                                                    >
                                                                                        <span>Invite a Paynco mediator</span>
                                                                                    </button>
                                                                                )}
                                                                            </Menu.Item>
                                                                            {project.status !== 'paused' && (
                                                                                <Menu.Item>
                                                                                    {({active}) => (
                                                                                        <button
                                                                                            onClick={() => setIsDisputeDialogOpen(true)}
                                                                                            type="button"
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'w-full flex justify-between px-4 py-2 text-sm'
                                                                                            )}
                                                                                        >
                                                                                            <span>Open dispute</span>
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            )}
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Menu>
                                                </div>

                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Message header */}
                    </div>
                    {/* Messages */}
                    <div onScroll={onScroll} ref={scrollMessagesContent} className="min-h-0 flex-1 overflow-y-auto">
                        {/* Thread section*/}
                        <ul className="py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8">
                            {orderedMessages.map((item) => (
                                <li className={classNames(
                                    item.replyTo ? 'flex flex-col' : ''
                                )} key={item.id}>
                                    <div className="z-5 order-1">
                                        {message(item)}
                                    </div>
                                    {item.replyTo && (
                                        <div className="scale-95 transform">
                                            <span className="text-xs mx-6 text-gray-400 mb-1">Replied to <b
                                                className="font-medium"><AuthorText id={item.replyTo.uid}/></b></span>
                                            <div
                                                className="text-gray-600 text-xs -mb-4 relative overflow-hidden rounded-lg opacity-75">
                                                {message(item.replyTo, true)}
                                                <div className="inset-0 absolute bg-white opacity-20"/>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>

                        <TypingIndicator data={typingIndicator}/>
                    </div>

                    {/* Input section */}
                    <div className="flex-shrink-0 space-y-2 sm:space-y-4">
                        <form className="bg-white px-8 shadow flex flex-col" onSubmit={send}>
                            <QuotedMessage data={replyTo} setReplyTo={setReplyTo}/>
                            <div className="flex flex-row flex-nowrap items-end py-2">
                                <button
                                    type="button"
                                    onClick={e => e.currentTarget.children[0].click()}
                                    className="-mx-2 w-10 h-10 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-500"
                                >
                                    <input className="hidden"
                                           onChange={mediaUpload.onFiles}
                                           type="file" multiple/>
                                    <PaperClipIcon className="h-6 w-6" aria-hidden="true"/>
                                    <span className="sr-only">Attach a file</span>
                                </button>

                                <div className="bg-gray-100 rounded-input flex-grow flex flex-col mx-4 relative">
                                    {mediaUpload.files.length > 0 && (
                                        <div className="px-4 py-4">
                                            <MediaUpload
                                                dense={true}
                                                mediaUpload={mediaUpload}
                                            />
                                        </div>
                                    )}

                                    <textarea
                                        value={text}
                                        onKeyPress={e => e.key === "Enter" && e.shiftKey === false ? send(e) : null}
                                        onChange={onMessageChange}
                                        rows={1}
                                        placeholder="Your message here ..."
                                        required
                                        className="bg-transparent overflow-hidden px-4 pr-16 border-transparent block w-full resize-none sm:text-sm outline-none focus:outline-none focus:ring-0 focus:border-transparent --focus:ring-indigo-400 --focus:border-indigo-400"
                                    />

                                    <button type="submit"
                                            disabled={!text || mediaUpload.isUploading}
                                            className={classNames(
                                                "absolute right-0 -m-0.5 bottom-0 w-10 h-10 rounded-full inline-flex items-center justify-center scale-100 hover:scale-125 transform-gpu ease-in-out duration-100",
                                                !text ? "hidden" : mediaUpload.isUploading ? "text-gray-300" : "text-indigo-600"
                                            )}>
                                        <SendIcon className="h-5 w-5"/>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            ) : (
                <section
                    aria-labelledby="message-heading"
                    className="min-w-0 flex-1 h-full flex flex-col overflow-hidden xl:order-last">
                    {/* Header conversation section */}
                    <div className="flex-shrink-0 bg-white border-b border-gray-200">
                        {/* Toolbar*/}
                        <div className="h-16 flex flex-col justify-center">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div className="py-3 flex justify-between">
                                    {/* Left buttons */}
                                    <div className="text-gray-600">
                                        No conversation selected
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Message header */}
                    </div>

                    <div className="text-center flex-grow flex flex-col items-center justify-center">
                        <ChatIcon className="mx-auto h-12 w-12 text-gray-400"/>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">No conversation selected</h3>
                        <p className="mt-1 text-sm text-gray-500">Get started by inviting someone.</p>
                        <div className="mt-6">
                            <button
                                type="button"
                                onClick={() => setInviteOpen(true)}
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
                                Invite
                            </button>
                        </div>
                    </div>
                </section>
            )}

            <ConversationInvite
                user={user}
                open={inviteOpen}
                onClose={setInviteOpen}
            />

            <CreateOffer
                open={isOfferDialogOpen}
                onClose={setIsOfferDialogOpen}
                onAction={sendOffer}
            />

            <PreviewOffer
                open={isOfferPreviewDialogOpen}
                setOpen={setIsOfferPreviewDialogOpen}
                onCancel={cancelOffer}
                onAccept={acceptOffer}
                onReject={rejectOffer}
                onStart={onStartProject}
                user={user}
                offerRef={previewOfferRef}
            />

            <OpenDispute
                open={isDisputeDialogOpen}
                setOpen={setIsDisputeDialogOpen}
                user={user}
                projectRef={conversation.projectRef}
            />

            <OpenDisputeReview
                open={isReviewDisputeDialogOpen}
                setOpen={setIsReviewDisputeDialogOpen}
                user={user}
                projectRef={conversation.projectRef}
            />

            <Elements stripe={stripePromise}>
                <StartProject
                    open={isStartProjectDialogOpen}
                    setOpen={setIsStartProjectDialogOpen}
                    setSuccessful={setIsPaymentCompleteDialogOpen}
                    user={user}
                    offer={offer}
                />
            </Elements>

            <PaymentComplete
                open={isPaymentCompleteDialogOpen}
                setOpen={setIsPaymentCompleteDialogOpen}
            />

            <AccountWarning
                open={isWarnDialogOpen}
                setOpen={setIsWarnDialogOpen}
            />

            <ReviewDeliverables
                open={isReviewDialogOpen}
                setOpen={setIsReviewDialogOpen}
                onAccept={payProject}
                onReject={askForRevision}
                user={user}
                projectRef={conversation.projectRef}
            />
            <ProjectRevision
                open={isRevisionDialogOpen}
                setOpen={onCloseRevisionDialog}
                onAction={sendForRevision}
                user={user}
                projectRef={conversation.projectRef}
            />

            <DeliverProject
                open={isDeliverDialogOpen}
                setOpen={setIsDeliverDialogOpen}
                deliverProjectId={deliverProjectId}
                projectRef={conversation.projectRef}
            />
        </div>
    )

}

export default Conversation
