import * as sanitizeHtml from 'sanitize-html';

/*DOMPurify.addHook('uponSanitizeElement', function (node, data) {
    if (data.tagName === 'a') {
        node.setAttribute("target", "_blank")
        node.setAttribute("referrerpolicy", "no-referrer")
        node.className = "text-indigo-500"
    }
});*/

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const pad = (num, size) => {
    let s = num + ""
    while (s.length < size) s = "0" + s
    return s
}

const s = {
    "paused": "bg-yellow-100 text-yellow-800",
    "in-revision": "bg-orange-100 text-orange-800",
    "canceled": "bg-gray-100 text-gray-800",
    "active": "bg-green-100 text-green-800",
    "delivered": "bg-green-100 text-green-800",
    "completed": "bg-gray-100 text-gray-800",
    "done": "bg-gray-100 text-gray-800",
}
export const projectStatusColorClasses = (status, textOnly = false) => textOnly ? ((s[status] || "bg-gray-100 text-gray-800").split(" ")[1]) : (s[status] || "bg-gray-100 text-gray-800")

export const hasAllRequiredUserPaymentDetails = user => !(!user.customerId || !user.connectedAccountId || !user.address || !user.address.line1 || !user.address.city || !user.address.postalCode || !user.address.country || !user.dob)

const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
export const linkify = text => text.replace(urlRegex, url => '<a referrerpolicy="no-referrer" target="_blank" href="' + url + '">' + url + '</a>');

export const sanitizeMessage = text => sanitizeHtml(text, {
    allowedTags: ['a'],
    allowedAttributes: {
        'a': ['target', 'referrerpolicy', 'href', 'class']
    },
    allowedClasses: {
        'a': ['text-indigo-500']
    },
    transformTags: {
        'a': function (tagName, attribs) {
            // My own custom magic goes here
            return {
                tagName,
                attribs: {
                    href: attribs.href,
                    referrerpolicy: 'no-referrer',
                    target: '_blank',
                    class: 'text-indigo-500'
                }
            };
        }
    },
    disallowedTagsMode: 'recursiveEscape',
    nestingLimit: 2,
    allowedSchemes: ['http', 'https', 'ftp', 'file'],
    allowProtocolRelative: true,

})

export const removeHTML = text => sanitizeHtml(text, {
    allowedTags: [],
    disallowedTagsMode: 'discard',
    nestingLimit: 0,
})

// returns "Attachment a" or "filename.ext"
export const beautifulStorageURL = (a, i) => {
    let filename = "Attachment " + (i + 1);
    try {
        let s = decodeURIComponent(a).split("/"),
            s2 = s[s.length - 1].split("?alt=media&token=")
        filename = s2[0] || filename;
    } catch (e) {
    }
    return filename
}

const fSExt = ['Bytes', 'KB', 'MB', 'GB', 'TB']
export const prettyFileSize = _size => {
    let i = 0;
    while (_size > 900) {
        _size /= 1024;
        i++;
    }
    return Math.ceil(_size) + ' ' + fSExt[i];
}

const dates = {
    convert: function (d) {
        // Converts the date in d to a date-object. The input can be:
        //   a date object: returned without modification
        //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
        //   a number     : Interpreted as number of milliseconds
        //                  since 1 Jan 1970 (a timestamp)
        //   a string     : Any format supported by the javascript engine, like
        //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
        //  an object     : Interpreted as an object with year, month and date
        //                  attributes.  **NOTE** month is 0-11.
        return (
            d.constructor === Date ? d :
                d.constructor === Array ? new Date(d[0], d[1], d[2]) :
                    d.constructor === Number ? new Date(d) :
                        d.constructor === String ? new Date(d) :
                            typeof d === "object" ? new Date(d.year, d.month, d.date) :
                                NaN
        );
    },
    compare: function (a, b) {
        // Compare two dates (could be of any type supported by the convert
        // function above) and returns:
        //  -1 : if a < b
        //   0 : if a = b
        //   1 : if a > b
        // NaN : if a or b is an illegal date
        // NOTE: The code inside isFinite does an assignment (=).
        return (
            isFinite(a = this.convert(a).valueOf()) &&
            isFinite(b = this.convert(b).valueOf()) ?
                (a > b) - (a < b) :
                NaN
        );
    },
    inRange: function (d, start, end) {
        // Checks if date in d is between dates in start and end.
        // Returns a boolean or NaN:
        //    true  : if d is between start and end (inclusive)
        //    false : if d is before start or after end
        //    NaN   : if one or more of the dates is illegal.
        // NOTE: The code inside isFinite does an assignment (=).
        return (
            isFinite(d = this.convert(d).valueOf()) &&
            isFinite(start = this.convert(start).valueOf()) &&
            isFinite(end = this.convert(end).valueOf()) ?
                start <= d && d <= end :
                NaN
        );
    }
};

Date.prototype.getMonthName = function (lang) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names[this.getMonth()];
};

Date.prototype.getMonthNameShort = function (lang) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names_short[this.getMonth()];
};

Date.locale = {
    en: {
        month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
};

export const beautifulDateTime = (timestamp = {seconds: 0}, dateOnly = false) => {
    try {
        const d = new Date(timestamp.seconds * 1000);
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        if (dates.inRange(d, today, tomorrow)) {
            if (dateOnly) return "Today"
            const h = d.getHours();
            const m = d.getMinutes();
            return (h < 10 ? '0' + h : h) + ":" + (m < 10 ? '0' + m : m)
        } else if (d.getFullYear() === today.getFullYear()) {
            // only date
            return (d.getDate()) + ". " + d.getMonthNameShort().toLowerCase()
        } else {
            // date with year
            return (d.getDate()) + ". " + d.getMonthNameShort().toLowerCase() + " " + d.getFullYear()
        }
    } catch (e) {
        console.error(e)
    }
};

export const Constants = {
    //URL_FILENAME:  new RegExp('(?<=\/)[^/?#]+(?=[^/]*$)')
}

const multiWord = (num, single, multi) => num === 1 ? [num, single].join(" ") : [num, multi].join(" ")

// Examples: "12 seconds ago", "2 days ago", "3 weeks ago"
const halfHourS = 1800;
const oneAndHalfHourS = 5400;
const halfDayS = 43200;
const oneAndHalfDayS = 129600;
const sixDaysS = 518400;
const eightDaysS = 691200;
const threeWeeksS = 1814400;
const fiveWeeksS = 3024000;
const yearS = 31622400;
export const beautifulTimeReducer = (timestamp = {seconds: 0}) => {
    if (!timestamp || !timestamp.seconds) return ""
    const s = (Date.now() / 1000) - timestamp.seconds;
    if (s < 30) return "a few seconds"
    if (s < 120) return "a minute"
    if (s < halfHourS) return multiWord(Math.floor(s / 60), "minute", "minutes")
    if (s < oneAndHalfHourS) return "an hour"
    if (s < halfDayS) return multiWord(Math.floor(s / 60 / 60), "hour", "hours")
    if (s < oneAndHalfDayS) return "a day"
    if (s < sixDaysS) return multiWord(Math.floor(s / 60 / 60 / 24), "day", "days")
    if (s < eightDaysS) return "a week"
    if (s < threeWeeksS) return multiWord(Math.floor(s / 60 / 60 / 24 / 7), "week", "weeks")
    if (s < fiveWeeksS) return "a month"
    if (s < yearS) return multiWord(Math.floor(s / 60 / 60 / 24 / 30), "month", "months")
    return "more than a year"
};

export const getConversationId = (...ids) => {
    return ids.sort().join("_")
}
