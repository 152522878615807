import React from "react";
import {beautifulDateTime} from "../../utils";
import {Link} from "react-router-dom";
import currency from "currency.js";


const OffersTab = ({results}) => {

    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
            <tr>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Timestamp
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Title
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Status
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Amount
                </th>
                {/*
                                `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                              */}
                <th
                    scope="col"
                    className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    <span className="sr-only">View receipt</span>
                </th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {results.map((item) => (
                <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.createdAt &&
                        <time
                            dateTime={item.createdAt.toDate().toISOString()}>{beautifulDateTime(item.createdAt)}</time>
                        }
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {item.title}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                        {item.status}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {currency(item.price, {symbol: "$"}).format()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link to={"/chat/" + item.conversationId}
                              className="text-orange-600 hover:text-orange-900">
                            Go to conversation
                        </Link>
                    </td>
                </tr>
            ))}
            {results.length === 0 && (
                <tr>
                    <td colSpan="5"
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                        No results
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    )

}

export default OffersTab
