import {ScaleIcon,} from '@heroicons/react/outline'
import {ArrowCircleDownIcon, CashIcon, ChevronRightIcon, InformationCircleIcon, XIcon,} from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import {allOrderedProjectsSelector, removeAllProjectsListener, startAllProjectsListener} from "../../store/admin";
import {useEffect, useState} from "react";
import {beautifulDateTime} from "../../utils";
import {Link} from "react-router-dom";
import currency from "currency.js";

const cards = [
    {name: 'Collected fees', href: '#', icon: ArrowCircleDownIcon, amount: '0,00 $', css: 'text-green-400'},
    {name: 'Pending fees', href: '#', icon: ScaleIcon, amount: '0,00 $'},
    // More items...
]

const stats = []

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const AdminDashboard = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(startAllProjectsListener())
        return () => {
            dispatch(removeAllProjectsListener())
        }
    }, [])


    const orderedProjects = useSelector(allOrderedProjectsSelector)

    const [stats, setStats] = useState([0, 0])

    const [active, setActive] = useState([])
    const [other, setOther] = useState([])

    useEffect(() => {
        const a = [], o = []
        let collected = currency(0), pending = currency(0)
        for (let i = 0; i < orderedProjects.length; i++) {
            const p = orderedProjects[i]
            //active, delivered, in-revision, completed
            if (p.status === "completed") {
                collected = collected.add(p.pricingStrategy?.fee.value)
                o.push(p)
            } else {
                pending = pending.add(p.pricingStrategy?.fee.value)
                a.push(p)
            }
        }
        setStats([collected.format(), pending.format()])
        setActive(a)
        setOther(o)
    }, [orderedProjects])

    return (
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <div className="mt-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
                    <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">

                        {cards.map((card, index) => (
                            <div key={card.name} className="bg-white overflow-hidden shadow rounded-lg">
                                <div className="p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <card.icon className={classNames(
                                                "h-6 w-6",
                                                card.css || "text-gray-400"
                                            )} aria-hidden="true"/>
                                        </div>
                                        <div className="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt className="text-sm font-medium text-gray-500 truncate">{card.name}</dt>
                                                <dd>
                                                    <div
                                                        className="text-lg font-medium text-gray-900">{stats[index] === null ? "..." : stats[index]}</div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="max-w-6xl mx-auto flex flex-col lg:flex-row">


                    {/*<div className="flex-grow flex-1 my-6">
                        <h2 className="px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Active projects
                        </h2>

                         Activity list (smallest breakpoint only)
                        <div className="shadow sm:hidden">
                            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {active.map(item => (
                                    <li key={item.id}>
                                        <Link to={"/chat/" + item.conversationId}
                                              className="block px-4 py-4 bg-white hover:bg-gray-50">
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{item.title}</span>
                            <span>
                              <span className="text-gray-900 font-medium">{item.price}</span>{' '}
                                $
                            </span>
                            <time
                                dateTime={item.createdAt.toDate().toISOString()}>{beautifulDateTime(item.createdAt, true)}</time>
                          </span>
                        </span>
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                         Activity table (small breakpoint and up)
                        <div className="hidden sm:block">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="flex flex-col mt-2">
                                    <div
                                        className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                            <tr>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Title
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Amount
                                                </th>
                                                <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                                                    Status
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Due
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {active.map(item => (
                                                <tr key={item.id} className="bg-white">
                                                    <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div className="flex">
                                                            <Link to={"/chat/" + item.conversationId}
                                                                  className="group inline-flex space-x-2 truncate text-sm">
                                                                <CashIcon
                                                                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                                <p className="text-gray-500 truncate group-hover:text-gray-900">{item.title}</p>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span
                                                            className="text-gray-900 font-medium">{item.price} </span>
                                                        $
                                                    </td>
                                                    <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                              <span
                                  className={classNames(
                                      'bg-green-100 text-green-800',
                                      'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                  )}
                              >
                                {item.status}
                              </span>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <time
                                                            dateTime={item.dueDate.toDate().toISOString()}>{beautifulDateTime(item.dueDate, true)}</time>
                                                    </td>
                                                </tr>
                                            ))}
                                            {active.length === 0 && (
                                                <tr className="bg-white">
                                                    <td colSpan="4"
                                                        className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                                                        No results
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                        <div className="bg-gray-50 px-5 py-3">
                                            <div className="text-sm">
                                                <Link to="/projects"
                                                      className="font-medium text-indigo-700 hover:text-indigo-900">
                                                    View all
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}


                    <div className="flex-grow flex-1 my-6">
                        <h2 className="px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                            Completed projects
                        </h2>

                        {/* Activity list (smallest breakpoint only) */}
                        <div className="shadow sm:hidden">
                            <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {other.map(item => (
                                    <li key={item.id}>
                                        <Link to={"/chat/" + item.conversationId}
                                              className="block px-4 py-4 bg-white hover:bg-gray-50">
                      <span className="flex items-center space-x-4">
                        <span className="flex-1 flex space-x-2 truncate">
                          <CashIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                          <span className="flex flex-col text-gray-500 text-sm truncate">
                            <span className="truncate">{item.title}</span>
                            <span>
                              <span className="text-gray-900 font-medium">{item.pricingStrategy?.feePercentage}</span>{' '}
                                %
                            </span>
                            <span>{item.pricingStrategy?.fee.formatted}</span>
                          </span>
                        </span>
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Activity table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="flex flex-col mt-2">
                                    <div
                                        className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                            <tr>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Title
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                                                    Fee %
                                                </th>
                                                <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap md:block">
                                                    Amount
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Date
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                            {other.map(item => (
                                                <tr key={item.id} className="bg-white">
                                                    <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                        <div className="flex">
                                                            <Link to={"/chat/" + item.conversationId}
                                                                  className="group inline-flex space-x-2 truncate text-sm">
                                                                <CashIcon
                                                                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                                <p className="text-gray-500 truncate group-hover:text-gray-900">{item.title}</p>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span
                                                            className="text-gray-900 font-medium">{item.pricingStrategy?.feePercentage} </span>
                                                        %
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <span
                                                            className="text-gray-900 font-medium">{item.pricingStrategy?.fee.formatted} </span>
                                                    </td>
                                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                                        <time
                                                            dateTime={item.createdAt.toDate().toISOString()}>{beautifulDateTime(item.createdAt, true)}</time>
                                                    </td>
                                                </tr>
                                            ))}
                                            {other.length === 0 && (
                                                <tr className="bg-white">
                                                    <td colSpan="4"
                                                        className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                                                        No results
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )

}

export default AdminDashboard
