import {onErrorProfileFallback} from "../components/utils";
import React, {useEffect, useState} from "react";
import AuthorText from "../components/author-text";
import {Link, useHistory, useLocation} from "react-router-dom";
import {classNames} from "../utils";
import {CheckIcon, PaperClipIcon, PlusIcon, QuestionMarkCircleIcon} from "@heroicons/react/solid";
import Spinner from "../components/spinner";
import currency from "currency.js";
import {buyerFeeFactor, buyerFeeFixedAmount} from "../config";
import {RadioGroup} from "@headlessui/react";
import MediaItem from "../components/files/media-item";

const CreateOffer = props => {
    const {open, onAction} = props;

    const [titleValue, setTitleValue] = useState("")
    const [descriptionValue, setDescriptionValue] = useState("")
    const [dueDateValue, setDueDateValue] = useState("")
    const [priceValue, setPriceValue] = useState("")
    const [buyerValue, setBuyerValue] = useState(settings[0])

    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        // reset
        setIsSubmitting(false)
        if (open) {
            setTitleValue("")
            setDescriptionValue("")
            setDueDateValue("")
            setPriceValue("")
            setBuyerValue(settings[0])
        }
    }, [open])

    function submitAction(e) {
        e.preventDefault();
        setIsSubmitting(true)
        onAction({
            title: titleValue,
            description: descriptionValue,
            dueDate: dueDateValue,
            price: Number(priceValue),
            creatorRole: buyerValue.me === true ? "buyer" : "seller"
        }, () => {
            setIsSubmitting(false)
        })
    }

    return (
        <>
            <form onSubmit={submitAction}>
                <div className="bg-white px-4 pb-4 px-6 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Create a New Offer
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                Let's start by defining the details...
                            </p>
                        </div>
                    </div>
                    <div className="mt-8 sm:flex flex-col">
                        <div className="pb-5">
                            <div className="sm:max-w-4xl sm:mx-auto lg:mx-0">
                                <div className="sm:flex">
                                    <div
                                        className="mt-3 sm:mt-0 flex w-full sm:items-center flex-col sm:flex-row space-y-2 sm:space-y-0">
                                        <div className="basis-3/12 w-full pr-0 sm:pr-3">
                                            <select aria-label="Action" name="action" defaultValue={"buying"}
                                                    className="w-full block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                                <option value="buying">I am buying</option>
                                                <option value="selling" disabled>I am selling</option>
                                            </select>
                                        </div>
                                        <div className="basis-4/12 w-full">
                                            <select aria-label="Action" name="action"
                                                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                                <option>Art Commission</option>
                                                <option>Photo</option>
                                                <option>Video</option>
                                                <option>Document</option>
                                                <option>Freelance Project</option>
                                                <option>Custom Service</option>
                                                <option>Accounts</option>
                                            </select>
                                        </div>
                                        <div
                                            className="basis-5/12 w-full flex flex-col sm:flex-row sm:items-center">
                                            <div className="sm:mx-3 sm:my-0 mb-2 text-center">
                                                <span>for</span>
                                            </div>
                                            <div className="relative">
                                                <div
                                                    className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm"> $ </span>
                                                </div>
                                                <input aria-label="Price" type="text" name="price" id="price"
                                                       onChange={e => setPriceValue(e.target.value)}
                                                       className="number-separator focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                                       placeholder="0.00" aria-describedby="price-currency"/>
                                                <div
                                                    className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm"
                                                          id="price-currency"> USD </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pb-5">
                            <label htmlFor="description"
                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Description
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        onChange={e => setDescriptionValue(e.target.value)}
                                        value={descriptionValue}
                                        disabled={isSubmitting}
                                        id="description"
                                        rows={3}
                                        className="w-full shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                    />
                                <p className="mt-2 text-sm text-gray-500">What is this about?</p>
                            </div>
                        </div>
                        <div className="pb-5">
                            <label htmlFor="dueDate"
                                   className="block text-sm font-medium text-gray-700">
                                Due date
                            </label>
                            <div className="mt-1">
                                <input
                                    onChange={e => setDueDateValue(e.target.value)}
                                    value={dueDateValue}
                                    disabled={isSubmitting}
                                    id="dueDate"
                                    type="date"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder="YYYY-MM-DD"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        disabled={isSubmitting}
                        type="submit"
                        className={classNames(
                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                            isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                    >
                        {isSubmitting && <Spinner/>}
                        {isSubmitting ? "Sending offer..." : "Send offer"}
                    </button>
                </div>
            </form>
        </>
    )
}
const Payment = props => {
    const {offer, open, onAction} = props;

    const [isLoading, setIsLoading] = useState(false)
    const [isConfirmingCard, setIsConfirmingCard] = useState(false)
    const [setupIntentClientSecret, setSetupIntentClientSecret] = useState()
    const [cards, setCards] = useState([
        {
            id: "balance",
            card: {
                brand: "Wallet Balance",
                last4: "Pay directly from your wallet balance",
                exp: ""
            }
        },
        {
            id: "card_1J9Z2pJZ2Z2Z2Z2Z2Z2Z2Z2Z",
            card: {
                brand: "Visa",
                last4: "**** 4242",
                exp: "12/2025",
            }
        },
    ])
    const [selectedCard, setSelectedCard] = useState(null)

    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(() => {
        if (open) {
            setIsSubmitting(false)
            setSelectedCard(null)
        }
    }, [open])

    const [subtotal, setSubtotal] = useState("")
    const [total, setTotal] = useState("")
    const [fee, setFee] = useState("")
    const [feeText, setFeeText] = useState("")
    useEffect(() => {
        if (offer) {
            let _subtotal = currency(offer.price, {symbol: "$"}),
                _total = _subtotal.multiply(buyerFeeFactor).add(buyerFeeFixedAmount),
                _fee = _total.subtract(_subtotal),
                _feeText = "Service Fee (4,4% + 50c)"

            /*if (_fee.intValue < 100) {
                // minimal fee is 1$
                _fee = currency(1, {symbol: '$', precision: 2})
                _total = _subtotal.add(_fee)
                _feeText = "Service Fee"
            }*/

            setSubtotal(_subtotal.format())
            setTotal(_total.format())
            setFee(_fee.format())
            setFeeText(_feeText)
        }
    }, [offer])

    function onStartProject(e) {
        setIsSubmitting(true)
        onAction("start", () => {
            setIsSubmitting(false)
        })
    }

    return (
        <>
            {!isLoading ? (
                <div className="bg-white px-6 pb-4 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Pay
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                Start by paying into the fund.
                            </p>
                        </div>
                    </div>
                    {offer && (
                        <div className="mt-8">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-2">
                                    <h3 className="font-medium text-gray-900">Pricing strategy</h3>
                                    <dl className="mt-2 border-t border-gray-200 divide-y divide-gray-200">
                                        <div className="py-3 flex justify-between text-sm font-medium">
                                            <dt className="text-gray-500">Subtotal</dt>
                                            <dd className="text-gray-700">{subtotal}</dd>
                                        </div>
                                        <div className="py-3 flex justify-between text-sm font-medium">
                                            <dt className="text-gray-500 inline-flex items-center">{feeText}
                                                <QuestionMarkCircleIcon
                                                    className="w-4 h-4 inline ml-1"/></dt>
                                            <dd className="text-gray-700">{fee}</dd>
                                        </div>
                                        <div className="py-3 flex justify-between font-medium">
                                            <dt className="text-gray-900 font-bold">Total</dt>
                                            <dd className="text-gray-900 font-bold">{total}</dd>
                                        </div>
                                    </dl>
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="mb-2">
                                        <h3 className="font-medium text-gray-900">Payment methods</h3>
                                    </div>
                                    <RadioGroup value={selectedCard}
                                                onChange={e => !isSubmitting && setSelectedCard(e)}>
                                        <RadioGroup.Label className="sr-only">Cards</RadioGroup.Label>
                                        <div className="relative bg-white rounded-md -space-y-px">
                                            {cards.map((plan, planIdx) => (
                                                <RadioGroup.Option
                                                    key={plan.id}
                                                    value={plan}
                                                    className={({checked}) =>
                                                        classNames(
                                                            planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                            planIdx === cards.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                            checked ? 'bg-orange-50 border-orange-200 z-10' : 'border-gray-200',
                                                            'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                                                        )
                                                    }
                                                >
                                                    {({active, checked}) => (
                                                        <>
                                                            <div className="flex items-center text-sm">
                                                                                  <span
                                                                                      className={classNames(
                                                                                          checked ? 'bg-indigo-500 border-transparent' : 'bg-white border-gray-300',
                                                                                          active ? 'ring-2 ring-offset-2 ring-gray-900' : '',
                                                                                          'h-4 w-4 rounded-full border flex items-center justify-center'
                                                                                      )}
                                                                                      aria-hidden="true"
                                                                                  >
                                                                                    <span
                                                                                        className="rounded-full bg-white w-1.5 h-1.5"/>
                                                                                  </span>
                                                                <RadioGroup.Label as="span"
                                                                                  className="capitalize ml-3 font-medium text-gray-900">
                                                                    {plan.card.brand}
                                                                </RadioGroup.Label>
                                                            </div>
                                                            <RadioGroup.Description
                                                                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                                                                              <span
                                                                                  className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium')}
                                                                              >
                                                                                  {plan.card.last4}
                                                                              </span>
                                                            </RadioGroup.Description>
                                                            <RadioGroup.Description
                                                                className={classNames(
                                                                    checked ? 'text-orange-700' : 'text-gray-500',
                                                                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                                                                )}
                                                            >
                                                                {plan.card.exp}
                                                            </RadioGroup.Description>
                                                        </>
                                                    )}
                                                </RadioGroup.Option>
                                            ))}
                                            {cards.length === 0 && (
                                                <span>No existing cards</span>
                                            )}
                                        </div>
                                    </RadioGroup>
                                </div>
                            </dl>
                        </div>
                    )}

                </div>
            ) : (
                <div className="bg-white flex justify-center px-4 py-6">
                    <Spinner big/>
                </div>
            )}

            <div className="rounded-md bg-green-50 p-4 m-6">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                             aria-hidden="true" className="h-5 w-5 text-green-400">
                            <path fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                  clip-rule="evenodd"></path>
                        </svg>
                    </div>
                    <div className="ml-3 w-full">
                        <div className="text-sm text-green-700">Paynco will safeguard the payment until the deliverables
                            are reviewed and approved.
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                    disabled={!selectedCard || isSubmitting}
                    type="button"
                    className={classNames(
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                        (!selectedCard || isSubmitting) ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    )}
                    onClick={onStartProject}
                >
                    {isSubmitting && <Spinner/>}
                    {isSubmitting ? "Starting..." : "Pay to Start"}
                </button>
            </div>
        </>

    )
}
const ReviewWork = props => {
    const {offer, open, onAction} = props;

    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(() => {
        setIsSubmitting(false)
    }, [open])

    function pay() {
        setIsSubmitting(true)
        onAction({}, () => {
            setIsSubmitting(false)
        })
    }

    function ask() {

    }

    const [isDownloading, setIsDownloading] = useState(false)

    function downloadDeliveredFiles() {
        setIsDownloading(true)
        setTimeout(() => {
            setIsDownloading(false)
        }, 2000)
    }

    return (
        <>
            <div className="bg-white px-6 pb-4 sm:pb-4">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Review
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Review deliverables and pay.
                        </p>
                    </div>
                </div>
                {offer && (
                    <div className="mt-8">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                        {(offer.deliverables && offer.deliverables.attachments && offer.deliverables.attachments.length > 0) ? (
                                            <li className="pl-3 pr-4 py-3 flex flex-col">
                                                <div
                                                    className="flex items-center justify-between text-sm">
                                                    <div className="w-0 flex-1 flex items-center">
                                                        <PaperClipIcon
                                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                            aria-hidden="true"/>
                                                        <span
                                                            className="ml-2 flex-1 w-0 truncate">{offer.deliverables.attachments.length} {offer.deliverables.attachments.length === 1 ? "attachment" : "attachments"}</span>
                                                    </div>
                                                    <div className="ml-4 flex-shrink-0">
                                                        {isDownloading ? (
                                                            <Spinner/>
                                                        ) : (
                                                            <span
                                                                onClick={downloadDeliveredFiles}
                                                                className="cursor-pointer font-medium text-gray-600 hover:text-blue-500">Download all</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="mt-4 grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-4">
                                                    {offer.deliverables.attachments.map((file, i) => (
                                                        <div key={i}>
                                                            <MediaItem defaultFileId={file + "?q=" + i}/>
                                                        </div>
                                                    ))}
                                                </div>
                                            </li>
                                        ) : (
                                            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">


                                                <div
                                                    className="w-0 flex-1 flex items-center justify-center">
                                                                    <span
                                                                        className="text-gray-500">No attachments</span>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </dd>
                            </div>
                        </dl>
                    </div>
                )}
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                    disabled={isSubmitting}
                    type="button"
                    className={classNames(
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                        isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    )}
                    onClick={pay}
                >
                    {isSubmitting && <Spinner/>}
                    {isSubmitting ? "Accepting..." : "Accept and Pay"}
                </button>
                <button
                    disabled={isSubmitting}
                    type="button"
                    className={classNames(
                        "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                        isSubmitting ? "bg-gray-300 cursor-default" : "bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500"
                    )}
                    onClick={ask}
                >
                    Ask for Revision
                </button>
            </div>
        </>
    )
}
const Completed = () => {
    return (
        <>
            <div className="text-center mb-12">
                <CheckIcon className="mx-auto h-12 w-12 text-green-400"/>
                <h3 className="mt-2 text-sm font-medium text-gray-900">Success!</h3>
                <p className="mt-1 text-sm text-gray-500">Your first transaction was successfully completed.</p>
                <div className="mt-6">
                    <Link
                        to="#create"
                        className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
                        Create a New Offer
                    </Link>
                </div>
            </div>
        </>
    )
}

const defaultSteps = [
    {id: '01', name: 'Create', description: '', href: '#create', status: 'current', component: CreateOffer},
    {id: '02', name: 'Pay', description: '', href: '#pay', status: 'upcoming', component: Payment},
    //{id: '03', name: 'Delivery', description: '', href: '#delivery', status: 'upcoming'},
    {id: '03', name: 'Review', description: '', href: '#review', status: 'upcoming', component: ReviewWork},
    {id: '04', name: 'Complete', description: '', href: '#completed', status: 'upcoming', component: Completed},
]

const Demo = () => {
    const location = useLocation()
    const history = useHistory()
    const hash = location?.hash
    const [page, setPage] = useState(-1)
    const [steps, setSteps] = useState(defaultSteps)

    const [offer, setOffer] = useState({
        price: 1240.00,
        deliverables: {
            attachments: [
                // sample image url
                "https://picsum.photos/300/200",
                "https://picsum.photos/300/200",
                "https://picsum.photos/300/200",
                "https://picsum.photos/300/200",
                "https://picsum.photos/300/200",
                "https://picsum.photos/300/200",
            ]
        }
    })

    useEffect(() => {
        let _page
        switch (hash) {
            case "#create":
                _page = 0
                break;
            case "#pay":
                _page = 1
                break;
            /*case "#delivery":
                _page = 2
                break;*/
            case "#review":
                _page = 2
                break;
            case "#completed":
                _page = 3
                break;
            default:
                _page = -1
                history.push("#create")
        }

        let _steps = [...defaultSteps]
        _steps.forEach((el, i) => {
            const status = i === _page ? "current" : i < _page ? "complete" : "upcoming"
            _steps[i] = {...el, status}
        })

        setSteps(_steps)
        setPage(_page)
    }, [hash])

    function onAction(data, cb) {
        setTimeout(() => {
            if (page + 1 < defaultSteps.length) {
                if (data?.price) {
                    setOffer({...offer, price: data.price})
                }
                history.push(defaultSteps[page + 1].href)
            }
            cb && cb()
        }, 500)
    }

    return (
        <div className="flex h-full">

            <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

                <div className="relative z-0 flex flex-1 overflow-hidden">
                    <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                        {/* Start main area*/}
                        <div className="flex-1 relative pb-8 z-0 overflow-y-auto">
                            {/* Page header */}
                            <div className="bg-white shadow">
                                <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                                    <div
                                        className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                                        <div className="flex-1 min-w-0">
                                            {/* Profile */}
                                            <div className="flex flex-col items-center">
                                                <span className="text-md font-bold">Transaction with</span>
                                                <img
                                                    className="mt-2 h-8 w-8 rounded-full sm:block"
                                                    src="https://picsum.photos/id/1005/200/200"
                                                    onError={onErrorProfileFallback}
                                                    alt=""
                                                />
                                                <h1 className="ml-3 text-md leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                                    <AuthorText id={""} fallback={"Ethan Animatez"}/>
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:border-t lg:border-b lg:border-gray-200">
                                <nav className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 mt-4 lg:mt-0"
                                     aria-label="Progress">
                                    <ol
                                        role="list"
                                        className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
                                    >
                                        {steps.map((step, stepIdx) => (
                                            <li key={step.id} className="relative overflow-hidden lg:flex-1">
                                                <div
                                                    className={classNames(
                                                        stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                                                        stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                                                        'border border-gray-200 overflow-hidden lg:border-0'
                                                    )}
                                                >
                                                    {step.status === 'complete' ? (
                                                        <a href={step.href} className="group">
                    <span
                        className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                    />
                                                            <span
                                                                className={classNames(
                                                                    stepIdx !== 0 ? 'lg:pl-9' : '',
                                                                    'px-6 py-5 flex items-center text-sm font-medium'
                                                                )}
                                                            >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                          <CheckIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">{step.name}</span>
                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                      </span>
                    </span>
                                                        </a>
                                                    ) : step.status === 'current' ? (
                                                        <a href={step.href} aria-current="step">
                    <span
                        className="absolute top-0 left-0 h-full w-1 bg-indigo-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                    />
                                                            <span
                                                                className={classNames(
                                                                    stepIdx !== 0 ? 'lg:pl-9' : '',
                                                                    'px-6 py-5 flex items-center text-sm font-medium'
                                                                )}
                                                            >
                      <span className="flex-shrink-0">
                        <span
                            className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600">
                          <span className="text-indigo-600">{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-indigo-600">{step.name}</span>
                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                      </span>
                    </span>
                                                        </a>
                                                    ) : (
                                                        <a href={step.href} className="group">
                    <span
                        className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                        aria-hidden="true"
                    />
                                                            <span
                                                                className={classNames(
                                                                    stepIdx !== 0 ? 'lg:pl-9' : '',
                                                                    'px-6 py-5 flex items-center text-sm font-medium'
                                                                )}
                                                            >
                      <span className="flex-shrink-0">
                        <span
                            className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                          <span className="text-gray-500">{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium text-gray-500">{step.name}</span>
                        <span className="text-sm font-medium text-gray-500">{step.description}</span>
                      </span>
                    </span>
                                                        </a>
                                                    )}

                                                    {stepIdx !== 0 ? (
                                                        <>
                                                            {/* Separator */}
                                                            <div
                                                                className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                                                                aria-hidden="true">
                                                                <svg
                                                                    className="h-full w-full text-gray-300"
                                                                    viewBox="0 0 12 82"
                                                                    fill="none"
                                                                    preserveAspectRatio="none"
                                                                >
                                                                    <path d="M0.5 0V31L10.5 41L0.5 51V82"
                                                                          stroke="currentcolor"
                                                                          vectorEffect="non-scaling-stroke"/>
                                                                </svg>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </li>
                                        ))}
                                    </ol>
                                </nav>
                            </div>

                            <div className="mt-8">
                                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="mt-2 pt-6 bg-white overflow-hidden shadow rounded-lg">
                                        {steps[page]?.component && (() => {
                                            const TagName = steps[page].component;
                                            return <TagName onAction={onAction} offer={offer}/>
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End main area */}
                    </main>
                    <aside
                        className="relative hidden w-96 flex-shrink-0 overflow-y-auto border-r border-gray-200 xl:order-first xl:flexa xl:flex-col">
                        {/* Start secondary column (hidden on smaller screens) */}
                        <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                            <div className="h-full rounded-lg border-2 border-dashed border-gray-200"/>
                        </div>
                        {/* End secondary column */}
                    </aside>
                </div>
            </div>


        </div>


    )

}

const settings = [
    {me: true, name: 'I am buying', description: 'I will be paying for this transaction.', current: true},
    {me: false, name: 'I am selling', description: 'I will be delivering.', current: false, countryCheck: true},
]


export default Demo
