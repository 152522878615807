import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getOffer, unconnectedOfferSelector} from "../../store/offers";
import {InformationCircleIcon, SparklesIcon} from "@heroicons/react/solid";
import MessageHeader from "./message-header";

const MessageOffer = props => {
    const {item, onActionClick, user, setReplyTo} = props
    const dispatch = useDispatch()

    const offer = useSelector(unconnectedOfferSelector(item.documentReference.id)) || {}

    useEffect(() => {
        dispatch(getOffer(item.documentReference))
    }, [])

    function onViewClick() {
        onActionClick(item.documentReference)
    }

    function renderMsg() {
        switch (item.type) {
            case "NEW_OFFER":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
            case "OFFER_ACCEPTED":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}

                        <div
                            className="bg-gray-50 text-gray-600 overflow-hidden rounded-md mt-4 pl-3 pr-4 py-3 italic text-sm flex flex-col">
                            <div className="flex-1 flex items-center">
                                <InformationCircleIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    aria-hidden="true"/>
                                <span
                                    className="ml-2 flex-1 w-0 font-medium">What happens now?</span>
                            </div>
                            <div className="flex-1 flex items-center">
                                <i className="flex-shrink-0 h-5 w-5"
                                   aria-hidden="true"/>
                                {offer?.buyer === user?.uid && (
                                    <ol className="ml-2 flex-1 w-0 list-none">
                                        <li
                                            className="mt-2">
                                            Please make the payment to start the project or the transaction. You can
                                            use the button “Pay to Start” to make the payment.
                                        </li>
                                        <li
                                            className="mt-2">
                                            Once the payment validated, Paynco will safeguard the money and send a
                                            notification to the seller.
                                        </li>
                                        <li
                                            className="mt-2">
                                            The seller will NOT start the work or deliver the agreed transaction
                                            until you make the payment.
                                        </li>
                                    </ol>
                                )}
                                {offer?.seller === user?.uid && (
                                    <ol className="ml-2 flex-1 w-0 list-none">
                                        <li
                                            className="mt-2">
                                            Waiting for the buyer to make the payment …
                                        </li>
                                        <li
                                            className="mt-2">
                                            You will receive a confirmation once Paynco receives the agreed amount.
                                        </li>
                                    </ol>
                                )}
                            </div>
                        </div>
                    </>
                )
            case "OFFER_REJECTED":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
            case "OFFER_CANCELED":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
        }
    }

    function headerText() {
        switch (item.type) {
            case "NEW_OFFER":
                return "has created a new offer"
            case "OFFER_ACCEPTED":
                return "has accepted the offer"
            case "OFFER_REJECTED":
                return "has rejected the offer"
            case "OFFER_CANCELED":
                return "has canceled the offer"
        }
    }

    return (
        <div className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
            <MessageHeader item={item} text={headerText()} setReplyTo={setReplyTo}/>
            <div
                className="mt-4 space-y-6 text-sm text-gray-800">
                {renderMsg()}
            </div>
        </div>
    )
}

export default MessageOffer
