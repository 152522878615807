import React, {useEffect, useState} from "react";
import {NavLink, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import ProjectsTab from "./projects-tab";
import {useDispatch, useSelector} from "react-redux";
import {orderedProjectsSelector, removeProjectsListener, startProjectsListener} from "../../store/projects";
import {orderedOffersSelector, removeOffersListener, startOffersListener} from "../../store/offers";
import OffersTab from "./offers-tab";

const tabs = [
    {name: 'Active', href: 'active'},
    {name: 'Delivered', href: 'delivered'},
    {name: 'In revision', href: 'in-revision'},
    {name: 'Completed', href: 'completed'},
    {name: 'Offers', href: 'offers'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/*
Project statuses: active, delivered, in-revision, completed
 */

const empty = {
    "active": [],
    "delivered": [],
    "in-revision": [],
    "completed": [],
}

const Projects = () => {
    let {path, url} = useRouteMatch();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(startProjectsListener())
        dispatch(startOffersListener())
        return () => {
            dispatch(removeProjectsListener())
            dispatch(removeOffersListener())
        }
    }, [])


    const orderedProjects = useSelector(orderedProjectsSelector)
    const [projects, setProjects] = useState(empty)

    useEffect(() => {
        const map = {
            "active": [],
            "delivered": [],
            "in-revision": [],
            "completed": [],
        }
        for (let i = 0; i < orderedProjects.length; i++) {
            const p = orderedProjects[i]
            if (map.hasOwnProperty(p.status)) {
                map[p.status].push(p)
            } else {
                console.warn(`Unrecognized project status '${p.status}'`)
            }
        }
        setProjects(map)
    }, [orderedProjects])

    const orderedOffers = useSelector(orderedOffersSelector)

    return (
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <div className="mt-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/*<h2 className="text-lg leading-6 font-medium text-gray-900">Settings</h2>*/}
                    <div className="mt-2 pt-6 bg-white overflow-hidden shadow rounded-lg">
                        <div className="px-4 sm:px-6 pb-6">
                            <h2 id="billing-history-heading" className="text-lg leading-6 font-medium text-gray-900">
                                My projects
                            </h2>
                        </div>

                        {/* Tabs */}
                        <div className="lg:hidden">
                            <label htmlFor="selected-tab" className="sr-only">
                                Select a tab
                            </label>
                            <select
                                id="selected-tab"
                                name="selected-tab"
                                /*onChange={(e) => setCurrentTab(e.target.value)}*/
                                className="mt-1 block w-full pl-3 pr-10 pt-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue={tabs.find((tab) => tab.href === "active").name}
                            >
                                {tabs.map((tab) => (
                                    <option key={"c" + tab.name} value={tab.href}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden lg:block">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8 px-5">
                                    {tabs.map((tab) => (
                                        <NavLink
                                            to={`${url}/${tab.href}`}
                                            key={"b" + tab.name}
                                            className={classNames(
                                                'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                            )}
                                            activeClassName={"border-indigo-500 text-indigo-600"}
                                        >
                                            {tab.name}
                                            {projects.hasOwnProperty(tab.href) ? (
                                                <span
                                                    className='bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                >{projects[tab.href].length}</span>
                                            ) : (
                                                <span
                                                    className='bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                >{orderedOffers.length}</span>
                                            )}
                                        </NavLink>
                                    ))}
                                </nav>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden border-t border-gray-200">
                                        <Switch>
                                            <Route exact path={path}>
                                                <Redirect to={"/projects/active"}/>
                                            </Route>
                                            {tabs.map((tab) => (
                                                <Route key={"a" + tab.href} path={`${path}/${tab.href}`}>
                                                    {tab.href === "offers" ? (
                                                        <OffersTab results={orderedOffers} status={tab.href}/>
                                                    ) : (
                                                        <ProjectsTab results={projects[tab.href]} status={tab.href}/>
                                                    )}
                                                </Route>
                                            ))}
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </main>
    )

}

export default Projects
