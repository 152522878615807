import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import {getProject, openDispute, projectSelector} from "../store/projects";
import {showToast, TOAST_ALERT} from "../store/toast";
import Spinner from "../components/spinner";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const OpenDispute = props => {
    const {projectRef, open, setOpen} = props;
    const cancelButtonRef = useRef()

    const textRef = useRef()

    const [isSubmitting, setIsSubmitting] = useState(false)


    useEffect(() => {
        if (open) {
            // reset
            setIsSubmitting(false)
        }
    }, [open])

    const dispatch = useDispatch()
    const project = useSelector(projectSelector(projectRef ? projectRef.id : ''))

    useEffect(() => {
        if (projectRef) dispatch(getProject(projectRef))
    }, [projectRef])

    function submit() {
        const text = textRef.current.value

        setIsSubmitting(true)

        dispatch(openDispute(projectRef.id, text, (err) => {
            if (err) {
                dispatch(showToast({
                    text: "There was an error submitting your request!",
                    type: TOAST_ALERT
                }))
            } else {
                dispatch(getProject(projectRef))
            }
            setOpen(false)
        }))
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={() => setOpen(false)}
            >
                <div
                    className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                {project && (
                                    <div>
                                        <h1 className="pb-4">Open a dispute - Request to cancel the project and to refund the buyer.</h1>
                                        <div
                                            className="sm:border-t sm:border-gray-200 sm:pt-5 mb-4">
                                            <label htmlFor="about"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 pb-4">
                                                Description
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                    ref={textRef}
                    id="about"
                    name="about"
                    rows={3}
                    className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={''}
                />
                                                <p className="mt-2 text-sm text-gray-500">Describe why you want to cancel the project.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={isSubmitting}
                                    type="button"
                                    className={classNames(
                                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                        isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                    onClick={submit}
                                >
                                    {isSubmitting && <Spinner/>}
                                    {isSubmitting ? "Submitting..." : "Submit"}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Dismiss
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default OpenDispute
