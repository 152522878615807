import React, {useState} from "react";
import MediaUploadItem from "./media-upload-item";
import {classNames} from "../../utils";

export function useMediaUpload() {
    const [files, setFiles] = useState([])
    const [isUploading, setIsUploading] = useState(false)

    /*useEffect(() => {
        setFiles([])
        setIsUploading(false)
        return () => {
            setFiles([])
            setIsUploading(false)
        }
    }, [])*/

    function onFiles(e) {
        const _added = e.target.files,
            _files = [...files, ..._added];
        setFiles(_files)
        if (_added.length > 0) setIsUploading(true)
        e.target.value = null
    }

    function reset(e) {
        setFiles([])
        setIsUploading(false)
    }

    function getFileIdList() {
        const fileIds = []
        for (let i = 0; i < files.length; i++) {
            const f = files[i]
            if (f._fileId) fileIds.push(f._fileId)
        }
        return fileIds
    }

    return {files, onFiles, setFiles, setIsUploading, isUploading, reset, getFileIdList}
}

export default function MediaUpload({mediaUpload, projectId = null, dense = false}) {
    function onUploaded() {
        let complete = true
        for (let i = 0; i < mediaUpload.files.length; i++) {
            const upload = mediaUpload.files[i]
            if (!upload._fileId) {
                complete = false
                break
            }
        }
        mediaUpload.setIsUploading(!complete)
    }

    function onRemove(f, i) {
        const _files = [...mediaUpload.files]
        _files.splice(i, 1)
        mediaUpload.setFiles(_files)
    }

    return (
        <ul role="list"
            className={classNames(
                "grid",
                dense ? "grid-cols-3 gap-x-4 gap-y-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 2xl:grid-cols-8" : "mt-4 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-4"
            )}>
            {mediaUpload.files.map((file, i) => (
                <li key={file.name}>
                    <MediaUploadItem
                        dense={dense}
                        inputFile={file}
                        index={i}
                        projectId={projectId}
                        onUploaded={onUploaded}
                        onRemove={onRemove}/>
                </li>
            ))}
        </ul>
    )
}
