import React, {useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {showToast, TOAST_ALERT} from "../store/toast";
import {useDispatch} from "react-redux";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import AuthPageHeader from "../components/auth/auth-page-header";

const auth = getAuth()

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SignIn = () => {
    const dispatch = useDispatch()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [redirect, setRedirect] = useState()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')


    useEffect(() => {
        setEmailError('')
        setPasswordError('')
    }, [email, password])

    useEffect(() => {
        setIsSubmitting(false)
        try {
            if (window.LeadDyno) {
                window.LeadDyno.recordVisit();
                window.LeadDyno.autoWatch();
            }
        } catch (e) {
            console.error(e)
        }
        return () => {
            setIsSubmitting(false)
        }
    }, [])

    function onSubmit(e) {
        e.preventDefault()

        setIsSubmitting(true)

        signInWithEmailAndPassword(auth, email, password).then(() => {
            setIsSubmitting(false)
            setRedirect("/")
        }).catch(err => {
            console.log(err, err.code)
            setIsSubmitting(false)
            if (err) {
                switch (err.code) {
                    case "auth/too-many-requests":
                        setEmailError(" ")
                        setPasswordError("There were too many requests! Please wait and try again later.")
                        return dispatch(showToast({
                            text: "There were too many requests! Please wait and try again later.",
                            type: TOAST_ALERT
                        }))
                    case "auth/wrong-password":
                        setEmailError(" ")
                        setPasswordError("Wrong email and password combination.")
                        return dispatch(showToast({
                            text: "Wrong email and password combination!",
                            type: TOAST_ALERT
                        }))
                }
            }
            dispatch(showToast({
                text: "There was an error signing you in!",
                type: TOAST_ALERT
            }))
        })
    }

    return redirect ? <Redirect to={redirect}/> : (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <AuthPageHeader/>
                <h1 className="text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h1>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Or{' '}
                    <Link to="/sign-up" className="font-medium text-indigo-600 hover:text-indigo-500">
                        register for a new account
                    </Link>
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={onSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    disabled={isSubmitting}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    aria-invalid={!!emailError}
                                    aria-describedby={emailError ? "email-error" : ""}
                                    required
                                    className={classNames(
                                        "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm",
                                        emailError ? "border-red-300 focus:ring-red-500 focus:border-red-500 placeholder-red-300 text-red-900" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-400"
                                    )}
                                />
                                {emailError && (
                                    <div
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                                    </div>
                                )}
                            </div>
                            {emailError && (
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {emailError}
                                </p>
                            )}
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    disabled={isSubmitting}
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    aria-invalid={!!passwordError}
                                    aria-describedby={passwordError ? "password-error" : ""}
                                    required
                                    className={classNames(
                                        "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm",
                                        passwordError ? "border-red-300 focus:ring-red-500 focus:border-red-500 placeholder-red-300 text-red-900" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-400"
                                    )}
                                />
                                {passwordError && (
                                    <div
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                                    </div>
                                )}
                            </div>
                            {passwordError && (
                                <p className="mt-2 text-sm text-red-600" id="password-error">
                                    {passwordError}
                                </p>
                            )}
                        </div>

                        <div className="flex items-center justify-between">

                            <div className="text-sm">
                                <Link to="/password-recovery"
                                      className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {isSubmitting ? "Signing you in..." : "Sign in"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default SignIn
