import React, {useEffect} from "react";
import {DocumentTextIcon} from "@heroicons/react/solid";
import {useDispatch, useSelector} from "react-redux";
import {fileSelector, getFile} from "../../store/files";


export default function MediaViewItem({src = "", fileId}) {
    const dispatch = useDispatch()
    const file = useSelector(fileSelector(fileId))

    useEffect(() => {
        if (fileId) dispatch(getFile(fileId))
        else src = ""
    }, [fileId])

    useEffect(() => {
        if (file) {
            console.log(file)
            src = file.thumbnailBigUrl
        }
    }, [file])

    return (
        <div
            className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-900 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
            {src ? (
                <img src={src} alt=""
                     className="object-cover pointer-events-none"/>
            ) : (
                <div className="flex justify-center items-center">
                    <DocumentTextIcon className="text-white h-12 w-12 opacity-75"/>
                </div>
            )}
        </div>
    )
}
