import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    listener: null,
    ordered: []
};

const slice = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {
        setPaymentMethods: (state, action) => {
            state.ordered = action.payload.ordered
            state.listener = action.payload.listener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setPaymentMethods} = slice.actions
/*
export const createPaymentMethod = (number = '', exp_month = 0, exp_year = 0, cvc = '') => async (dispatch) => {
    try {
        const result = await functions.httpsCallable("createPaymentMethod")({number, exp_month, exp_year, cvc})
        console.log(result)
    } catch (e) {
        // todo: show error
    }
}

export const startPaymentMethodsListener = () => async (dispatch, getState) => {
    const state = getState(),
        uid = auth.currentUser.uid,
        query = firebase.firestore().collection("users").doc(uid).collection("payment-methods")
            .orderBy("createdAt", "desc")
            .limit(50)

    if (state.paymentMethods.listener) state.paymentMethods.listener() // unsubscribe

    const listener = query.onSnapshot(snapshot => {
        dispatch(setPaymentMethods({
            listener,
            ordered: snapshot.docs.map(doc => ({...doc.data(), id: doc.id, doc}))
        }))
    })
}

export const removePaymentMethodsListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.paymentMethods.listener) state.paymentMethods.listener() // unsubscribe
}*/

// SELECTORS

export const orderedPaymentMethodsSelector = state => state.paymentMethods.ordered
