import React, {useEffect, useState} from 'react'
import {DocumentTextIcon, InformationCircleIcon, PaperClipIcon, SparklesIcon} from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import {getProject, projectSelector} from "../../store/projects";
import MessageHeader from "./message-header";
import {downloadFiles} from "../../store/files";
import Spinner from "../../components/spinner";
import MediaItem from "../../components/files/media-item";

const MessageProject = props => {
    const {item, onActionClick, user, setReplyTo} = props
    const dispatch = useDispatch()

    const offer = useSelector(projectSelector(item.documentReference.id)) || {}

    useEffect(() => {
        dispatch(getProject(item.documentReference))
        setIsDownloading(false)
    }, [])

    function onViewClick() {
        onActionClick(item.documentReference)
    }

    const [isDownloading, setIsDownloading] = useState(false)

    function downloadDeliveredFiles() {
        setIsDownloading(true)
        dispatch(downloadFiles(item.attachments, () => {
            setIsDownloading(false)
        }))
    }

    function renderMsg() {
        switch (item.type) {
            case "PROJECT_STARTED":
                return <>
                    {(offer && offer.title) && (
                        <div
                            className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                            <ul className="divide-y divide-gray-300">
                                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                    <div className="w-0 flex-1 flex items-center">
                                        <SparklesIcon
                                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                                            aria-hidden="true"/>
                                        <span
                                            className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <button onClick={onViewClick}
                                                type="button"
                                                className="font-medium text-indigo-600 hover:text-indigo-500">
                                            View
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                    <div
                        className="bg-gray-50 text-gray-600 overflow-hidden rounded-md mt-4 pl-3 pr-4 py-3 italic text-sm flex flex-col">
                        <div className="flex-1 flex items-center">
                            <InformationCircleIcon
                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                aria-hidden="true"/>
                            <span
                                className="ml-2 flex-1 w-0 font-medium">What happens now?</span>
                        </div>
                        <div className="flex-1 flex items-center">
                            <i className="flex-shrink-0 h-5 w-5"
                               aria-hidden="true"/>

                            <ol className="ml-2 flex-1 w-0 list-none">
                                <li
                                    className="mt-2">
                                    Paynco will now safeguard the money until the seller delivers the agreed work or the
                                    transaction.
                                </li>
                            </ol>
                        </div>
                    </div>
                </>
            case "PROJECT_DELIVERED":
                return (
                    <>
                        {(item.attachments || item.text || offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    {offer.title && (
                                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div className="w-0 flex-1 flex items-center">
                                                <SparklesIcon
                                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"/>
                                                <span
                                                    className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                            </div>
                                        </li>
                                    )}
                                    {item.text && (
                                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div className="w-0 flex-1 flex items-start">
                                                <DocumentTextIcon
                                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"/>
                                                <p
                                                    className="ml-2 flex-1 w-0 whitespace-pre-line">{item.text}</p>
                                            </div>
                                        </li>
                                    )}
                                    {item.attachments.length > 0 && (
                                        <li className="pl-3 pr-4 py-3 flex flex-col">

                                            <div className="flex items-center justify-between text-sm">
                                                <div className="w-0 flex-1 flex items-center">
                                                    <PaperClipIcon
                                                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"/>
                                                    <span
                                                        className="ml-2 flex-1 w-0 truncate">{item.attachments.length} {item.attachments.length === 1 ? "attachment" : "attachments"}</span>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    {isDownloading ? (
                                                        <Spinner/>
                                                    ) : (
                                                        <span
                                                            onClick={downloadDeliveredFiles}
                                                            className="cursor-pointer font-medium text-gray-600 hover:text-blue-500">Download all</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="mt-4 grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-4">
                                                {item.attachments.map((file, i) => (
                                                    <div key={i}>
                                                        <MediaItem defaultFileId={file} downloadable/>
                                                    </div>
                                                ))}
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                        <div
                            className="bg-gray-50 text-gray-600 overflow-hidden rounded-md mt-4 pl-3 pr-4 py-3 italic text-sm flex flex-col">
                            <div className="flex-1 flex items-center">
                                <InformationCircleIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    aria-hidden="true"/>
                                <span
                                    className="ml-2 flex-1 w-0 font-medium">What happens now?</span>
                            </div>
                            <div className="flex-1 flex items-center">
                                <i className="flex-shrink-0 h-5 w-5"
                                   aria-hidden="true"/>
                                {offer?.buyer === user?.uid && (
                                    <ol className="ml-2 flex-1 w-0 list-none">
                                        <li
                                            className="mt-2">
                                            Please review and approve the delivery …
                                        </li>
                                        <li
                                            className="mt-2">
                                            If there is no action taken from your side during next 5 days, the project
                                            or transaction will be automatically approved.
                                        </li>
                                        <li
                                            className="mt-2">
                                            Once the delivery approved, Paynco will automatically send the money to the
                                            seller’s account …
                                        </li>
                                    </ol>
                                )}
                                {offer?.seller === user?.uid && (
                                    <ol className="ml-2 flex-1 w-0 list-none">
                                        <li
                                            className="mt-2">
                                            Now, the buyer has to approve the delivery …
                                        </li>
                                        <li
                                            className="mt-2">
                                            Once the delivery approved, you will receive a notification and Paynco will
                                            automatically send the money to your account …
                                        </li>
                                    </ol>
                                )}
                            </div>
                        </div>
                    </>
                )
            case "PROJECT_IN_REVISION":
                return (
                    <>
                        {(item.text || offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    {offer.title && (
                                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div className="w-0 flex-1 flex items-center">
                                                <SparklesIcon
                                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"/>
                                                <span
                                                    className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                            </div>
                                        </li>
                                    )}
                                    {item.text && (
                                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                            <div className="w-0 flex-1 flex items-start">
                                                <DocumentTextIcon
                                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                    aria-hidden="true"/>
                                                <p
                                                    className="ml-2 flex-1 w-0 whitespace-pre-line">{item.text}</p>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </>
                )
            case "PROJECT_COMPLETED":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
        }
    }

    function headerText() {
        switch (item.type) {
            case "PROJECT_STARTED":
                return "has successfully made the payment"
            case "PROJECT_DELIVERED":
                return "has delivered the project or the transaction"
            case "PROJECT_IN_REVISION":
                return "has asked for revision"
            case "PROJECT_COMPLETED":
                return "has completed the project"
        }
    }

    return (
        <div className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
            <MessageHeader item={item} text={headerText()} setReplyTo={setReplyTo}/>
            <div
                className="mt-4 space-y-6 text-sm text-gray-800">
                {renderMsg()}
            </div>
        </div>
    )
}

export default MessageProject
