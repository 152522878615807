import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from 'firebase/auth';
import {collection, doc, getDoc, getFirestore, onSnapshot, orderBy, query, where} from 'firebase/firestore';

import {getFunctions, httpsCallable} from "firebase/functions";
import {region} from "../config";

const auth = getAuth(),
    firestore = getFirestore(),
    functions = getFunctions()

functions.region = region

const initialState = {
    ordered: [],
    listener: null,
    '': null
};

const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProject: (state, action) => {
            state[action.payload.id] = action.payload
        },
        setOrdered: (state, action) => {
            state.ordered = action.payload.ordered
            state.listener = action.payload.listener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setProject, setOrdered} = slice.actions


export const getProject = projectRefOrId => async (dispatch) => {
    const projectRef = typeof projectRefOrId === "string" ? doc(firestore, "projects", projectRefOrId) : projectRefOrId
    await getDoc(projectRef).then(doc => {
        if (doc.exists) {
            dispatch(setProject({...doc.data(), id: projectRef.id}))
        }
    })
}

export const startProject = (offerId, paymentMethodId, cb) => async (dispatch) => {
    const data = {
        offerId, paymentMethodId
    }

    const startProject = httpsCallable(functions, "startProject")
    await startProject(data).then(() => {
        console.log("complete")
        cb && cb()
    }).catch((err) => {
        console.error("err", err)
        cb && cb(err)
    })
}

export const openDispute = (projectId, text, cb) => async (dispatch) => {
    await httpsCallable(functions, "openDispute")({
        projectId, text
    }).then(() => {
        console.log("complete")
        cb && cb()
    }).catch((err) => {
        console.error("err", err)
        cb && cb(err)
    })
}

export const closeDispute = (projectId, action, cb) => async (dispatch) => {
    const data = {
        projectId, action
    }
    await httpsCallable(functions, "closeDispute")(data).then(() => {
        console.log("complete")
        cb && cb()
    }).catch((err) => {
        console.error("err", err)
        cb && cb(err)
    })
}

export const deliverProjectFiles = (projectId, attachments, text, cb) => async (dispatch) => {
    const data = {
        projectId, attachments, text
    }

    const deliverProject = httpsCallable(functions, "deliverProject")
    await deliverProject(data).then(() => {
        console.log("complete")
        cb && cb()
    }).catch((err) => {
        console.error("err", err)
        cb && cb(err)
    })
}

export const askForProjectRevision = (projectId, message, cb) => async (dispatch) => {
    const data = {
        projectId, message
    }

    const revisionOnProject = httpsCallable(functions, "revisionOnProject")
    await revisionOnProject(data).then(() => {
        console.log("complete")
    }).catch((err) => {
        console.error("err", err)
    }).finally(() => cb && cb())
}

export const payoutAndCompleteProject = (projectId, cb) => async (dispatch) => {
    const data = {
        projectId
    }

    const completeProject = httpsCallable(functions, "completeProject")
    await completeProject(data).then(() => {
        console.log("complete")
    }).catch((err) => {
        console.error("err", err)
    }).finally(() => cb && cb())
}


export const startProjectsListener = () => async (dispatch, getState) => {
    const uid = auth.currentUser.uid,
        state = getState(),
        _query = query(collection(firestore, "projects"), where("members", "array-contains-any", [uid]), orderBy("createdAt", "desc"))

    if (state.projects.listener) state.projects.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        dispatch(setOrdered({listener, ordered: snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))}))
    })
}

export const removeProjectsListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.projects.listener) state.projects.listener() // unsubscribe
}

// SELECTORS

export const orderedProjectsSelector = state => state.projects.ordered
export const projectSelector = id => state => state.projects[id]
