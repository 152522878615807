import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {useDispatch, useSelector} from "react-redux";
import {deliverProjectFiles, getProject, projectSelector} from "../store/projects";
import {showToast, TOAST_ALERT} from "../store/toast";
import {getStorage} from "firebase/storage";
import Spinner from "../components/spinner";
import {ExclamationCircleIcon} from '@heroicons/react/solid'
import {PaperClipIcon} from "@heroicons/react/outline";
import MediaUpload, {useMediaUpload} from "../components/files/media-upload";

const storage = getStorage()

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const DeliverProject = props => {
    const {projectRef, deliverProjectId, open, setOpen} = props;
    const cancelButtonRef = useRef()

    const textRef = useRef()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isTextValid, setIsTextValid] = useState(true)

    const mediaUpload = useMediaUpload()

    useEffect(() => {
        if (open) {
            // reset
            setIsSubmitting(false)
            setIsTextValid(true)
        }
    }, [open])

    const dispatch = useDispatch()
    const project = useSelector(projectSelector(projectRef ? projectRef.id : ''))

    useEffect(() => {
        if (projectRef) dispatch(getProject(projectRef))
    }, [projectRef])

    function submitDelivery() {
        const text = textRef.current.value
        setIsTextValid(!!text)
        if (!text || mediaUpload.isUploading) return
        setIsSubmitting(true)
        if (mediaUpload.files.length > 0) {
            const fileIds = mediaUpload.getFileIdList()
            if(fileIds.length !== mediaUpload.files.length) {
                setIsSubmitting(false)
                return dispatch(showToast({
                    text: "Please wait so that all files are finished uploading first!",
                    type: TOAST_ALERT
                }))
            }
            dispatch(deliverProjectFiles(deliverProjectId, fileIds, text, (err) => {
                /*dispatch(showToast({
                    text: "Your project was delivered!",
                    type: TOAST_INFO
                }))*/
                if (err) {
                    dispatch(showToast({
                        text: "There was an error submitting your request!",
                        type: TOAST_ALERT
                    }))
                }
                setOpen(false)
            }))
        } else {
            dispatch(deliverProjectFiles(deliverProjectId, [], text, (err) => {
                if (err) {
                    dispatch(showToast({
                        text: "There was an error submitting your request!",
                        type: TOAST_ALERT
                    }))
                }
                setOpen(false)
            }))
        }
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={() => setOpen(false)}
            >
                <div
                    className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                {project && (
                                    <div>
                                        <h1 className="pb-4">Deliver project</h1>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 mb-4">
                                            <label htmlFor="about"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Description
                                            </label>
                                            <div className="mt-1 sm:mt-0 relative sm:col-span-2">
                <textarea
                    ref={textRef}
                    id="about"
                    name="about"
                    rows={3}
                    className={classNames(
                        "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border rounded-md",
                        isTextValid ? "border-gray-300" : "border-red-300"
                    )}
                    defaultValue={''}
                    aria-invalid={isTextValid}
                />

                                                <p className={classNames(
                                                    "mt-2 text-sm",
                                                    isTextValid ? "text-gray-500" : "text-red-600"
                                                )}>
                                                    {isTextValid ? 'Delivery documentation' : (
                                                        <div className="flex items-center pointer-events-none">
                                                            <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-1"
                                                                                   aria-hidden="true"/>
                                                            This field is required
                                                        </div>
                                                    )}
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="cover_photo"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Attachments or Screenshots Validating the transaction
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                {mediaUpload.files.length > 0 ? (
                                                    <>
                                                        <div className="flex items-center">
                                                            <label
                                                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 group"
                                                            >
                                                                <input id="file-upload"
                                                                       onChange={mediaUpload.onFiles}
                                                                       name="file-upload" type="file" multiple
                                                                       className="sr-only"/>
                                                                <PaperClipIcon className="-ml-1 h-5 w-5 mr-2"
                                                                               aria-hidden="true"/>
                                                                <span className="">Attach more files</span>
                                                            </label>

                                                            <p className="ml-4 text-sm text-gray-500">{mediaUpload.files.length ? (mediaUpload.files.length + " added") : "No files added"}</p>
                                                        </div>
                                                        <MediaUpload
                                                            mediaUpload={mediaUpload}
                                                            projectId={deliverProjectId}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <label
                                                            htmlFor="file-upload"
                                                            className="cursor-pointer max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md group">
                                                            <div className="space-y-1 text-center">
                                                                <svg
                                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    viewBox="0 0 48 48"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                                <div
                                                                    className="flex justify-center text-sm text-gray-600">
                                                                    <div
                                                                        className="relative bg-white rounded-md font-medium text-indigo-600 group-hover:text-indigo-500"
                                                                    >
                                                                        <span>Click to upload files</span>
                                                                        <input id="file-upload"
                                                                               onChange={mediaUpload.onFiles}
                                                                               name="file-upload" type="file" multiple
                                                                               className="sr-only"/>
                                                                    </div>
                                                                    {/*<p className="pl-1">or drag and drop</p>*/}
                                                                </div>
                                                                <p className="text-xs text-gray-500">Any format up to
                                                                    250MB</p>
                                                            </div>
                                                        </label>

                                                        <p className="mt-2 text-sm text-gray-500">No files added</p>
                                                    </>
                                                )}

                                            </div>


                                        </div>
                                    </div>
                                )}

                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={isSubmitting || mediaUpload.isUploading}
                                    type="button"
                                    className={classNames(
                                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                        (isSubmitting || mediaUpload.isUploading) ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                    onClick={submitDelivery}
                                >
                                    {isSubmitting && <Spinner/>}
                                    {isSubmitting ? "Delivering..." : "Deliver"}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default DeliverProject
