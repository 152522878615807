import {XIcon} from "@heroicons/react/outline";
import React, {useEffect, useState} from "react";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {useDispatch, useSelector} from "react-redux";
import {showToast, TOAST_ALERT} from "../../store/toast";
import Spinner from "../spinner";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {createFile, fileSelector, getFile} from "../../store/files";
import {classNames} from "../../utils";
import MediaViewItem from "./media-view-item";

const storage = getStorage();

export default function MediaUploadItem({
                                            inputFile = {},
                                            index,
                                            defaultFileId,
                                            projectId,
                                            onUploaded,
                                            onRemove,
                                            dense = false
                                        }) {
    const dispatch = useDispatch()
    const [isUploading, setIsUploading] = useState(true)
    const [uploadTask, setUploadTask] = useState()
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadStateColor, setUploadStateColor] = useState('color-gray-100')
    const [filePreview, setFilePreview] = useState(null)
    const [isImage, setIsImage] = useState(false)
    const [success, setSuccess] = useState(false)
    const [fileId, setFileId] = useState(defaultFileId)
    const bucketFile = useSelector(fileSelector(fileId))

    useEffect(() => {
        return () => {
            if (uploadTask) {
                try {
                    setIsUploading(false)
                    setFileId(null)
                    setIsImage(false)
                    setFilePreview(null)
                    uploadTask.cancel()
                } catch (e) {

                }
            }
        }
    }, [])

    useEffect(() => {
        if (bucketFile?.thumbnailBigUrl) {
            setFilePreview(bucketFile.thumbnailBigUrl)
        }
    }, [bucketFile])

    useEffect(() => {
        if (fileId && !fileId.startsWith("https://")) {
            inputFile._fileId = fileId
            dispatch(getFile(fileId, true))
            setUploadStateColor("color-green-100")
            setSuccess(true)
            setIsUploading(false)
            onUploaded && onUploaded(inputFile, index)
        }
    }, [fileId])


    useEffect(() => {
        if (inputFile) {
            setIsUploading(true)
            if (inputFile.type.startsWith("image")) {
                const reader = new FileReader()
                reader.onloadend = (e) => {
                    setIsImage(true)
                    setFilePreview([reader.result])
                }
                reader.readAsDataURL(inputFile)
            }

            const now = Date.now(),
                fileName = inputFile.name,
                fileRef = ref(storage, `deliveries/${projectId || ("t" + now)}/${now}/${fileName}`), // /deliveries/{projectId}/{millis}/{fileName}
                uploadTask = uploadBytesResumable(fileRef, inputFile);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                    setUploadProgress(progress)
                    setUploadStateColor("color-gray-100")
                },
                (error) => {
                    // Handle unsuccessful uploads
                    setUploadStateColor('color-red-400')
                    setIsUploading(false)
                    dispatch(showToast({
                        text: "There was a problem uploading a file!",
                        type: TOAST_ALERT
                    }))
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                        dispatch(createFile(downloadURL, fileRef, fileName, doc => {
                            setFileId(doc.id)
                        }))
                    });
                }
            );
            setUploadTask(uploadTask)
        }
    }, [inputFile])

    return (
        <div className="relative group overflow-hidden">
            <MediaViewItem src={(!inputFile || isImage) && filePreview ? filePreview : null}/>
            {onRemove && (
                <div
                    className="opacity-0 absolute flex justify-end items-start inset-0 focus:outline-none group-hover:opacity-100">
                    <button type="button"
                            onClick={() => onRemove(inputFile, index)}
                            className={classNames(
                                "relative flex justify-center items-center rounded-full overflow-hidden m-1",
                                dense ? "h-6 w-6" : "h-8 w-8"
                            )}>
                        <span className="absolute inset-0 bg-black opacity-50"/>
                        <XIcon className={classNames(
                            "text-white z-1",
                            dense ? "h-4 w-4" : "h-5 w-5"
                        )}/>
                        <span className="sr-only">Remove</span>
                    </button>
                </div>
            )}
            {inputFile && (
                <div className={classNames(
                    "mt-2 flex items-center overflow-hidden",
                    dense ? "absolute bottom-0 left-0 right-0 rounded-b-lg p-2 text-gray-200" : "text-gray-900"
                )}>
                    {dense && (
                        <div className="absolute inset-0 bg-black opacity-75"/>
                    )}
                    <div className="z-10 flex items-center overflow-hidden">
                        <div className="inline-block mr-1">
                            {isUploading ? (
                                <Spinner tiny/>
                            ) : success ? (
                                <CheckCircleIcon className="text-green-400 w-4 h-4"/>
                            ) : null}
                        </div>
                        <p className={classNames(
                            "block font-medium truncate pointer-events-none",
                            dense ? "text-xs" : "text-sm"
                        )}>
                            {inputFile.name}
                        </p>
                    </div>
                </div>
            )}


            {/*<p className="ml-5 block text-sm font-medium text-gray-500 pointer-events-none">{prettyFileSize(inputFile.size)}</p>*/}
        </div>
    )
}
