import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {orderedProjectsSelector, removeProjectsListener, startProjectsListener} from "../store/projects";
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {beautifulStorageURL} from "../utils";
import {fileThumb, onErrorImageFallback} from "../components/utils";
import FileDetailItem from "../components/files/file-detail-item";

const tabs = [
    {name: 'Recently Added', href: '#', current: true},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Files = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(startProjectsListener())
        return () => {
            dispatch(removeProjectsListener())
        }
    }, [])

    const orderedProjects = useSelector(orderedProjectsSelector)
    const [projects, setProjects] = useState([])
    useEffect(() => {
        const ps = []
        for (let i = 0; i < orderedProjects.length; i++) {
            let p = {...orderedProjects[i]}
            // active, delivered, in-revision, completed
            if (p.deliverables && p.deliverables.attachments && p.deliverables.attachments.length > 0) {
                p._legacyAttachments = p.deliverables.attachments[0].startsWith("https://firebasestorage.googleapis.com/v0/b/paynco-test.appspot.com/")
                ps.push(p)
            }
        }
        if (ps.length - 1 < selectedIndex) setSelectedIndex(0)
        setProjects(ps)
    }, [orderedProjects])

    const [selectedIndex, setSelectedIndex] = useState(0)

    function onSelect(index) {
        return e => {
            setSelectedIndex(index || 0)
        }
    }

    //const [isDownloading, setIsDownloading] = useState("") // id of selectedIndex
    const downloadAll = async () => {
        const pro = projects[selectedIndex]
        //setIsDownloading(pro.id)

        const zip = new JSZip();
        let count = 0, urls = pro.deliverables.attachments,
            zipFilename = pro.title + '-attachments.zip'

        for (let i = 0; i < urls.length; i++) {
            let url = urls[i], filename = beautifulStorageURL(url)

            await JSZipUtils.getBinaryContent(url, async (err, data) => {
                if (err) {
                    throw err
                    //return
                }
                zip.file(filename, data, {binary: true});
                count++;
                if (count === urls.length) {
                    const zipFile = await zip.generateAsync({type: 'blob'});
                    saveAs(zipFile, zipFilename);
                }

            })
        }

        const zipFile = await zip.generateAsync({type: 'blob'});
        saveAs(zipFile, zipFilename);
    }


    function renderDetails(currentFile) {
        return (
            <aside className="hidden w-96 bg-white p-8 border-l border-gray-200 overflow-y-auto lg:block">
                <div className="pb-16 space-y-6">
                    <div>
                        <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                            {currentFile._legacyAttachments ? (
                                <img alt="" src={currentFile.deliverables.attachments[0]} onError={onErrorImageFallback}
                                     className="object-cover"/>
                            ) : (
                                <img alt="" src={fileThumb(currentFile.deliverables.attachments[0])}
                                     onError={onErrorImageFallback}
                                     className="object-cover"/>
                            )}
                        </div>
                        <div className="mt-4 flex items-start justify-between">
                            <div>
                                <h2 className="text-lg font-medium text-gray-900">
                                    <span className="sr-only">Details for </span>
                                    {currentFile.title}
                                </h2>
                                <p className="text-sm font-medium text-gray-500">{currentFile.description}</p>
                            </div>
                            {/*<button
                                type="button"
                                className="ml-4 bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                <HeartIcon className="h-6 w-6" aria-hidden="true"/>
                                <span className="sr-only">Favorite</span>
                            </button>*/}
                        </div>
                    </div>

                    <div>
                        <h3 className="font-medium text-gray-900">Description</h3>
                        <div className="mt-2 flex items-center justify-between">
                            <p className="text-sm text-gray-500 italic">{currentFile.deliverables.text}</p>
                            {/*<button
                                type="button"
                                className="bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                <PencilIcon className="h-5 w-5" aria-hidden="true"/>
                                <span className="sr-only">Add description</span>
                            </button>*/}
                        </div>
                    </div>
                    <div>
                        <h3 className="font-medium text-gray-900">Files</h3>
                        <dl className="mt-2 border-t border-gray-200 divide-y divide-gray-200">
                            {currentFile._legacyAttachments ?
                                currentFile.deliverables.attachments.map((a, i) => (
                                    <div key={i} className="py-3 flex justify-between text-sm font-medium">
                                        <dt className="text-gray-500">{beautifulStorageURL(a, i)}</dt>
                                        <dd className="text-gray-900"><a
                                            className="font-medium text-indigo-600 hover:text-indigo-500" href={a}
                                            target={a}>Download</a></dd>
                                    </div>
                                ))
                                :
                                currentFile.deliverables.attachments.map((a, i) => (
                                    <FileDetailItem key={i} fileId={a}/>
                                ))
                            }
                        </dl>
                    </div>
                    <div className="flex">
                        <button
                            type={"button"}
                            onClick={downloadAll}
                            className="flex-1 text-center bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Download all files
                        </button>
                    </div>
                </div>
            </aside>
        )
    }

    return (
        <div className="flex-1 flex flex-col overflow-hidden flex-grow">
            <div className="flex-1 flex items-stretch overflow-hidden">
                <main className="flex-1 overflow-y-auto">
                    <div className="pt-8 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex">
                            <h1 className="flex-1 text-2xl font-bold text-gray-900">Files</h1>
                            {/*<div className="ml-6 bg-gray-100 p-0.5 rounded-lg flex items-center sm:hidden">
                                <button
                                    type="button"
                                    className="p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                >
                                    <ViewListIcon className="h-5 w-5" aria-hidden="true"/>
                                    <span className="sr-only">Use list view</span>
                                </button>
                                <button
                                    type="button"
                                    className="ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                >
                                    <ViewGridIconSolid className="h-5 w-5" aria-hidden="true"/>
                                    <span className="sr-only">Use grid view</span>
                                </button>
                            </div>*/}
                        </div>

                        {/* Tabs */}
                        <div className="mt-3 sm:mt-2">
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>
                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue="Recently Viewed"
                                >
                                    <option>Recently Added</option>
                                </select>
                            </div>
                            <div className="hidden sm:block">
                                <div className="flex items-center border-b border-gray-200">
                                    <nav className="flex-1 -mb-px flex space-x-6 xl:space-x-8" aria-label="Tabs">
                                        {tabs.map((tab) => (
                                            <a
                                                key={tab.name}
                                                href={tab.href}
                                                aria-current={tab.current ? 'page' : undefined}
                                                className={classNames(
                                                    tab.current
                                                        ? 'border-indigo-500 text-indigo-600'
                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                                )}
                                            >
                                                {tab.name}
                                            </a>
                                        ))}
                                    </nav>
                                    {/*<div className="hidden ml-6 bg-gray-100 p-0.5 rounded-lg items-center sm:flex">
                                        <button
                                            type="button"
                                            className="p-1.5 rounded-md text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        >
                                            <ViewListIcon className="h-5 w-5" aria-hidden="true"/>
                                            <span className="sr-only">Use list view</span>
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-0.5 bg-white p-1.5 rounded-md shadow-sm text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        >
                                            <ViewGridIconSolid className="h-5 w-5" aria-hidden="true"/>
                                            <span className="sr-only">Use grid view</span>
                                        </button>
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                        {/* Gallery */}
                        <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                            <h2 id="gallery-heading" className="sr-only">
                                Recently viewed
                            </h2>
                            <ul
                                role="list"
                                className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                            >
                                {projects.map((file, i) => (
                                    <li key={file.id} onClick={onSelect(i)} className="relative">
                                        <div
                                            className={classNames(
                                                i === selectedIndex
                                                    ? 'ring-2 ring-offset-2 ring-indigo-500'
                                                    : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
                                                'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden'
                                            )}
                                        >


                                            {file._legacyAttachments ? (
                                                <img
                                                    src={file.deliverables.attachments[0]}
                                                    alt=""
                                                    onError={onErrorImageFallback}
                                                    className={classNames(
                                                        i === selectedIndex ? '' : 'group-hover:opacity-75',
                                                        'object-cover pointer-events-none'
                                                    )}
                                                />
                                            ) : (
                                                <img
                                                    src={fileThumb(file.deliverables.attachments[0])}
                                                    alt=""
                                                    onError={onErrorImageFallback}
                                                    className={classNames(
                                                        i === selectedIndex ? '' : 'group-hover:opacity-75',
                                                        'object-cover pointer-events-none'
                                                    )}
                                                />
                                            )}

                                            <button type="button" className="absolute inset-0 focus:outline-none">
                                                <span className="sr-only">View details for {file.title}</span>
                                            </button>
                                        </div>
                                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                                            {file.title}
                                        </p>
                                        <p className="block text-sm font-medium text-gray-500 pointer-events-none truncate">{file.deliverables.text}</p>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    </div>
                </main>

                {/* Details sidebar */}
                {projects[selectedIndex] && renderDetails(projects[selectedIndex])}
            </div>
        </div>

    )

}

export default Files
