import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getProfile, profileSelector} from "../store/profile";
import {onErrorProfileFallback, profilePhoto} from "./utils";
import {useIntervalWhen} from "rooks";
import {beautifulTimeReducer, classNames} from "../utils";


const AuthorSnippet = props => {
    const {id, text, hidePhoto, dense, cache = true, smDense, showLastSeen = false} = props
    const profile = useSelector(profileSelector(id)) || {}
    const dispatch = useDispatch()
    const [isOnline, setIsOnline] = useState(false)
    const [lastSeen, setLastSeen] = useState("")

    useEffect(() => {
        if (id) dispatch(getProfile(id))
    }, [id])

    function refreshStatus() {
        const _isOnline = profile.lastOnlineAt?.seconds * 1000 > Date.now() - 11500 || false
        setIsOnline(_isOnline)
        if (_isOnline) {
            setLastSeen("Active")
        } else {
            const _lastSeen = beautifulTimeReducer(profile.lastOnlineAt)
            setLastSeen(_lastSeen ? "Last seen " + _lastSeen + " ago" : "")
        }
    }

    useEffect(() => {
        refreshStatus()
    }, [profile])

    useIntervalWhen(() => {
        if (id) dispatch(getProfile(id, false, () => refreshStatus()))
    }, 10000, true, false)

    return (
        <div
            className="relative flex items-center space-x-3" title={profile.name}>
            <div className="flex-shrink-0 relative" hidden={hidePhoto === true}>
                <img className={((dense || smDense) ? "h-6 w-6" : "h-10 w-10") + " rounded-full"}
                     src={profilePhoto(id, cache)}
                     onError={onErrorProfileFallback} alt=""/>
                <span
                    className={classNames(
                        "absolute bottom-0 right-0 inline-block flex-shrink-0 rounded-full border-2 box-content border-white -mr-0.5 -mb-0.5",
                        isOnline ? "bg-green-400" : "bg-gray-200",
                        (dense || smDense) ? "h-2 w-2" : "h-3 w-3"
                    )}
                    aria-hidden="true"
                />
            </div>
            <div className="flex-1 min-w-0">
                <div className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true"/>
                    <p className="text-sm font-medium text-gray-900 line-clamp-1">{profile.name}</p>
                    <p className="text-xs sm:text-sm text-gray-500 truncate">{text || (showLastSeen ? lastSeen : "")}</p>
                </div>
            </div>
        </div>
    )
}

export default AuthorSnippet
