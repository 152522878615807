import React, {useEffect} from 'react'
import {InformationCircleIcon, SparklesIcon} from '@heroicons/react/solid'
import {useDispatch, useSelector} from "react-redux";
import {getProject, projectSelector} from "../../store/projects";
import MessageHeader from "./message-header";

const MessageDispute = props => {
    const {item, onActionClick, setReplyTo} = props
    const dispatch = useDispatch()

    const offer = useSelector(projectSelector(item.documentReference.id)) || {}

    useEffect(() => {
        dispatch(getProject(item.documentReference))
    }, [])

    function onViewClick() {
        onActionClick(item.documentReference)
    }

    function renderMsg() {
        switch (item.type) {
            case "DISPUTE_OPENED":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
            case "DISPUTE_ACCEPTED":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <div
                            className="bg-gray-50 text-gray-600 overflow-hidden rounded-md mt-4 pl-3 pr-4 py-3 italic text-sm flex flex-col">
                            <div className="flex-1 flex items-center">
                                <InformationCircleIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    aria-hidden="true"/>
                                <span
                                    className="ml-2 flex-1 w-0 font-medium">What happens now?</span>
                            </div>
                            <div className="flex-1 flex items-center">
                                <i className="flex-shrink-0 h-5 w-5"
                                   aria-hidden="true"/>
                                <p
                                    className="ml-2 flex-1 w-0">
                                    The project is now canceled and the money will be refunded to the buyer
                                    automatically.
                                </p>
                            </div>
                        </div>
                    </>
                )
            case "DISPUTE_REJECTED":
                return (
                    <>
                        {(offer && offer.title) && (
                            <div
                                className="bg-white border border-gray-300 overflow-hidden rounded-md mt-4">
                                <ul className="divide-y divide-gray-300">
                                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <div className="w-0 flex-1 flex items-center">
                                            <SparklesIcon
                                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate">{offer.title}</span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <button onClick={onViewClick}
                                                    type="button"
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                View
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <div
                            className="bg-gray-50 text-gray-600 overflow-hidden rounded-md mt-4 pl-3 pr-4 py-3 italic text-sm flex flex-col">
                            <div className="flex-1 flex items-center">
                                <InformationCircleIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    aria-hidden="true"/>
                                <span
                                    className="ml-2 flex-1 w-0 font-medium">What happens now?</span>
                            </div>
                            <div className="flex-1 flex items-center">
                                <i className="flex-shrink-0 h-5 w-5"
                                   aria-hidden="true"/>
                                <p
                                    className="ml-2 flex-1 w-0">
                                    To have a Paynco consultant mediate on your dispute please contact <a
                                    className="text-indigo-600 underline"
                                    href="mailto:support@paynco.com">support@paynco.com</a>.
                                </p>
                            </div>
                        </div>
                    </>
                )
        }
    }

    function headerText() {
        switch (item.type) {
            case "DISPUTE_OPENED":
                return "has opened a dispute"
            case "DISPUTE_ACCEPTED":
                return "has accepted the dispute request"
            case "DISPUTE_REJECTED":
                return "has rejected the dispute request"
        }
    }

    /*
            add 2 chat notifications : "xxx has opened a dispute" and
             "xxx has accepted the dispute request"
             [with explanation : The Project is now canceled and the money would be refunded to the buyer automatically "
             */
    /*
    Once you press reject on a dispute, the project should stay active : With a message :
    "xxx has rejected the dispute request" and an explanation :
    "To have a Paynco consultant mediate on your dispute please contact "support@paynco.com" "
     */

    // TODO: new message types: DISPUTE_OPENED, DISPUTE_ACCEPTED, DISPUTE_REJECTED

    return (
        <div className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
            <MessageHeader item={item} text={headerText()} setReplyTo={setReplyTo}/>
            <div
                className="mt-4 space-y-6 text-sm text-gray-800">
                {renderMsg()}
            </div>
        </div>
    )
}

export default MessageDispute
