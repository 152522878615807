import React, {useEffect} from "react";
import {removeTransactionsListener, startTransactionsListener, transactionsSelector} from "../../store/transactions";
import {useDispatch, useSelector} from "react-redux";
import {beautifulDateTime} from "../../utils";

const Transactions = () => {
    const dispatch = useDispatch()
    const transactions = useSelector(transactionsSelector)

    useEffect(() => {
        dispatch(startTransactionsListener())
        return () => {
            dispatch(removeTransactionsListener())
        }
    }, [])


    return (
        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
            <div className="mt-8">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/*<h2 className="text-lg leading-6 font-medium text-gray-900">Transactions History</h2>*/}
                    <div className="mt-2 bg-white overflow-hidden shadow rounded-lg">
                        {/* Tabs */}

                        <section aria-labelledby="billing-history-heading">
                            <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 sm:px-6">
                                    <h2 id="billing-history-heading"
                                        className="text-lg leading-6 font-medium text-gray-900">
                                        Billing history
                                    </h2>
                                </div>
                                <div className="mt-6 flex flex-col">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="overflow-hidden border-t border-gray-200">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Date
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Description
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Amount
                                                        </th>
                                                        {/*
                                `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                              */}
                                                        {/*<th
                                                            scope="col"
                                                            className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            <span className="sr-only">View receipt</span>
                                                        </th>*/}
                                                    </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                    {transactions.map((payment) => (
                                                        <tr key={payment.id}>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                {payment.createdAt &&
                                                                <time
                                                                    dateTime={payment.createdAt.toDate().toISOString()}>{beautifulDateTime(payment.createdAt, true)}</time>
                                                                }
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                {payment.description}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{payment.amount.formatted}</td>
                                                            {/*<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                <Link to={"transactions/" + payment.stripeTransferId}
                                                                   className="text-orange-600 hover:text-orange-900">
                                                                    View receipt
                                                                </Link>
                                                            </td>*/}
                                                        </tr>
                                                    ))}
                                                    {transactions.length === 0 && (
                                                        <tr>
                                                            <td colSpan="4" className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                                                                No transactions
                                                            </td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        </main>
    )

}

export default Transactions
