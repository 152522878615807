import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    active: null,
    isUsername: false,
};

const slice = createSlice({
    name: 'invitations',
    initialState,
    reducers: {
        setActiveInvitation: (state, action) => {
            state.active = action.payload.active
            state.isUsername = action.payload.isUsername
        },
    }
})

export default slice.reducer

// ACTIONS

const {setActiveInvitation} = slice.actions

export const setInvitation = (active = null, isUsername = false) => setActiveInvitation({active, isUsername})

// SELECTORS

export const activeInvitationSelector = state => state.invitations
