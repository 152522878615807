import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {Dialog, RadioGroup, Transition} from "@headlessui/react";
import {useDispatch} from "react-redux";
import {getFunctions, httpsCallable} from "firebase/functions";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Spinner from "../components/spinner";
import {startProject} from "../store/projects";
import currency from "currency.js";
import {showToast, TOAST_ALERT} from "../store/toast";
import {buyerFeeFactor, buyerFeeFixedAmount, region} from "../config";
import {QuestionMarkCircleIcon} from "@heroicons/react/solid";

const functions = getFunctions()

functions.region = region

const useOptions = () => {
    const options = useMemo(() => ({
        style: {
            base: {
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#9e2146"
            }
        }
    }), []);

    return options;
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function setupIntent() {
    const createCustomerSetupIntent = httpsCallable(functions, "createCustomerSetupIntent")
    return createCustomerSetupIntent()
}

function listCustomerCards() {
    return httpsCallable(functions, "listCustomerCards")()
}

const StartProject = props => {
    const {offer, open, setOpen, setSuccessful} = props;
    const cancelButtonRef = useRef()

    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)
    const [isConfirmingCard, setIsConfirmingCard] = useState(false)
    const [setupIntentClientSecret, setSetupIntentClientSecret] = useState()
    const [cards, setCards] = useState([])
    const [selectedCard, setSelectedCard] = useState(null)

    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(() => {
        if (open) {
            setIsSubmitting(false)
            setSelectedCard(null)
        }
    }, [open])

    function refreshCards() {
        setIsLoading(true)
        listCustomerCards().then(({data}) => {
            setIsLoading(false)
            if (data && data.stripeResult) {
                setCards(data.stripeResult.data || [])
            }
        }).catch(err => {
            console.error(err)
            setOpen(false)
            dispatch(showToast({
                text: "There was an error fetching data. Please try again later.",
                type: TOAST_ALERT
            }))
        })
    }

    useEffect(() => {
        if (open) {
            refreshCards()
            setupIntent().then(({data}) => {
                setSetupIntentClientSecret(data.client_secret)
            }).catch(err => {
                console.error(err, JSON.stringify(err))
                setOpen(false)
                dispatch(showToast({
                    text: "There was an error fetching data. Please try again later.",
                    type: TOAST_ALERT
                }))
            })
        }
    }, [open])

    const [subtotal, setSubtotal] = useState("")
    const [total, setTotal] = useState("")
    const [fee, setFee] = useState("")
    const [feeText, setFeeText] = useState("")
    useEffect(() => {
        if (offer) {
            let _subtotal = currency(offer.price, {symbol: "$"}),
                _total = _subtotal.multiply(buyerFeeFactor).add(buyerFeeFixedAmount),
                _fee = _total.subtract(_subtotal),
                _feeText = "Service Fee (4,4% + 50c)"

            /*if (_fee.intValue < 100) {
                // minimal fee is 1$
                _fee = currency(1, {symbol: '$', precision: 2})
                _total = _subtotal.add(_fee)
                _feeText = "Service Fee"
            }*/

            setSubtotal(_subtotal.format())
            setTotal(_total.format())
            setFee(_fee.format())
            setFeeText(_feeText)
        }
    }, [offer])

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    async function onConfirmCard(event) {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement(CardElement)

        setIsConfirmingCard(true)

        const result = await stripe.confirmCardSetup(setupIntentClientSecret, {
            payment_method: {
                card
            }
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
        })

        setIsConfirmingCard(false)
        refreshCards()
    }

    function onStartProject(e) {
        setIsSubmitting(true)
        dispatch(startProject(offer.id, selectedCard.id, err => {
            setIsSubmitting(false)
            setOpen(false)
            if (err) dispatch(showToast({
                text: "There was an error submitting your request. Please try again later.",
                type: TOAST_ALERT
            }))
            else setSuccessful(true)
        }))
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div
                    className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">

                            {!isLoading ? (
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Start Project
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Start project by paying into the project fund.
                                            </p>
                                        </div>
                                    </div>
                                    {offer && !!setupIntentClientSecret && (
                                        <div className="sm:mx-4 mt-8">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-2">
                                                    <h3 className="font-medium text-gray-900">Pricing strategy</h3>
                                                    <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                                                        <div className="py-3 flex justify-between text-sm font-medium">
                                                            <dt className="text-gray-500">Subtotal</dt>
                                                            <dd className="text-gray-700">{subtotal}</dd>
                                                        </div>
                                                        <div className="py-3 flex justify-between text-sm font-medium">
                                                            <dt className="text-gray-500 inline-flex items-center">{feeText}
                                                                <QuestionMarkCircleIcon
                                                                    className="w-4 h-4 inline ml-1"/></dt>
                                                            <dd className="text-gray-700">{fee}</dd>
                                                        </div>
                                                        <div className="py-3 flex justify-between font-medium">
                                                            <dt className="text-gray-900 font-bold">Total</dt>
                                                            <dd className="text-gray-900 font-bold">{total}</dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <div className="mb-2">
                                                        <h3 className="font-medium text-gray-900">Payment methods</h3>
                                                    </div>
                                                    <RadioGroup value={selectedCard}
                                                                onChange={e => !isSubmitting && setSelectedCard(e)}>
                                                        <RadioGroup.Label className="sr-only">Cards</RadioGroup.Label>
                                                        <div className="relative bg-white rounded-md -space-y-px">
                                                            {cards.map((plan, planIdx) => (
                                                                <RadioGroup.Option
                                                                    key={plan.id}
                                                                    value={plan}
                                                                    className={({checked}) =>
                                                                        classNames(
                                                                            planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                                            planIdx === cards.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                                            checked ? 'bg-orange-50 border-orange-200 z-10' : 'border-gray-200',
                                                                            'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                                                                        )
                                                                    }
                                                                >
                                                                    {({active, checked}) => (
                                                                        <>
                                                                            <div className="flex items-center text-sm">
                                                                                  <span
                                                                                      className={classNames(
                                                                                          checked ? 'bg-indigo-500 border-transparent' : 'bg-white border-gray-300',
                                                                                          active ? 'ring-2 ring-offset-2 ring-gray-900' : '',
                                                                                          'h-4 w-4 rounded-full border flex items-center justify-center'
                                                                                      )}
                                                                                      aria-hidden="true"
                                                                                  >
                                                                                    <span
                                                                                        className="rounded-full bg-white w-1.5 h-1.5"/>
                                                                                  </span>
                                                                                <RadioGroup.Label as="span"
                                                                                                  className="capitalize ml-3 font-medium text-gray-900">
                                                                                    {plan.card.brand}
                                                                                </RadioGroup.Label>
                                                                            </div>
                                                                            <RadioGroup.Description
                                                                                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                                                                              <span
                                                                                  className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium')}
                                                                              >
                                                                                    **** {plan.card.last4}
                                                                              </span>
                                                                            </RadioGroup.Description>
                                                                            <RadioGroup.Description
                                                                                className={classNames(
                                                                                    checked ? 'text-orange-700' : 'text-gray-500',
                                                                                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                                                                                )}
                                                                            >
                                                                                {plan.card.exp_month}/{plan.card.exp_year}
                                                                            </RadioGroup.Description>
                                                                        </>
                                                                    )}
                                                                </RadioGroup.Option>
                                                            ))}
                                                            {cards.length === 0 && (
                                                                <span>No existing cards</span>
                                                            )}
                                                        </div>
                                                    </RadioGroup>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <form
                                                        className="flex flex-col p-4 bg-gray-100 hover:bg-gray-200 rounded-lg"
                                                        onSubmit={onConfirmCard}>
                                                        <span className="pb-4">Add new card</span>
                                                        <label className="">
                                                            <CardElement
                                                                options={options}
                                                                onReady={() => {
                                                                    console.log("CardElement [ready]");
                                                                }}
                                                                onChange={event => {
                                                                    console.log("CardElement [change]", event);
                                                                }}
                                                                onBlur={() => {
                                                                    console.log("CardElement [blur]");
                                                                }}
                                                                onFocus={() => {
                                                                    console.log("CardElement [focus]");
                                                                }}
                                                            />
                                                        </label>
                                                        <div className="pt-4 flex sm:justify-end">
                                                            <button
                                                                className={classNames(
                                                                    "w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                                                                    isConfirmingCard ? "text-gray-200" : ""
                                                                )}
                                                                type="submit"
                                                                disabled={!stripe || isConfirmingCard || isSubmitting}>
                                                                {isConfirmingCard && <Spinner/>}
                                                                Confirm
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </dl>
                                        </div>
                                    )}

                                </div>
                            ) : (
                                <div className="bg-white flex justify-center px-4 py-6">
                                    <Spinner big/>
                                </div>
                            )}

                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={!selectedCard || isSubmitting}
                                    type="button"
                                    className={classNames(
                                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                        (!selectedCard || isSubmitting) ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                    onClick={onStartProject}
                                >
                                    {isSubmitting && <Spinner/>}
                                    {isSubmitting ? "Starting project..." : "Pay to Start"}
                                </button>

                                <button
                                    disabled={isSubmitting}
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default StartProject
