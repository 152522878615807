import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getProject, projectSelector} from "../store/projects";


const ProjectText = props => {
    const {id, fallback = ""} = props
    const project = useSelector(projectSelector(id)) || {}
    const dispatch = useDispatch()

    useEffect(() => {
        if (id) dispatch(getProject(id))
    }, [id])

    return project.title || fallback
}

export default ProjectText
