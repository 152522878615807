import {createSlice} from "@reduxjs/toolkit";

import {getAuth} from 'firebase/auth';
import {collection, getFirestore, limit, onSnapshot, orderBy, query, where} from 'firebase/firestore';

const auth = getAuth()
const firestore = getFirestore()

const initialState = {
    ordered: [],
    listener: null,
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setOrdered: (state, action) => {
            state.ordered = action.payload.ordered
            state.listener = action.payload.listener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setOrdered} = slice.actions

export const startNotificationsListener = () => async (dispatch, getState) => {
    const uid = auth.currentUser.uid,
        state = getState(),
        _query = query(collection(firestore, "notifications"), where("uid", "==", uid), orderBy("timestamp", "desc"), limit(5))

    if (state.notifications.listener) state.notifications.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        dispatch(setOrdered({listener, ordered: snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))}))
    })
}

export const removeNotificationsListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.notifications.listener) state.notifications.listener() // unsubscribe
}

// SELECTORS

export const notificationsSelector = state => state.notifications.ordered
