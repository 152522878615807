import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from 'firebase/auth';

import {
    collection,
    doc,
    getFirestore,
    limit,
    onSnapshot,
    query,
    serverTimestamp,
    setDoc,
    where
} from 'firebase/firestore';

import {getFunctions} from "firebase/functions";
import {region} from "../config";

const auth = getAuth()
const firestore = getFirestore(),
    functions = getFunctions()

functions.region = region

const initialState = {
    listener: null,
};

const slice = createSlice({
    name: 'typingIndicator',
    initialState,
    reducers: {
        updateIndicator: (state, action) => {
            state[action.payload.conversationId] = {...state[action.payload.conversationId], [action.payload.uid]: action.payload.timestamp}
        },
        setListener: (state, action) => {
            state.listener = action.payload
        },
    }
})

export default slice.reducer

// ACTIONS

const {setListener, updateIndicator} = slice.actions

export const startTypingIndicatorListener = (conversationId, members = []) => async (dispatch, getState) => {
    if(!members && members.length === 0) return
    const state = getState(),
        _query = query(collection(firestore, "conversations", conversationId, "indicators"), where("uid", "in", members), limit(members.length))

    if (state.typingIndicator.listener) state.typingIndicator.listener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        snapshot.forEach(doc => {
            dispatch(updateIndicator({
                conversationId,
                ...doc.data()
            }))
        })
    })
    dispatch(setListener(listener))
}

export const removeTypingIndicatorListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.typingIndicator.listener) state.typingIndicator.listener() // unsubscribe
    dispatch(setListener(null))
}

export const updateTypingIndicator = conversationId => async () => {
    const uid = auth.currentUser.uid
    if (!conversationId || !uid) return
    const data = {
        uid,
        timestamp: serverTimestamp()
    }
    setDoc(doc(firestore, "conversations", conversationId, "indicators", uid), data).then(() => {
    }).catch(err => {
        console.error(err)
    })
}


// SELECTORS
export const typingIndicatorSelector = conversationId => state => state.typingIndicator[conversationId]
