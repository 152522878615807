import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from 'firebase/auth';
import {addDoc, collection, doc, getDoc, getFirestore, onSnapshot, serverTimestamp} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import JSZip from "jszip";
import {saveAs} from 'file-saver';
import {showToast, TOAST_WARNING} from "./toast";

const auth = getAuth(),
    firestore = getFirestore(),
    storage = getStorage()


const initialState = {
    listeners: {},
};

const slice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setListener: (state, action) => {
            state.listeners[action.payload.id] = action.payload.listener
        },
        setFile: (state, action) => {
            state[action.payload.id] = action.payload
        },
    }
})

export default slice.reducer

// ACTIONS

const {setFile, setListener} = slice.actions

const uniqueName = (nameMap, filename, _addCount = 0) => {
    let modifiedFilename = filename
    if (_addCount > 0) modifiedFilename = "(" + _addCount + ") " + filename;
    if (nameMap.hasOwnProperty(modifiedFilename)) return uniqueName(nameMap, filename, _addCount + 1)
    nameMap[modifiedFilename] = true
    return modifiedFilename
}

export const downloadFile = (fileId, cb) => async (dispatch, getState) => {
    if (fileId.startsWith("https://")) {
        let name = fileId.split("/")
        name = name[name.length - 1]
        name = name.split("%2F")
        name = name[name.length - 1]
        name = name.split("?")[0]
        fetch(fileId).then(res => res.blob()).then(blob => {
            saveAs(blob, name)
            cb && cb(true)
        })
    } else {
        const state = getState(),
            f = state.files[fileId]

        let file

        if (!f?.bucketFilePath) file = await getDoc(doc(firestore, "files", fileId))
            .then(doc => doc.data())

        if (!f?.bucketFilePath) {
            dispatch(showToast({
                text: "There was an error preparing your download!",
                type: TOAST_WARNING
            }))
            cb && cb(false, true)
            return
        }

        file = ref(storage, f.bucketFilePath)
        await getDownloadURL(file)
            .then(url => fetch(url))
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, file.name)
                cb && cb(true)
            })
            .catch(err => {
                console.error(err)
                dispatch(showToast({
                    text: "There was an error preparing your download!",
                    type: TOAST_WARNING
                }))
                cb && cb(false, err)
            })
    }
}

export const fetchFile = (file, url) => {
    console.log("downloading", file, url)
    return fetch(url)
        .then(res => res.blob())
        .then(blob => [file, blob])
}

export const downloadFiles = (fileIds, cb) => async (dispatch) => {
    const ps = []

    for (let i = 0; i < fileIds.length; i++) {
        let fileId = fileIds[i]
        if (!fileId || typeof fileId !== "string") continue

        if (fileId.startsWith("https://")) {
            const filePathSplit = new URL(fileId).pathname.split("/")
            ps.push(fetchFile({name: filePathSplit[filePathSplit.length - 1]}), fileId)
        } else {
            ps.push(getDoc(doc(firestore, "files", fileIds[i])).then(doc => {
                if (doc.exists()) {
                    const bucketFilePath = doc.get("bucketFilePath")
                    if (bucketFilePath) {
                        const file = ref(storage, bucketFilePath)
                        return getDownloadURL(file)
                            .then(url => fetchFile(file, url))
                    }
                }
            }))
        }
    }

    await Promise.all(ps).then(results => {
        const zip = new JSZip(),
            nameMap = {}

        for (let i = 0; i < results.length; i++) {
            const [{name}, blob] = results[i]
            let filename = uniqueName(nameMap, name)
            zip.file(filename, blob, {base64: true})
        }

        return zip.generateAsync({type: "blob"}).then(content => {
            saveAs(content, "project_files.zip")
            cb && cb()
        })
    }).catch(err => {
        console.error(err)
        dispatch(showToast({
            text: "There was an error preparing your download!",
            type: TOAST_WARNING
        }))
        cb && cb()
    })
}

export const getFile = (fileId, listen = false) => async (dispatch, getState) => {
    const fileDoc = doc(firestore, "files", fileId);
    if (listen) {
        const state = getState()

        if (state.files.listeners[fileId]) state.files.listeners[fileId]() // unsubscribe

        const listener = onSnapshot(fileDoc, doc => {
            dispatch(setFile({...doc.data(), id: doc.id}))
        })
        dispatch(setListener({id: fileDoc.id, listener}));
    } else {
        await getDoc(fileDoc).then(doc => {
            if (doc.exists) dispatch(setFile({...doc.data(), id: doc.id}))
        })
    }
}

export const createFile = (downloadUrl, fileRef, fileName, cb) => async () => {
    const uid = auth.currentUser.uid
    const data = {
        createdAt: serverTimestamp(),
        createdBy: uid,
        downloadUrl,
        fileName,
        bucketFilePath: fileRef.fullPath
    }

    const docRef = await addDoc(collection(firestore, "files"), data)
    cb && cb(docRef)
}


export const removeFileListener = (fileId) => async (_, getState) => {
    const state = getState()
    if (state.files.listeners[fileId]) state.files.listeners[fileId]() // unsubscribe
}

// SELECTORS

export const fileSelector = id => state => state.files[id]
