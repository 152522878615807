import React from 'react'
import AuthorText from "../../components/author-text";
import {XIcon} from "@heroicons/react/solid";
import {sanitizeMessage} from "../../utils";


const QuotedMessage = props => {
    const {data, setReplyTo} = props

    return (
        <div>
            {data && (
                <div className="pb-2 pt-3">
                    <div className="flex justify-between items-start">
                        <span className="text-sm">
                            Replying to <b className="font-medium"><AuthorText id={data.uid}/></b>
                        </span>
                        <button
                            onClick={() => setReplyTo(null)}
                            type="button"
                            className="-my-2 p-2 rounded-full bg-white flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-600">
                            <span className="sr-only">Cancel reply-to</span>
                            <XIcon className="h-4 h-5"
                                   aria-hidden="true"/>
                        </button>

                    </div>
                    <div
                        className="text-xs text-gray-800 pr-10">
                        <p className="whitespace-pre-line line-clamp-1"
                           dangerouslySetInnerHTML={{__html: sanitizeMessage(data.text)}}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default QuotedMessage
