import {createSlice} from "@reduxjs/toolkit";

import {getAuth} from 'firebase/auth';
import {collection, doc, getFirestore, onSnapshot, orderBy, query, updateDoc, where} from 'firebase/firestore';

const auth = getAuth()
const firestore = getFirestore()

const initialState = {
    ordered: [],
    listener: null,
    seen: {},
    seenListener: null,
};

const slice = createSlice({
    name: 'conversations',
    initialState,
    reducers: {
        setOrdered: (state, action) => {
            state.ordered = action.payload.ordered
            state.listener = action.payload.listener
        },
        reduceSeen: (state, action) => {
            state.seen = action.payload.seen
            state.seenListener = action.payload.seenListener
        },
    }
})

export default slice.reducer

// ACTIONS

const {setOrdered, reduceSeen} = slice.actions

export const startConversationsListener = () => async (dispatch, getState) => {
    const uid = auth.currentUser.uid,
        state = getState(),
        _query = query(collection(firestore, "conversations"), where("members", "array-contains-any", [uid]), orderBy("updatedAt", "desc")),
        _querySeen = query(collection(firestore, "conversation-notifications"), where("uid", "==", uid), where("seen", "==", false), orderBy("timestamp", "desc"))

    if (state.conversations.listener) state.conversations.listener() // unsubscribe
    if (state.conversations.seenListener) state.conversations.seenListener() // unsubscribe

    const listener = onSnapshot(_query, snapshot => {
        dispatch(setOrdered({listener, ordered: snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))}))
    })
    const seenListener = onSnapshot(_querySeen, snapshot => {

        dispatch(reduceSeen({
            seenListener,
            seen: snapshot.docs.reduce((ac, a) => ({...ac, [a.get("conversationId")]: a.data()}), {})
        }))
    })
}

export const removeConversationsListener = () => async (dispatch, getState) => {
    const state = getState()
    if (state.conversations.listener) state.conversations.listener() // unsubscribe
    if (state.conversations.seenListener) state.conversations.seenListener() // unsubscribe
}

export const setSeen = conversationId => async (dispatch, getState) => {
    const uid = auth.currentUser.uid

    updateDoc(doc(firestore, "conversation-notifications", [conversationId, uid].join("_")), {
        seen: true
    })

}

// SELECTORS

export const orderedConversationsSelector = state => state.conversations.ordered
export const seenConversationsSelector = state => state.conversations.seen
