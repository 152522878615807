import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {beautifulDateTime, classNames, projectStatusColorClasses} from "../utils";
import {getOffer, unconnectedOfferSelector} from "../store/offers";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../components/spinner";
import {connectedAccountSelector, getConnectedAccount} from "../store/user";
import PayoutWarning from "./payout-warning";
import {CalendarIcon, CurrencyDollarIcon, ExclamationIcon} from "@heroicons/react/solid";
import AuthorSnippet from "../components/author-snippet";
import AuthorText from "../components/author-text";
import currency from "currency.js";
import {getBankAccountCountry} from "../resources/countries";


const PreviewOffer = props => {
    const {offerRef, user, open, setOpen, onCancel, onAccept, onReject, onStart} = props;
    const cancelButtonRef = useRef()

    const dispatch = useDispatch()
    const offer = useSelector(unconnectedOfferSelector(offerRef.id))

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [rejectAction, setRejectAction] = useState(false)
    const connectedAccount = useSelector(connectedAccountSelector)
    const [showingWarning, setShowingWarning] = useState(false)
    const [doSubmit, setDoSubmit] = useState(false)

    const [countryName, setCountryName] = useState("")
    const [isCountrySupported, setIsCountrySupported] = useState(false)

    useEffect(() => {
        setIsSubmitting(false)
        setShowingWarning(false)
        setDoSubmit(false)
        if (open) {
            dispatch(getConnectedAccount())
        }
    }, [open])

    useEffect(() => {
        if (offerRef) dispatch(getOffer(offerRef))
    }, [offerRef])

    useEffect(() => {
        const country = getBankAccountCountry(user?.country)
        setCountryName((country ? country[1] : "") || "")
        setIsCountrySupported(offer?.seller === user.uid && offer.status === "pending" ? ((country ? country[3] : false) || false) : true)
    }, [user?.country, offer])

    function submitAction() {
        callAction(onAccept)()
    }

    useEffect(() => {
        if (doSubmit) {
            let showWarning = true

            try {
                showWarning = !connectedAccount || !connectedAccount.payouts_enabled || (connectedAccount.requirements.currently_due.indexOf("individual.verification.additional_document") !== -1 || connectedAccount.requirements.currently_due.indexOf("individual.verification.document") !== -1)
            } catch (e) {

            }

            if (showWarning) {
                setShowingWarning(true)
            } else {
                submitAction()
            }
        }
    }, [isSubmitting, connectedAccount, doSubmit])

    function payoutWarningCheck() {
        return () => {
            //console.log("HERE")

            if (offer && offer.seller === user.uid) {
                setDoSubmit(true)
            } else {
                submitAction()
            }
        }
    }

    function callAction(action, isReject = false) {
        return () => {
            setRejectAction(isReject)
            setIsSubmitting(true)
            action(() => {
                setIsSubmitting(false)
                setRejectAction(false)
                setOpen(false)
            })
        }
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed z-10 inset-0 overflow-y-auto"
                    initialFocus={cancelButtonRef}
                    open={open}
                    onClose={setOpen}
                >
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    {offer && (
                                        <div
                                            className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 xl:max-w-5xl xl:grid xl:grid-cols-3 my-5">
                                            <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
                                                <div>
                                                    <div>
                                                        <div
                                                            className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                                                            <div>
                                                                <h1 className="text-2xl font-bold text-gray-900">
                                                                    {offer.title}
                                                                </h1>
                                                                <p className="mt-2 text-sm text-gray-500">
                                                                    Created by{' '}
                                                                    <span className="font-medium text-gray-900">
                                                                        <AuthorText id={offer.createdBy}/>
                                                                    </span>{' '}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <aside className="mt-8 xl:hidden">
                                                            <h2 className="sr-only">Details</h2>
                                                            <div className="space-y-5">
                                                                <div className="flex items-center space-x-2">
                                                                    <CalendarIcon className="h-5 w-5 text-gray-400"
                                                                                  aria-hidden="true"/>
                                                                    <span className="text-gray-900 text-sm font-medium">
                      Created at <time dateTime={offer.dueDate.toDate().toISOString()}>{beautifulDateTime(offer.createdAt)}</time>
                    </span>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    <CalendarIcon className="h-5 w-5 text-gray-400"
                                                                                  aria-hidden="true"/>
                                                                    <span className="text-gray-900 text-sm font-medium">
                      Due at <time dateTime={offer.dueDate.toDate().toISOString()}>{beautifulDateTime(offer.dueDate)}</time>
                    </span>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    <CurrencyDollarIcon
                                                                        className="h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"/>
                                                                    <span
                                                                        className="text-gray-900 text-sm font-medium">{currency(offer.price, {symbol: "$"}).format()}</span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="mt-6 border-t border-b border-gray-200 py-6 space-y-8">
                                                                <div>
                                                                    <h2 className="text-sm font-medium text-gray-500">Buyer</h2>
                                                                    <ul role="list" className="mt-3 space-y-3">
                                                                        <li className="flex justify-start">
                                                                            <div
                                                                                className="flex items-center space-x-3">
                                                                                <AuthorSnippet id={offer.buyer} dense/>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <h2 className="text-sm font-medium text-gray-500">Seller</h2>
                                                                    <ul role="list" className="mt-3 space-y-3">
                                                                        <li className="flex justify-start">
                                                                            <div
                                                                                className="flex items-center space-x-3">
                                                                                <AuthorSnippet id={offer.seller} dense/>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <h2 className="text-sm font-medium text-gray-500">Status</h2>
                                                                    <ul role="list" className="mt-2 leading-8">
                                                                        <li className="inline">
                                                                            <div
                                                                                className={classNames(
                                                                                    "relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5",
                                                                                    projectStatusColorClasses(offer.status)
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className="text-sm font-medium text-gray-900 capitalize">{offer.status}</div>
                                                                            </div>
                                                                            {' '}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </aside>
                                                        <div className="py-3 xl:pt-6 xl:pb-0">
                                                            <h2 className="sr-only">Description</h2>
                                                            <p className="prose max-w-none whitespace-pre-line">
                                                                {offer.description}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <aside className="hidden xl:block xl:pl-8">
                                                <h2 className="sr-only">Details</h2>
                                                <div className="space-y-5">
                                                    <div className="flex items-center space-x-2">
                                                        <CalendarIcon className="h-5 w-5 text-gray-400"
                                                                      aria-hidden="true"/>
                                                        <span className="text-gray-900 text-sm font-medium">
                      Created at <time dateTime={offer.createdAt.toDate().toISOString()}>{beautifulDateTime(offer.createdAt)}</time>
                    </span>
                                                    </div>
                                                    <div className="flex items-center space-x-2">
                                                        <CalendarIcon className="h-5 w-5 text-gray-400"
                                                                      aria-hidden="true"/>
                                                        <span className="text-gray-900 text-sm font-medium">
                      Due at <time dateTime={offer.dueDate.toDate().toISOString()}>{beautifulDateTime(offer.dueDate)}</time>
                    </span>
                                                    </div>
                                                    <div className="flex items-center space-x-2">
                                                        <CurrencyDollarIcon className="h-5 w-5 text-gray-400"
                                                                            aria-hidden="true"/>
                                                        <span
                                                            className="text-gray-900 text-sm font-medium">{currency(offer.price, {symbol: "$"}).format()}</span>
                                                    </div>
                                                </div>
                                                <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
                                                    <div>
                                                        <h2 className="text-sm font-medium text-gray-500">Buyer</h2>
                                                        <ul role="list" className="mt-3 space-y-3">
                                                            <li className="flex justify-start">
                                                                <div className="flex items-center space-x-3">
                                                                    <AuthorSnippet id={offer.buyer} dense/>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h2 className="text-sm font-medium text-gray-500">Seller</h2>
                                                        <ul role="list" className="mt-3 space-y-3">
                                                            <li className="flex justify-start">
                                                                <div className="flex items-center space-x-3">
                                                                    <AuthorSnippet id={offer.seller} dense/>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h2 className="text-sm font-medium text-gray-500">Status</h2>
                                                        <ul role="list" className="mt-2 leading-8">
                                                            <li className="inline">
                                                                <div
                                                                    className={classNames(
                                                                        "relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5",
                                                                        projectStatusColorClasses(offer.status)
                                                                    )}
                                                                >
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900 capitalize">{offer.status}</div>
                                                                </div>
                                                                {' '}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </aside>
                                        </div>

                                        /*<div className="my-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">Title</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{offer.title}</dd>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">Due Date</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{beautifulDateTime(offer.dueDate, true)}</dd>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">Price</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{offer.price.toFixed(2)} USD</dd>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">About</dt>
                                                    <dd
                                                        className="mt-1 max-w-prose text-sm text-gray-900 space-y-5"
                                                        dangerouslySetInnerHTML={{__html: offer.description}}
                                                    />
                                                </div>
                                                {offer.deliverables && (
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">About</dt>
                                                        <dd
                                                            className="mt-1 max-w-prose text-sm text-gray-900 space-y-5"
                                                            dangerouslySetInnerHTML={{__html: offer.description}}
                                                        />
                                                    </div>
                                                )}

                                            </dl>
                                        </div>*/

                                    )}
                                </div>

                                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
                                    <div className="sm:flex sm:flex-row-reverse">
                                        {offer && offer.status === "pending" && (
                                            offer.createdBy === user.uid ? (
                                                <>
                                                    <button
                                                        disabled={isSubmitting}
                                                        type="button"
                                                        className={classNames(
                                                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                                            isSubmitting ? "bg-gray-300" : "bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                        )}
                                                        onClick={callAction(onCancel, true)}
                                                    >
                                                        {isSubmitting && rejectAction && <Spinner/>}
                                                        {(isSubmitting && rejectAction) ? "Canceling offer..." : "Cancel offer"}
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        disabled={isSubmitting || !isCountrySupported}
                                                        type="button"
                                                        className={classNames(
                                                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                                            (isSubmitting || !isCountrySupported) ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        )}
                                                        onClick={payoutWarningCheck()}
                                                    >
                                                        {isSubmitting && !rejectAction && <Spinner/>}
                                                        {(isSubmitting && !rejectAction) ? "Accepting offer..." : "Accept offer"}
                                                    </button>
                                                    <button
                                                        disabled={isSubmitting}
                                                        type="button"
                                                        className={classNames(
                                                            "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                                                            isSubmitting ? "bg-gray-300 cursor-default" : "bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500"
                                                        )}
                                                        onClick={callAction(onReject, true)}
                                                    >
                                                        {isSubmitting && rejectAction && <Spinner/>}
                                                        {(isSubmitting && rejectAction) ? "Rejecting offer..." : "Reject offer"}
                                                    </button>
                                                </>
                                            )
                                        )}
                                        {offer && offer.status === "accepted" && offer.buyer === user.uid && (
                                            <button
                                                disabled={isSubmitting}
                                                type="button"
                                                className={classNames(
                                                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                                    isSubmitting ? "bg-gray-300 cursor-default" : "bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                )}
                                                onClick={callAction(onStart)}
                                            >
                                                {isSubmitting && !rejectAction && <Spinner/>}
                                                {(isSubmitting && !rejectAction) ? "Starting project..." : "Start project"}
                                            </button>
                                        )}
                                        {offer && offer.status === "accepted" && (
                                            <button
                                                disabled={isSubmitting}
                                                type="button"
                                                className={classNames(
                                                    "mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                                                    isSubmitting ? "bg-gray-300 cursor-default" : "bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500"
                                                )}
                                                onClick={callAction(onCancel, true)}
                                            >
                                                {isSubmitting && rejectAction && <Spinner/>}
                                                {(isSubmitting && rejectAction) ? "Canceling offer..." : "Cancel offer"}
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    {!isCountrySupported && (
                                        <p className="mt-2 text-xs text-orange-500 flex items-center justify-end">
                                            <ExclamationIcon className="h-4 w-4 mr-1"/>
                                            This is not yet available in {countryName}.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <PayoutWarning open={showingWarning} setOpen={setShowingWarning} continueAnyway={submitAction}/>
        </>
    )
}

export default PreviewOffer
