import {createSlice} from "@reduxjs/toolkit";
import {getAuth} from 'firebase/auth';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore';

const auth = getAuth()
const firestore = getFirestore()

const initialState = {
    usernameToIdMap: {},
    '': null
};

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            const data = (action.payload.data() || {})
            state[action.payload.id] = {...data, id: action.payload.id}
            state.usernameToIdMap[data.lowerUsername] = action.payload.id
        },
    }
})

export default slice.reducer

// ACTIONS

const {setProfile} = slice.actions

export const getCurrentProfile = () => async (dispatch) => {
    if (!auth.currentUser) return
    getDoc(doc(firestore, "profile", auth.currentUser.uid)).then(doc => dispatch(setProfile(doc)))
}

export const getProfile = (id, isUsername = false, cb) => async (dispatch) => {
    if (isUsername) getDocs(query(collection(firestore, "profile"), where("lowerUsername", "==", id.toLowerCase()), limit(1)))
        .then(snap => snap.forEach(doc => dispatch(setProfile(doc))))
    else getDoc(doc(firestore, "profile", id)).then(doc => {
        dispatch(setProfile(doc))
        cb && cb()
    })
}

export const updateProfile = data => async (dispatch) => {
    const id = auth.currentUser.uid
    setDoc(doc(firestore, "profile", id), {
        ...data,
        updatedAt: serverTimestamp()
    }, {merge: true}).then(() => dispatch(getProfile(id)))
}

export const updateOnlineStatus = () => async (dispatch) => {
    const id = auth.currentUser.uid
    await updateDoc(doc(firestore, "profile", id), {
        lastOnlineAt: serverTimestamp()
    }).then(() => dispatch(getProfile(id)))
}

// SELECTORS

export const profileSelector = (id, isUsername = false) => state => isUsername ? state.profile[(state.profile.usernameToIdMap[id] || '')] : state.profile[id]
export const currentProfileSelector = state => state.profile[auth.currentUser ? auth.currentUser.uid : '']
export const profilesSelector = state => state.profile
